import { PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Input, Modal, Row, Space, Spin, Table } from 'antd'
import dayjs from 'dayjs';
import React, { useContext, useState } from 'react'
import { BankDarahContext } from '../../context/BankDarahContext';

const { TextArea } = Input;

const RiwayatTranfusi = () => {
    const {
        ktgjnsDarah,
        listDarahSiap,
        listRiwReaksi,
        simpanReaksi,
        spTbKantongSiap,
        spTbRiwReaksi,
    } = useContext(BankDarahContext)

    const [tempId, settempId] = useState()
    const [reaksi, setreaksi] = useState()
    const [mdTambahReaksi, setmdTambahReaksi] = useState()

    const columns = [
        {
            title: 'Tanggal',
            align: 'center',
            dataIndex: 'TglPemeriksaan',
            key: 'TglPemeriksaan',
            width: 120,
            render: (text) => dayjs(text).format('DD-MM-YY HH:mm'),
        },
        {
            title: 'No. Kantong',
            align: 'center',
            dataIndex: 'NoKantong',
            key: 'NoKantong',
            width: 90,
        },
        {
            title: 'Gol. Darah',
            align: 'center',
            dataIndex: 'GolDarah',
            key: 'GolDarah',
            width: 80,
        },
        {
            title: 'Rhesus',
            align: 'center',
            dataIndex: 'Rhesus',
            key: 'Rhesus',
            width: 60,
        },
        {
            title: 'Jml. cc',
            align: 'center',
            dataIndex: 'JmlCc',
            key: 'JmlCc',
            width: 55,
        },
        {
            title: 'Jenis Komponen',
            align: 'center',
            dataIndex: 'JenisKomponen',
            key: 'JenisKomponen',
            width: 120,
        },
        {
            title: 'Reaksi yang terjadi',
            align: 'center',
            dataIndex: 'Reaksi',
            key: 'Reaksi',
        },
        {
            title: 'Aksi',
            align: 'center',
            width: 100,
            fixed: 'right',
            render: (text, record, index) => (
                <Button
                    onClick={() => {
                        setmdTambahReaksi(true)
                        setreaksi(record.Reaksi)
                        settempId(record.Id)
                    }}
                    type="primary"
                    // disabled={record.StsKirim}
                    // size='small'
                    icon={<PlusOutlined />}
                    style={{ width: '80px' }}
                >
                    Reaksi
                </Button>
            ),
        },
    ];

    const colTbRiwayat = [
        {
            title: 'Tanggal',
            align: 'center',
            dataIndex: 'TglPemeriksaan',
            key: 'TglPemeriksaan',
            width: 120,
            render: (text) => dayjs(text).format('DD-MM-YY HH:mm'),
        },
        {
            title: 'No. Kantong',
            align: 'center',
            dataIndex: 'NoKantong',
            key: 'NoKantong',
            width: 90,
        },
        {
            title: 'Gol. Darah',
            align: 'center',
            dataIndex: 'GolDarah',
            key: 'GolDarah',
            width: 80,
        },
        {
            title: 'Rhesus',
            align: 'center',
            dataIndex: 'Rhesus',
            key: 'Rhesus',
            width: 60,
        },
        {
            title: 'Jml. cc',
            align: 'center',
            dataIndex: 'JmlCc',
            key: 'JmlCc',
            width: 55,
        },
        {
            title: 'Jenis Komponen',
            align: 'center',
            dataIndex: 'JenisKomponen',
            key: 'JenisKomponen',
            width: 120,
        },
        {
            title: 'Reaksi yang terjadi',
            align: 'center',
            dataIndex: 'Reaksi',
            key: 'Reaksi',
        }
    ];

    const tempdata = [
        {
            tgl: dayjs(),
            qty: '1',
            unit: 'Teratai Teratai Teratai Teratai',
            reaksi: 'Reaksi yang terjadi ada disini ya gaes ya... :) Reaksi yang terjadi ada disini ya gaes ya... :)',
        },
        {
            tgl: dayjs(),
            qty: '1',
            unit: 'Teratai',
            reaksi: 'Reaksi yang terjadi ada disini ya gaes ya... :)',
        },
        {
            tgl: dayjs(),
            qty: '1',
            unit: 'Teratai',
            reaksi: 'Reaksi yang terjadi ada disini ya gaes ya... :)',
        },
    ];

    const klikSimpan = (sReaksi, sId) => {
        setmdTambahReaksi(false)

        let data = {}

        data.Id = sId;
        data.Reaksi = sReaksi;

        simpanReaksi(data)
    }

    return (
        <div>
            <Row style={{ marginBottom: '5px' }}>
                <Col span={24}>
                    <Divider
                        orientation='left'
                        style={{ backgroundColor: '#fff1b8', margin: '0px' }}>
                        Kantong Darah Diterima
                    </Divider>
                </Col>
            </Row>

            <Table
                bordered
                loading={spTbKantongSiap}
                columns={columns}
                dataSource={listDarahSiap}
                pagination={false}
                scroll={{
                    x: 1000,
                    // y: 300,
                }}
            />

            <Row style={{ marginTop: '5px', marginBottom: '5px' }}>
                <Col span={24}>
                    <Divider
                        orientation='left'
                        style={{ backgroundColor: '#fff1b8', margin: '0px' }}>
                        Riwayat Reaksi Kantong Darah Diterima
                    </Divider>
                </Col>
            </Row>

            <Table
                bordered
                loading={spTbRiwReaksi}
                columns={colTbRiwayat}
                dataSource={listRiwReaksi}
                pagination={false}
                scroll={{
                    x: 1000,
                    // y: 300,
                }}
            />

            {/* MD INSERT REAKSI */}
            <Modal
                centered
                open={mdTambahReaksi}
                onCancel={() => setmdTambahReaksi(false)}
                closable={false}
                footer={null}
                width={500}
            >
                <Divider
                    orientation='left'
                    style={{ backgroundColor: '#fff1b8', margin: '0px' }}>
                    Tambah Reaksi Transfusi
                </Divider>

                <Spin spinning={spTbKantongSiap}>
                    <Row style={{ marginTop: '5px' }}>
                        <Col span={4}>
                            Reaksi Transfusi :
                        </Col>
                        <Col span={20}>
                            <TextArea
                                rows={4}
                                value={reaksi}
                                onChange={(e) => setreaksi(e.target.value)}
                                maxLength={255}
                                // size='small' 
                                style={{ width: '100%' }} />
                        </Col>
                    </Row>

                    <hr />

                    <Row>
                        <Col span={24}>
                            <Space style={{ float: 'right' }}>
                                <Button
                                    onClick={() => klikSimpan(reaksi, tempId)}
                                    type='primary'
                                    style={{ width: '100px' }}
                                >
                                    Simpan
                                </Button>
                            </Space>
                        </Col>
                    </Row>
                </Spin>

            </Modal>
        </div>
    )
}

export default RiwayatTranfusi