import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import React from "react";
const { Option } = Select;
const formItemLayoutdpjp = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const FormFungsiMenelan = () => {
  return (
    <div>
      <Row>
        <Col span={24}>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Tanggal Pengkajian"
            style={{ marginBottom: 5 }}
          >
            <DatePicker
              //   value={tanggalKontrol}
              format="DD-MM-YYYY"
              onChange={(date) => {}}
              style={{ width: "100%" }}
              placeholder="..."
            />
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Kesadaran"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Suara Nafas"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Komphrehensif"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Bicara"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Motorik Bibir"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Gerakan Lidah"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Platum"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Reflek GAG"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Fonasi"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Batuk"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Mengunyah"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Oral"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Pharynx"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Toleransi Menelan"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                //   setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Keterangan"
            style={{ marginBottom: 5 }}
          >
            <Input.Group compact>
              <Input
                type="text"
                placeholder="..."
                readOnly
                // value={}
                style={{ width: "20%" }}
              />
              <Input
                type="text"
                placeholder="..."
                disabled
                // value={ketLatchScore}
                // style={stylekuLatchScore}
                readOnly
                style={{ width: "80%" }}
              />
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default FormFungsiMenelan;
