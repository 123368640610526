import { Card } from 'antd'
import React from 'react'

const FormCetak_coba1 = () => {
    return (
        <div>
            <Card>
                <table>
                    <tr>
                        <td>Alfreds Futterkiste</td>
                        <td>Maria Anders</td>
                        <td>Germany</td>
                    </tr>
                </table>
            </Card>
        </div>
    )
}

export default FormCetak_coba1