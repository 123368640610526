import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Slider,
  Space,
  Row,
  Col,
  Alert,
  DatePicker,
} from "antd";

const { Option } = Select;

const FormDisfagia = () => {
  const [disfagia1, setdisfagia1] = useState("");
  const [disfagia2, setdisfagia2] = useState("");
  const [disfagia3, setdisfagia3] = useState("");
  const [disfagia4, setdisfagia4] = useState("");
  const [disfagia5, setdisfagia5] = useState("");
  const [disfagia6, setdisfagia6] = useState("");

  return (
    <div>
      <Row>
        <Col span={1}>
          <p></p>
        </Col>
        <Col span={6}>
          <p>Tanggal Pengkajian</p>
        </Col>
        <Col span={11}>
          <DatePicker
            //   value={tanggalKontrol}
            format="DD-MM-YYYY"
            onChange={(date) => {}}
            style={{ width: "100%" }}
            placeholder="..."
          />
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>1.</p>
        </Col>
        <Col span={6}>
          <p>Kesadaran Pasien</p>
        </Col>
        <Col span={11}>
          <Select
            value={disfagia1}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              setdisfagia1(e);
            }}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={1}>Sadar</Option>
            <Option value={0}>Tidak Sadar</Option>
          </Select>
        </Col>
      </Row>
      {disfagia1 === 0 ? (
        <Alert message="Hentikan Skrining" type="info" showIcon />
      ) : disfagia1 === 1 ? (
        <>
          <Row>
            <Col span={1}>
              <p>2.</p>
            </Col>
            <Col span={6}>
              <p>Afasia Atau Disatria</p>
            </Col>
            <Col span={11}>
              <Select
                value={disfagia2}
                // dataSource={listSpesialisDBRS}
                onChange={(e) => {
                  setdisfagia2(e);
                }}
                showSearch
                style={{ width: "100%" }}
                placeholder="..."
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={1}>Ya</Option>
                <Option value={0}>Tidak</Option>
              </Select>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}

      {disfagia2 === 1 ? (
        <>
          {/* <Alert
            message={
              "Kolaborasi Dengan Terapi Wicara , dan Lanjutkan Langkah 3"
            }
            type="info"
            showIcon
          /> */}
          <Row>
            <Col span={1}>
              <p>3.</p>
            </Col>
            <Col span={6}>
              <p>
                Dapat merapatkan gigi, merapatkan bibir, wajah simetris, letak
                lidah ditengah, uvula di tengah
              </p>
            </Col>
            <Col span={11}>
              <Select
                value={disfagia3}
                // dataSource={listSpesialisDBRS}
                onChange={(e) => {
                  setdisfagia3(e);
                }}
                showSearch
                style={{ width: "100%" }}
                placeholder="..."
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={1}>1 Gejala</Option>
                <Option value={2}>2 Gejala</Option>
                <Option value={3}>&gt;3 Gejala</Option>
                <Option value={0}>Tidak</Option>
              </Select>
            </Col>
          </Row>
        </>
      ) : disfagia2 === 0 ? (
        <Row>
          <Col span={1}>
            <p>3.</p>
          </Col>
          <Col span={6}>
            <p>
              Dapat merapatkan gigi, merapatkan bibir, wajah simetris, letak
              lidah ditengah, uvula di tengah
            </p>
          </Col>
          <Col span={11}>
            <Select
              value={disfagia3}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                setdisfagia3(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>1 Gejala</Option>
              <Option value={2}>2 Gejala</Option>
              <Option value={3}>&gt;3 Gejala</Option>
              <Option value={0}>Tidak</Option>
            </Select>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {disfagia3 === 3 ? (
        <Row>
          <Col span={1}>
            <p>4.</p>
          </Col>
          <Col span={6}>
            <p>Reflek muntah ada, batuk spontan, reflek menelan baik</p>
          </Col>
          <Col span={11}>
            <Select
              value={disfagia4}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                setdisfagia4(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Ya</Option>
              <Option value={0}>Tidak</Option>
            </Select>
          </Col>
        </Row>
      ) : disfagia3 === 0 ? (
        // <>
        //   <Alert
        //     message="Kolaborasi Dengan Terapi Wicara, dan Lanjut Ke langkah 4"
        //     type="info"
        //     showIcon
        //   />
        <Row>
          <Col span={1}>
            <p>4.</p>
          </Col>
          <Col span={6}>
            <p>Reflek muntah ada, batuk spontan, reflek menelan baik</p>
          </Col>
          <Col span={11}>
            <Select
              value={disfagia4}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                setdisfagia4(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Ya</Option>
              <Option value={0}>Tidak</Option>
            </Select>
          </Col>
        </Row>
      ) : (
        <></>
      )}

      {disfagia4 === 1 ? (
        <Row>
          <Col span={1}>
            <p>5.</p>
          </Col>
          <Col span={6}>
            <p>Tes menelan air putih satu sendok teh</p>
          </Col>
          <Col span={11}>
            <Select
              value={disfagia5}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                setdisfagia5(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Mampu Menelan</Option>
              <Option value={0}>Tidak Mampu Menelan</Option>
            </Select>
          </Col>
        </Row>
      ) : disfagia4 === 0 ? (
        // <>
        //   <Alert
        //     message="Kolaborasi Dengan Terapi Wicara, dan Lanjut Ke langkah 5"
        //     type="info"
        //     showIcon
        //   />
        <Row>
          <Col span={1}>
            <p>5.</p>
          </Col>
          <Col span={6}>
            <p>Tes menelan air putih satu sendok teh</p>
          </Col>
          <Col span={11}>
            <Select
              value={disfagia5}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                setdisfagia5(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Mampu Menelan</Option>
              <Option value={0}>Tidak Mampu Menelan</Option>
            </Select>
          </Col>
        </Row>
      ) : (
        // </>
        <></>
      )}
      {disfagia5 === 1 ? (
        <Row>
          <Col span={1}>
            <p>6.</p>
          </Col>
          <Col span={6}>
            <p>
              Berikan minum air putih bertahap mulai dari 25 ml, 50 ml, dan 100
              ml.
            </p>
          </Col>
          <Col span={11}>
            <Select
              value={disfagia6}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {
                setdisfagia6(e);
              }}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tersedak/Batuk</Option>
              <Option value={0}>Tidak Tersedak</Option>
            </Select>
          </Col>
        </Row>
      ) : disfagia5 === 0 ? (
        <Alert
          message="STOP. Hasil skrining disfagia positif. Lakukan latihan menelan: Protokol L. Jangan berikan makan/ minum per oral, pasang NGT, kolaborasi dengan dokter, terapis wicara, dan ahli gizi"
          type="info"
          showIcon
        />
      ) : (
        <></>
      )}
      {disfagia6 === 1 ? (
        <Alert
          message="Hasil skrining disfagia positif, pasien tidak mampu menelan cairan. Lakukan latihan menelan: Protokol II, berikan modifikasi diet sesuai toleransi, pasang NGT bila diperlukan untuk asupan cairan, kolaborasi dg dokter, terapis wicara, dan ahli gizi"
          type="info"
          showIcon
        />
      ) : disfagia6 === 0 ? (
        <Alert
          message="Hasil Skrening Disfagia Negatif atau Fungsi Menelan Normal"
          type="info"
          showIcon
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default FormDisfagia;
