import { Button, Card, Input, Select, message } from "antd";
import React, { useContext, useState } from "react";
import { PasienContext } from "../rawatjalan/context/PasienContext";
import axios from "axios";
import { PelayananContext } from "../rawatjalan/context/Pelayanancontext";
const { Option } = Select;
const InsertBill = () => {
  const { ruangpoli, pembayaran } = useContext(PasienContext);
  const { dokterall } = useContext(PelayananContext);

  const [pelayanan, setPelayanan] = useState([]);
  const [noreg, setNoreg] = useState(null);
  const [ruang, setRuang] = useState(null);
  const [unit, setUnit] = useState([]);
  const [pel, setPel] = useState(null);
  const [pem, setPem] = useState(null);
  const [dokter, setDokter] = useState(null);
  const [user, setUser] = useState(null);
  const [client, setClient] = useState(null);
  const [ip, setIP] = useState(null);

  const data = {
    registrasiId: noreg,
    ruangId: ruang,
    pelayananId: pel,
    pembayaranId: pem,
    dokterPemeriksaId: dokter,
    kdgrptrf: "3",
    jmlPelayanan: 1,
    biayaPelayanan: 350000,
    userId: user,
    clientHost: client,
    clientIp: ip,
  };
  const apiku = sessionStorage.getItem("api");
  const tok = sessionStorage.getItem("userData");

  const options = {
    headers: { Authorization: "Bearer " + tok },
  };
  const loadPelayanan = (ruang) => {
    axios
      .get(`${apiku}/MstStandarPelayananRuang/Read/${ruang}/1/200`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setPelayanan(res.data.result);
        } else {
          setPelayanan([]);
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        setPelayanan([]);
        console.log(err);
        message.error("Gagal mengambil data!");
      });
  };

  const loadRuang = (jenis) => {
    axios
      .get(`${apiku}/MstRuang/Lookup/%20/${jenis}/1/100`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUnit(res.data.result);
        } else {
          setUnit([]);
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        setUnit([]);
        console.log(err);
        message.error("Gagal mengambil data!");
      });
  };
  const sample = {
    registrasiId: "2402010042",
    ruangId: "9405",
    pelayananId: "RHM009",
    pembayaranId: "0051",
    dokterPemeriksaId: "D348",
    kdgrptrf: "3",
    jmlPelayanan: 1,
    biayaPelayanan: 50000,
    userId: "UTIYA",
    clientHost: "ZOREHABADM",
    clientIp: "182.168.4.227",
  };

  const insertBiiling = (databilling) => {
    axios
      .post(`${apiku}/BillPemeriksaan/`, databilling, {
        headers: options.headers,
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success("Berhasil Disimpan!");
        } else {
          console.log(res.data);
          message.warning(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Gagal Disimpan!");
      });
  };

  return (
    <div>
      <Card
        size="small"
        title="Insert Billing"
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
        style={{
          borderWidth: "2px",
          borderColor: "darkgray",
          borderRadius: "4px",
        }}
      >
        <Input
          placeholder="No Registrasi"
          onChange={(e) => setNoreg(e.target.value)}
        />

        <Select
          showSearch
          style={{ width: "100%" }}
          placeholder="Pilih Jenis Ruang..."
          optionFilterProp="children"
          onChange={(e) => {
            loadRuang(e);
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option key={"1"}>RawatInap</Option>
          <Option key={"2"}>Rawat Jalan</Option>
          <Option key={"3"}>IGD</Option>
          <Option key={"4"}>PenunjangMedis</Option>
          <Option key={"5"}>PenunjangNonMedis</Option>
          <Option key={"7"}>ApotikFarmasi</Option>
        </Select>
        <Select
          dataSource={unit}
          showSearch
          style={{ width: "100%" }}
          placeholder="Pilih Ruang..."
          optionFilterProp="children"
          onChange={(e) => {
            setRuang(e);
            loadPelayanan(e);
          }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {unit.map((d) => (
            <Option
              key={d.ruangId}
              className={d.deskripsi.includes("ABIYASA") ? "backgroundaby" : ""}
            >
              {d.ruangId + " - " + d.deskripsi}
            </Option>
          ))}
        </Select>
        <Select
          dataSource={pelayanan}
          showSearch
          // searchValue={kosong}
          style={{ width: "100%" }}
          placeholder="Pilih Pelayanan..."
          optionFilterProp="children"
          onChange={(e) => setPel(e)}
          value={pel}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {pelayanan.map((d) => (
            <Option key={d.pelayananId}>
              {d.pelayananId + " - " + d.deskripsi}
            </Option>
          ))}
        </Select>
        <Select
          dataSource={pembayaran}
          showSearch
          // searchValue={kosong}
          style={{ width: "100%" }}
          placeholder="Pilih Pembayaran..."
          optionFilterProp="children"
          onChange={(e) => setPem(e)}
          value={pem}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {pembayaran.map((d) => (
            <Option key={d.pembayaranId}>
              {d.pembayaranId + " - " + d.deskripsi}
            </Option>
          ))}
        </Select>
        <Select
          dataSource={dokterall}
          showSearch
          style={{ width: "100%" }}
          placeholder="Pilih Pelaksana..."
          optionFilterProp="children"
          onChange={(e) => setDokter(e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          {dokterall.map((p) => (
            <Option key={p.dokterId}>
              {p.dokterId + " - " + p.namaDokter}
            </Option>
          ))}
        </Select>
        <Input placeholder="User" onChange={(e) => setUser(e.target.value)} />
        <Input
          placeholder="Client Name"
          onChange={(e) => setClient(e.target.value)}
        />
        <Input
          placeholder="Client IP"
          onChange={(e) => setIP(e.target.value)}
        />
        <Button type="primary" onClick={() => insertBiiling(sample)} block>
          Insert
        </Button>
      </Card>
    </div>
  );
};

export default InsertBill;
