import React, { useContext, useEffect, useRef } from "react";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import ImageEditor from "@toast-ui/react-image-editor";
import { Button, Col, Row, Space } from "antd";
import { PasienContext } from "../context/PasienContext";
import { GambarContext } from "../context/GambarContext";
const icona = require("tui-image-editor/dist/svg/icon-a.svg");
const iconb = require("tui-image-editor/dist/svg/icon-b.svg");
const iconc = require("tui-image-editor/dist/svg/icon-c.svg");
const icond = require("tui-image-editor/dist/svg/icon-d.svg");
const myTheme = {
  "menu.backgroundColor": "#fff",
  // "common.backgroundColor": "#151515",
  "downloadButton.backgroundColor": "white",
  // "downloadButton.borderColor": "white",
  "downloadButton.color": "black",
  "menu.normalIcon.path": icond,
  "menu.activeIcon.path": iconb,
  "menu.disabledIcon.path": icona,
  "menu.hoverIcon.path": iconc,
  "common.backgroundColor": "black",
  "submenu.normalIcon.path": icond,
  "submenu.activeIcon.path": iconb,
  "header.display": "none",
};
const GambarEditor = () => {
  const { curpas, poli1 } = useContext(PasienContext);
  const { insertGambar, gambar } = useContext(GambarContext);
  const bedahsaraf = require("../../../assets/img/dermatome.PNG");
  const badan = require("../../../assets/img/body.png");
  const gigi = require("../../../assets/img/gigi.jpeg");
  const mata = require("../../../assets/img/mata.jpg");
  const paru = require("../../../assets/img/paruparu.png");
  const anak = require("../../../assets/img/denver.png");
  const bidan = require("../../../assets/img/kebidanan.PNG");
  const imageEditor = React.createRef();

  const saveImageToDisk = () => {
    const imageEditorInst = imageEditor.current.imageEditorInst;
    const data = imageEditorInst.toDataURL();
    if (data) {
      console.log(data);
      insertGambar({
        registrasiId: curpas.registrasiId,
        gambar: data.substr(22),
      });
    }
  };

  const clear = () => {
    const image = new Image();
    image.src = badan;
    image.onload = () => {
      imageEditor.current.imageEditorInst.addImageObject(
        poli1 === "9105" || poli1 === "9163"
          ? bedahsaraf
          : poli1 === "9110" ||
            poli1 === "9167" ||
            poli1 === "9138" ||
            poli1 === "9135" ||
            poli1 === "91B4" ||
            poli1 === "91B5"
          ? gigi
          : poli1 === "9115" || poli1 === "9172"
          ? paru
          : poli1 === "9119" || poli1 === "9176"
          ? bidan
          : poli1 === "9109" ||
            poli1 === "9166" ||
            poli1 === "9148" ||
            poli1 === "91A1"
          ? mata
          : poli1 === "9144"
          ? anak
          : badan
      );
    };
  };
  return (
    <div>
      <Row>
        <Col span={20}>
          <ImageEditor
            includeUI={{
              loadImage: {
                path:
                  gambar === null
                    ? poli1 === "9105" || poli1 === "9163"
                      ? bedahsaraf
                      : poli1 === "9110" ||
                        poli1 === "9167" ||
                        poli1 === "9138" ||
                        poli1 === "9135" ||
                        poli1 === "91B4" ||
                        poli1 === "91B5"
                      ? gigi
                      : poli1 === "9115" || poli1 === "9172"
                      ? paru
                      : poli1 === "9119" || poli1 === "9176"
                      ? bidan
                      : poli1 === "9109" ||
                        poli1 === "9166" ||
                        poli1 === "9148" ||
                        poli1 === "91A1"
                      ? mata
                      : poli1 === "9144"
                      ? anak
                      : badan
                    : gambar,
                name: "gambar",
              },
              theme: myTheme,
              menu: [
                "crop",
                "flip",
                "rotate",
                "draw",
                "shape",
                "icon",
                "text",
                "mask",
                "filter",
              ],
              initMenu: "draw",
              uiSize: {
                width: "1300px",
                height: "700px",
              },
              menuBarPosition: "left",
            }}
            cssMaxHeight={600}
            cssMaxWidth={1000}
            selectionStyle={{
              cornerSize: 10,
              rotatingPointOffset: 70,
            }}
            usageStatistics={false}
            ref={imageEditor}
          />
        </Col>
        <Col span={4}>
          <Space>
            <Button
              size="large"
              type="primary"
              className="button"
              onClick={clear}
              style={{
                backgroundColor: "darkturquoise",
                borderColor: "darkturquoise",
              }}
            >
              Reset
            </Button>
            <Button
              size="large"
              type="primary"
              className="button"
              onClick={saveImageToDisk}
            >
              Simpan Gambar
            </Button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default GambarEditor;
