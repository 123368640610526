import { Spin, Card } from "antd";
import React, { useContext, useState } from "react";
import { Route } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import { ChatContext } from "../chat/Chatcontext";
import FormBilling from "./form/billing";
import Formanamnesa from "./form/Formanamnesa2";
import { LoadingOutlined } from "@ant-design/icons";
import Formdokter from "./form/formdokter";
import Formreminder from "./form/formreminder";
import Formpemeriksaankhusus from "./form/formpemeriksaankhusus";
import Formpantuaninfeksi from "./form/formpantuaninfeksi";
import FormKonsul from "./form/FormKonsulRJ";
import FormOrderPenunjang from "./form/FormOrderPenunjang";
import FormOrderResep from "./orderresep/FormOrderResep";
import FormDiagnosa from "./form/FormDiagnosa2";
import FormProsedur from "./form/Formprosedur";
import SuratKeteranganRJ from "./komponen/Suket/SuratKeteranganRJ";
import GambarEditor from "./komponen/GambarEditor";
import FormAskep from "./form/FormAskep";
import { useHistory } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import FormBeranda from "./form/FormBeranda";
import FormProtokolKemo from "./form/FormProtokolKemo";
const FormRJ = () => {
  const { loading } = useContext(ChatContext);
  const [key, setKey] = useState("tab1");
  const onTabChange = (key) => {
    setKey(key);
  };
  const tabList = [
    {
      key: "tab1",
      tab: <span style={{ fontWeight: "bolder" }}>ICD10 (Diagnosa)</span>,
    },
    {
      key: "tab2",
      tab: <span style={{ fontWeight: "bolder" }}>ICD9 (Prosedur)</span>,
    },
  ];
  const contentList = {
    tab1: <FormDiagnosa />,
    tab2: <FormProsedur />,
  };
  const history = useHistory();
  // const routeChange = () => {
  //   history.push("/app/form/resep");
  // };
  useHotkeys("shift+r", () => {
    history.push("/app/form/resep");
  });
  useHotkeys("shift+k", () => {
    history.push("/app/form/konsul");
  });
  useHotkeys("shift+b", () => {
    history.push("/app/form/billing");
  });
  useHotkeys("shift+a", () => {
    history.push("/app/form/anamnesa");
  });
  return (
    <Spin
      size="large"
      indicator={<LoadingOutlined style={{ fontSize: 30 }} spin />}
      spinning={loading}
      tip="Sedang Memuat.."
    >
      <div
        style={{
          // minHeight: 475,
          // height: 500,
          maxHeight: "77vh",
          // maxHeight: "85vh",
          overflowY: "scroll",
          paddingRight: 4,
        }}
      >
        <StickyContainer>
          <Route path="/app/form/billing" exact>
            <FormBilling />
          </Route>
          <Route path="/app/form/diagpro" exact>
            <Card
              headStyle={{ backgroundColor: "beige" }}
              tabProps={{ size: "small" }}
              style={{
                borderWidth: "2px",
                borderColor: "darkgray",
                borderRadius: "4px",
              }}
              size="small"
              tabList={tabList}
              activeTabKey={key}
              onTabChange={(key) => onTabChange(key)}
            >
              {contentList[key]}
            </Card>
          </Route>
          <Route path="/app/form/anamnesa" exact>
            <Formanamnesa />
            <Formdokter />
          </Route>
          <Route path="/app/form/perawat" exact>
            <FormAskep />
          </Route>
          <Route path="/app/form/tindaklanjut" exact>
            <Formreminder />
          </Route>
          <Route path="/app/form/khusus" exact>
            <Formpemeriksaankhusus />
          </Route>
          <Route path="/app/form/infeksi" exact>
            <Formpantuaninfeksi />
          </Route>
          <Route path="/app/form/gambar" exact>
            <GambarEditor />
          </Route>
          <Route path="/app/form/konsul" exact>
            <FormKonsul />
          </Route>
          <Route path="/app/form/penunjang" exact>
            <FormOrderPenunjang />
          </Route>
          <Route path="/app/form/resep" exact>
            <FormOrderResep />
          </Route>
          <Route path="/app/form/suket" exact>
            <SuratKeteranganRJ />
          </Route>
          <Route path="/app/form/protokolkemo" exact>
            <FormProtokolKemo />
          </Route>
          <Route path="/app/form/" exact>
            <FormBeranda />
          </Route>
        </StickyContainer>
      </div>
    </Spin>
  );
};

export default FormRJ;
