import React, { useContext } from "react";
import { Tabs } from "antd";
import FormRM13 from "./FormRM13";
import FormRM14 from "./FormRM14";
import FormSuratKeterangan from "./FormSuratKeterangan";
import { PasienRIContext } from "../context/PasienRIContext";
import { RM14Context } from "../context/RM14Context";
import { RM13RIContext } from "../context/RM13Context";
import { DiagnosaRIContext } from "../context/DiagnosaRIContext";

const { TabPane } = Tabs;
const FormPencetakan = () => {
  const { curpasRI, tabkey, setTabkey, cariRuangUserRI } =
    useContext(PasienRIContext);
  const { getDiagnosa, detailDiagnosa } = useContext(DiagnosaRIContext);
  const { detailRM13RI } = useContext(RM13RIContext);
  const { detailRM14RI } = useContext(RM14Context);

  const callback = (key) => {
    setTabkey(key);
    if (key === "1") {
      detailDiagnosa(curpasRI.registrasiId);
      detailRM13RI(curpasRI.registrasiId);
    } else if (key === "2") {
      detailDiagnosa(curpasRI.registrasiId);
      detailRM14RI(curpasRI.registrasiId);
    } else if (key === "3") {
      detailRM13RI(curpasRI.registrasiId);
    }
  };
  return (
    <div>
      <Tabs type="card" activeKey={tabkey} onChange={callback} size="small">
        <TabPane tab="Resume Perawatan (RM13)" key="1">
          <FormRM13 />
        </TabPane>
        <TabPane tab="Resume Rawat Inap (RM14)" key="2">
          <FormRM14 />
        </TabPane>
        <TabPane tab="Surat Keterangan" key="3">
          <FormSuratKeterangan />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default FormPencetakan;
