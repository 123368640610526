import React, { useContext, useState } from "react";
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Checkbox,
  Radio,
  Tabs,
  Card,
  Popconfirm,
  Button,
  message,
  Space,
} from "antd";
import dayjs from "dayjs";
import { PelayananContext } from "../../rawatjalan/context/Pelayanancontext";
import { RM13RIContext } from "../context/RM13Context";
import { AnamnesaRIContext } from "../context/AnamnesaRIContext";
import { PasienRIContext } from "../context/PasienRIContext";
import { DiagnosaRIContext } from "../context/DiagnosaRIContext";
import { DiagnosaContext } from "../../rawatjalan/context/Diagnosacontext";
import { VClaimContext } from "../../rawatjalan/context/VClaimContext";
const { TabPane } = Tabs;
const { TextArea } = Input;
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const formItemLayoutdpjp = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};
const formItemLayoutanjuran = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const { Option } = Select;

const FormRM13 = () => {
  const d = new Date();
  let dayapa = d.getDay();
  const { riwayatPenyakitRM13, setriwayatPenyakitRM13 } =
    useContext(AnamnesaRIContext);
  const [form] = Form.useForm();
  const ip = sessionStorage.getItem("IP");
  const host = sessionStorage.getItem("Host");
  const dateFormat = "DD-MM-YYYY";
  // const { detdiagnosa } = useContext(DiagnosaRIContext);
  const { detdiagnosa } = useContext(DiagnosaContext);
  const { curpasRI, lookupPoli, setlookupPoli, getLookupPoli } =
    useContext(PasienRIContext);
  const { dokterall, getDokterShift, dokterpoli } =
    useContext(PelayananContext);
  const [dissableForm, setdissableForm] = useState(true);
  const {
    insertRM13Bpjs,
    insertRM13nonbpjs,
    emrResumePerawatanId,
    //  setemrResumePerawatanId,tanggal, settanggal, hapus, sethapus,
    // registrasiId, setregistrasiId, RM13, setRM13,
    diagnosisIdMasuk,
    setdiagnosisIdMasuk,
    diagnosisIdPulang,
    setdiagnosisIdPulang,
    pembedahan,
    setpembedahan,
    riwayatPenyakit,
    setriwayatPenyakit,
    pemeriksaanFisik,
    setpemeriksaanFisik,
    hasilPenunjang,
    sethasilPenunjang,
    perkembangan,
    setperkembangan,
    keadaanPasien,
    setkeadaanPasien,
    pengobatan,
    setpengobatan,
    prognosa,
    setprognosa,
    anjuran,
    setanjuran,
    pelaksanaId,
    setpelaksanaId,
    kosongkanFormRM13,
    tanggalKontrol,
    settanggalKontrol,
    tipeRujuk,
    settipeRujuk,
    jenisKontrol,
    setjenisKontrol,
    ppkTujuan,
    setppkTujuan,
    noHp,
    setNoHp,
    klinik,
    setKlinik,
    diagnosa,
    setdiagnosa,
    catatan,
    setCatatan,
    sebab,
    setSebab,
    program,
    setProgram,
    poliBpjs,
    setpoliBpjs,
    dokterBpjsId,
    setdokterBpjsId,
  } = useContext(RM13RIContext);
  const { getMappingPoliBPJS, listpolibpjs, ambilDokterBPJS, dokterBPJS } =
    useContext(VClaimContext);

  const datarm13 = {
    emrResumePerawatanId: emrResumePerawatanId,
    registrasiId: curpasRI.registrasiId,
    diagnosisIdMasuk: diagnosisIdMasuk,
    diagnosisIdPulang: diagnosisIdPulang,
    pembedahan: pembedahan,
    riwayatPenyakit:
      riwayatPenyakit === "" ? riwayatPenyakitRM13 : riwayatPenyakit,
    pemeriksaanFisik: pemeriksaanFisik,
    hasilPenunjang: hasilPenunjang,
    perkembangan: perkembangan,
    keadaanPasien: keadaanPasien,
    pengobatan: pengobatan,
    prognosa: prognosa,
    anjuran: anjuran,
    pelaksanaId:
      pelaksanaId === "" || (pelaksanaId === []) | (pelaksanaId === null)
        ? curpasRI.dokterId
        : pelaksanaId,
    tanggal: dayjs().format("YYYY-MM-DDTHH:mm:ss"),
    hapus: false,
    clientHost: ip,
    clientIP: host,
  };

  return (
    <div>
      <Form form={form}>
        <Card size="small" title="Form RM 13">
          <Row>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutdpjp}
                label="Keadaan Saat Keluar"
                style={{ marginBottom: 5 }}
              >
                <Select
                  value={keadaanPasien}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="..."
                  onChange={(e) => setkeadaanPasien(e)}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Sembuh" defaultValue>
                    Sembuh
                  </Option>
                  <Option value="Membaik">Membaik</Option>
                  <Option value="Memburuk">Memburuk</Option>
                  <Option value="Seperti Semula">Seperti Semula</Option>
                  <Option value="Dirujuk">Dirujuk</Option>
                  <Option value="Mati < 48 Jam">Mati &lt; 48 Jam</Option>
                  <Option value="Mati > 48 Jam">Mati &gt; 48 Jam</Option>
                  <Option value="APS">APS</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutdpjp}
                label="DPJP"
                style={{ marginBottom: 5 }}
              >
                <Select
                  value={
                    pelaksanaId === "" ||
                    (pelaksanaId === []) | (pelaksanaId === null)
                      ? curpasRI.dokterId
                      : pelaksanaId
                  }
                  dataSource={dokterall}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="..."
                  optionFilterProp="children"
                  onChange={(e) => setpelaksanaId(e)}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dokterall.map((d) => (
                    <Option key={d.dokterId}>{d.namaDokter}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutdpjp}
                label="Diagnosa Utama Masuk"
                style={{ marginBottom: 5 }}
              >
                <Select
                  value={diagnosisIdMasuk}
                  dataSource={detdiagnosa}
                  onChange={(e) => setdiagnosisIdMasuk(e)}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="..."
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {detdiagnosa.map((e) => (
                    <Option key={e.diagnosisId}>
                      {e.diagnosisId + "-" + e.diagnosisDesk}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                {...formItemLayoutdpjp}
                label="Diagnosa Utama Pulang"
                style={{ marginBottom: 5 }}
              >
                <Select
                  value={diagnosisIdPulang}
                  dataSource={detdiagnosa}
                  onChange={(e) => setdiagnosisIdPulang(e)}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="..."
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {detdiagnosa.map((e) => (
                    <Option key={e.diagnosisId}>
                      {e.diagnosisId + "-" + e.diagnosisDesk}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item
                {...formItemLayout}
                label="Tindakan / Pembedahan"
                style={{ marginBottom: 5 }}
              >
                <TextArea
                  rows={2}
                  placeholder="..."
                  onChange={(e) => setpembedahan(e.target.value)}
                  value={pembedahan}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Card>
                <Tabs defaultActiveKey="1">
                  <TabPane tab="Riwayat Penyakit" key="1">
                    <Form.Item style={{ marginBottom: 5 }}>
                      <TextArea
                        rows={6}
                        placeholder="..."
                        onChange={(e) => setriwayatPenyakit(e.target.value)}
                        value={
                          riwayatPenyakit === ""
                            ? riwayatPenyakitRM13
                            : riwayatPenyakit
                        }
                      />
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Pemeriksaan Fisik" key="2">
                    <Form.Item style={{ marginBottom: 5 }}>
                      <TextArea
                        rows={6}
                        placeholder="..."
                        onChange={(e) => setpemeriksaanFisik(e.target.value)}
                        value={pemeriksaanFisik}
                      />
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Hasil Penunjang" key="3">
                    <Form.Item style={{ marginBottom: 5 }}>
                      <TextArea
                        rows={6}
                        placeholder="..."
                        onChange={(e) => sethasilPenunjang(e.target.value)}
                        value={hasilPenunjang}
                      />
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Pengobatan" key="5">
                    <Form.Item style={{ marginBottom: 5 }}>
                      <TextArea
                        rows={6}
                        placeholder="..."
                        onChange={(e) => setpengobatan(e.target.value)}
                        value={pengobatan}
                      />
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Prognosa" key="6">
                    <Form.Item style={{ marginBottom: 5 }}>
                      <Radio.Group
                        onChange={(e) => {
                          setprognosa(e.target.value);
                        }}
                      >
                        <Radio value="Dubia At Malam">Dubia At Malam</Radio>
                        <Radio value="Dubia At Bonam">Dubia At Bonam</Radio>
                      </Radio.Group>
                      <TextArea
                        rows={6}
                        placeholder="..."
                        onChange={(e) => setprognosa(e.target.value)}
                        value={prognosa}
                      />
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Perkembangan" key="8">
                    <Form.Item style={{ marginBottom: 5 }}>
                      <TextArea
                        rows={6}
                        placeholder="..."
                        onChange={(e) => setperkembangan(e.target.value)}
                        value={perkembangan}
                      />
                    </Form.Item>
                  </TabPane>
                  <TabPane tab="Anjuran Pasien" key="7">
                    {curpasRI.pembayaranId === "00501" ||
                    curpasRI.pembayaranId === "00511" ? (
                      <Row>
                        <Col span={8}>
                          <TextArea
                            rows={14}
                            placeholder="..."
                            onChange={(e) => setanjuran(e.target.value)}
                            style={{ width: "90%" }}
                            value={anjuran}
                          />
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            {...formItemLayout}
                            label="Kontrol/Rujuk"
                            style={{ marginBottom: 5 }}
                          >
                            <Row gutter={[5, 5]}>
                              <Col span={1}>
                                <Checkbox
                                  onChange={(e) => {
                                    e.target.checked === true
                                      ? setdissableForm(false)
                                      : setdissableForm(true);
                                    getLookupPoli("%20");
                                  }}
                                />
                              </Col>
                              <Col span={23}>
                                <Input style={{ width: "100%" }} />
                              </Col>
                            </Row>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Tipe Rujuk"
                            style={{ marginBottom: 5 }}
                          >
                            <Row gutter={[10, 10]}>
                              <Col span={10}>
                                <Select
                                  value={tipeRujuk}
                                  onChange={(e) => {
                                    settipeRujuk(e);
                                  }}
                                  disabled={dissableForm}
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="..."
                                  optionFilterProp="children"
                                >
                                  <Option value="Rujuk Penuh">
                                    Rujuk Penuh
                                  </Option>
                                  <Option value="Rujuk Parsial">
                                    Rujuk Parsial
                                  </Option>
                                  <Option value="Rujuk Balik">
                                    Rujuk Balik
                                  </Option>
                                  <Option value="Kontrol">Kontrol</Option>
                                </Select>
                              </Col>
                              <Col span={14}>
                                <Radio.Group
                                  disabled={dissableForm}
                                  onChange={(e) => {
                                    setjenisKontrol(e);
                                  }}
                                  value={jenisKontrol}
                                >
                                  <Radio value="Rawat Jalan">Rawat Jalan</Radio>
                                  <Radio value="Rawat Inap">Rawat Inap</Radio>
                                </Radio.Group>
                              </Col>
                            </Row>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Klinik RS"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              onChange={(e) => {
                                console.log(e.split("=").pop());
                                setKlinik(e);
                                getMappingPoliBPJS(e.split("=").shift());
                              }}
                              dataSource={lookupPoli}
                              value={klinik}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="..."
                              optionFilterProp="children"
                            >
                              {lookupPoli.map((e) => (
                                <Option key={e.ruangId + "=" + e.deskripsi}>
                                  {e.deskripsi}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Klinik BPJS"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              onChange={(e) => {
                                setpoliBpjs(e);
                              }}
                              dataSource={listpolibpjs}
                              value={poliBpjs}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="..."
                              optionFilterProp="children"
                            >
                              {listpolibpjs.map((e) => (
                                <Option key={e.kodeBpjs}>
                                  {e.ruangDeskripsiBPJS}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                {...formItemLayoutanjuran}
                                label="Tgl Kontrol"
                                style={{ marginBottom: 5 }}
                              >
                                <DatePicker
                                  value={tanggalKontrol}
                                  disabledDate={(current) => {
                                    return (
                                      current && current < dayjs().endOf("day")
                                    );
                                  }}
                                  format={dateFormat}
                                  onChange={(date) => {
                                    settanggalKontrol(date);
                                    ambilDokterBPJS(
                                      poliBpjs,
                                      dayjs(date).format("YYYY-MM-DD")
                                    );
                                  }}
                                  disabled={dissableForm}
                                  style={{ width: "100%" }}
                                  placeholder="..."
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                {...formItemLayout}
                                label="No Hp"
                                style={{ marginBottom: 5 }}
                              >
                                <Input
                                  onChange={(e) => {
                                    setNoHp(e.target.value);
                                  }}
                                  value={
                                    noHp === [] || noHp === "" || noHp === null
                                      ? curpasRI.noTelephone
                                      : noHp
                                  }
                                  disabled={dissableForm}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item
                            {...formItemLayout}
                            label="Dokter BPJS"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              onChange={(e) => {
                                setdokterBpjsId(e);
                                console.log(e);
                              }}
                              value={dokterBpjsId}
                              dataSource={dokterBPJS}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="..."
                              optionFilterProp="children"
                            >
                              {dokterBPJS.map((e) => (
                                <Option key={e.kode}>{e.namaDokter}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Diagnosa"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              value={
                                diagnosa === [] ||
                                diagnosa === "" ||
                                diagnosa === null
                                  ? diagnosisIdPulang
                                  : diagnosa
                              }
                              dataSource={detdiagnosa}
                              onChange={(e) => setdiagnosa(e)}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="..."
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {detdiagnosa.map((e) => (
                                <Option key={e.diagnosisId}>
                                  {e.diagnosisId + "-" + e.diagnosisDesk}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Catatan"
                            style={{ marginBottom: 5 }}
                          >
                            <TextArea
                              onChange={(e) => {
                                setCatatan(e.target.value);
                                setanjuran(
                                  "Kontrol Tanggal " +
                                    dayjs(tanggalKontrol).format("DD-MM-YYYY") +
                                    ", di " +
                                    klinik.split("=").pop() +
                                    ", Catatan : " +
                                    e.target.value
                                );
                              }}
                              value={catatan}
                              disabled={dissableForm}
                              rows={2}
                              placeholder="..."
                            />
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Sebab Rujuk/Kontrol"
                            style={{ marginBottom: 5 }}
                          >
                            <TextArea
                              onChange={(e) => {
                                setSebab(e.target.value);
                              }}
                              value={sebab}
                              disabled={dissableForm}
                              rows={2}
                              placeholder="..."
                            />
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Program"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              onChange={(e) => {
                                setProgram(e);
                              }}
                              value={program}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "30%" }}
                              placeholder="..."
                              optionFilterProp="children"
                            >
                              <Option value="-">-</Option>
                              <Option value="Kontrol">Kontrol</Option>
                              <Option value="Kemoterapi">Kemoterapi</Option>
                              <Option value="Operasi">Operasi</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col span={8}>
                          <TextArea
                            rows={14}
                            placeholder="..."
                            onChange={(e) => setanjuran(e.target.value)}
                            style={{ width: "90%" }}
                            value={anjuran}
                          />
                        </Col>
                        <Col span={16}>
                          <Form.Item
                            {...formItemLayout}
                            label="Kontrol/Rujuk"
                            style={{ marginBottom: 5 }}
                          >
                            <Row gutter={[5, 5]}>
                              <Col span={1}>
                                <Checkbox
                                  onChange={(e) => {
                                    e.target.checked === true
                                      ? setdissableForm(false)
                                      : setdissableForm(true);
                                    getLookupPoli("%20");
                                  }}
                                />
                              </Col>
                              <Col span={23}>
                                <Input style={{ width: "100%" }} />
                              </Col>
                            </Row>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Tipe Rujuk"
                            style={{ marginBottom: 5 }}
                          >
                            <Row gutter={[10, 10]}>
                              <Col span={10}>
                                <Select
                                  value={tipeRujuk}
                                  onChange={(e) => {
                                    settipeRujuk(e);
                                  }}
                                  disabled={dissableForm}
                                  showSearch
                                  style={{ width: "100%" }}
                                  placeholder="..."
                                  optionFilterProp="children"
                                >
                                  <Option value="Rujuk Penuh">
                                    Rujuk Penuh
                                  </Option>
                                  <Option value="Rujuk Parsial">
                                    Rujuk Parsial
                                  </Option>
                                  <Option value="Rujuk Balik">
                                    Rujuk Balik
                                  </Option>
                                  <Option value="Kontrol">Kontrol</Option>
                                </Select>
                              </Col>
                              <Col span={14}>
                                <Radio.Group
                                  disabled={dissableForm}
                                  onChange={(e) => {
                                    setjenisKontrol(e);
                                  }}
                                >
                                  <Radio value="Rawat Jalan">Rawat Jalan</Radio>
                                  <Radio value="Rawat Inap">Rawat Inap</Radio>
                                </Radio.Group>
                              </Col>
                            </Row>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Klinik RS"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              onChange={(e) => {
                                console.log(e.split("=").pop());
                                setKlinik(e);
                                getMappingPoliBPJS(e.split("=").shift());
                              }}
                              dataSource={lookupPoli}
                              value={klinik}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="..."
                              optionFilterProp="children"
                            >
                              {lookupPoli.map((e) => (
                                <Option key={e.ruangId + "=" + e.deskripsi}>
                                  {e.deskripsi}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Row>
                            <Col span={12}>
                              <Form.Item
                                {...formItemLayoutanjuran}
                                label="Tgl Kontrol"
                                style={{ marginBottom: 5 }}
                              >
                                <DatePicker
                                  disabledDate={(current) => {
                                    return (
                                      current && current < dayjs().endOf("day")
                                    );
                                  }}
                                  value={tanggalKontrol}
                                  format={dateFormat}
                                  onChange={(date) => {
                                    settanggalKontrol(date);
                                    getDokterShift(
                                      klinik.split("=").shift(),
                                      new Date(date).getDay() + 1
                                    );
                                  }}
                                  disabled={dissableForm}
                                  style={{ width: "100%" }}
                                  placeholder="..."
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                {...formItemLayout}
                                label="No Hp"
                                style={{ marginBottom: 5 }}
                              >
                                <Input
                                  onChange={(e) => {
                                    setNoHp(e.target.value);
                                  }}
                                  value={
                                    noHp === [] || noHp === "" || noHp === null
                                      ? curpasRI.noTelephone
                                      : noHp
                                  }
                                  disabled={dissableForm}
                                  style={{ width: "100%" }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                          <Form.Item
                            {...formItemLayout}
                            label="Dokter RS"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              onChange={(e) => {
                                setdokterBpjsId(e);
                              }}
                              value={dokterBpjsId}
                              dataSource={dokterpoli}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="..."
                              optionFilterProp="children"
                            >
                              {dokterpoli.map((p) => (
                                <Option key={p.DokterId}>{p.NamaDokter}</Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Diagnosa"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              value={
                                diagnosa === [] ||
                                diagnosa === "" ||
                                diagnosa === null
                                  ? diagnosisIdPulang
                                  : diagnosa
                              }
                              dataSource={detdiagnosa}
                              onChange={(e) => setdiagnosa(e)}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="..."
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.props.children
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {detdiagnosa.map((e) => (
                                <Option key={e.diagnosisId}>
                                  {e.diagnosisId + "-" + e.diagnosisDesk}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Catatan"
                            style={{ marginBottom: 5 }}
                          >
                            <TextArea
                              onChange={(e) => {
                                setCatatan(e.target.value);
                                setanjuran(
                                  "Kontrol Tanggal " +
                                    dayjs(tanggalKontrol).format("DD-MM-YYYY") +
                                    ", di " +
                                    klinik.split("=").pop() +
                                    ", Catatan : " +
                                    e.target.value
                                );
                              }}
                              value={catatan}
                              disabled={dissableForm}
                              rows={2}
                              placeholder="..."
                            />
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Sebab Rujuk/Kontrol"
                            style={{ marginBottom: 5 }}
                          >
                            <TextArea
                              onChange={(e) => {
                                setSebab(e.target.value);
                              }}
                              value={sebab}
                              disabled={dissableForm}
                              rows={2}
                              placeholder="..."
                            />
                          </Form.Item>
                          <Form.Item
                            {...formItemLayout}
                            label="Program"
                            style={{ marginBottom: 5 }}
                          >
                            <Select
                              onChange={(e) => {
                                setProgram(e);
                              }}
                              value={program}
                              disabled={dissableForm}
                              showSearch
                              style={{ width: "30%" }}
                              placeholder="..."
                              optionFilterProp="children"
                            >
                              <Option value="-">-</Option>
                              <Option value="Kontrol">Kontrol</Option>
                              <Option value="Kemoterapi">Kemoterapi</Option>
                              <Option value="Operasi">Operasi</Option>
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </Card>
        <Card>
          <Row>
            <Col span={12}>
              <Space>
                <Button
                  onClick={() => {
                    console.log("ini data rm 13", datarm13);
                  }}
                >
                  Cetak
                </Button>
                <Button
                  danger
                  onClick={() => {
                    console.log("ini data rm 13", datarm13);
                  }}
                >
                  Hapus Kontrol
                </Button>
              </Space>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Space>
                <Button
                  onClick={() => {
                    kosongkanFormRM13();
                  }}
                >
                  Batal
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    // insertRM13(datarm13);
                    console.log("ini data rm 13", datarm13);
                  }}
                >
                  Simpan
                </Button>
              </Space>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default FormRM13;
