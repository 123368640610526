import { Card, Col, Descriptions, Image, Row, Table } from "antd";
import Search from "antd/lib/input/Search";
import React, { useContext, useState } from "react";
import { PasienContext } from "../rawatjalan/context/PasienContext";
import RiwayatPemeriksaan from "./RiwayatPemeriksaan";
import { ResepContext } from "../rawatjalan/orderresep/ResepContext";

const RiwayatMedisPasien = () => {
  const { getRiwayatObat } = useContext(ResepContext);

  const {
    setPasienID,
    setLoading,
    loading,
    setLoadingDetail,
    loadingdetail,
    pasienlist,
    cariPasienListAll,
    getPasienDetail,
    pasiendetail,
    pasienDetailJenisKelamin,
    pasienDetailAgama,
    pasienDetailPendidikan,
    pasienDetailStatusKwn,
    pasienDetailPekerjaan,
    pasienDetailGolonganDrh,
    pasienDetailSuku,
    pasienDetailBahasa,
    pasienDetailDesa,
    pasienDetailKelasRwt,
    pasienDetailPembayaran,
    // insertPasien,
    detailRiwayatPasien,
    setCurpas,
  } = useContext(PasienContext);
  const [warnarow, setWarnaRow] = useState(null);

  const columns = [
    {
      title: "No Pasien",
      dataIndex: "pasienId",
    },
    {
      title: "Nama Pasien",
      dataIndex: "nama",
    },
    {
      title: "Tanggal Lahir",
      dataIndex: "tanggalLahir",
      render: (_, record) => {
        return <div>{record.tanggalLahir.substr(0, 10)}</div>;
      },
    },
    {
      title: "Alamat",
      dataIndex: "alamat",
    },
  ];

  const setAmbil = (e) => {
    cariPasienListAll(e);
    setLoading(true);
  };
  return (
    <div className="backcontent" id="divcontents">
      <Row gutter={[8, 8]}>
        <Col span={12}>
          <Card
            size="small"
            style={{ margin: 3 }}
            title="Pencarian Pasien"
            headStyle={{ fontWeight: "bold", fontSize: "14" }}
          >
            <Search
              placeholder="Cari Nama Pasien / No Medis Pasien"
              enterButton
              onSearch={(e) => setAmbil(e)}
            />
            <Table
              pagination={false}
              scroll={{ y: 250 }}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    setWarnaRow(rowIndex);
                    getPasienDetail(record.pasienId);
                    // setForm(true);
                    setLoadingDetail(true);
                    setPasienID(record.pasienId);
                    detailRiwayatPasien(record.pasienId);
                    // listHasilLabPK();
                    getRiwayatObat(record.pasienId);
                    sessionStorage.setItem("norm", record.pasienId);
                    setCurpas("a");
                  }, // click row
                };
              }}
              loading={loading}
              columns={columns}
              dataSource={pasienlist}
              size="small"
              rowClassName={(record, rowIndex) =>
                rowIndex === warnarow ? "bgcolorsuccess" : null
              }
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card
            size="small"
            style={{ margin: 3 }}
            title="Informasi Pasien"
            headStyle={{ fontWeight: "bold", fontSize: "14" }}
            loading={loadingdetail}
          >
            <Descriptions bordered size="small">
              <Descriptions.Item label="No. Pasien">
                {pasiendetail.pasienId}
              </Descriptions.Item>
              <Descriptions.Item label="NIK">
                {pasiendetail.nik}
              </Descriptions.Item>
              <Descriptions.Item label="Nama">
                {pasiendetail.nama}
              </Descriptions.Item>
              <Descriptions.Item label="Tempat Lahir">
                {pasiendetail.tempatLahir}
              </Descriptions.Item>
              <Descriptions.Item label="Tanggal Lahir">
                {pasiendetail.tanggalLahir === undefined
                  ? null
                  : pasiendetail.tanggalLahir.substring(0, 10)}
              </Descriptions.Item>
              <Descriptions.Item label="Umur">
                {pasiendetail.umur}
              </Descriptions.Item>
              <Descriptions.Item label="Jenis Kelamin">
                {pasienDetailJenisKelamin.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Agama">
                {pasienDetailAgama.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Golongan Darah">
                {pasienDetailGolonganDrh.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Alamat">
                {pasiendetail.alamat}
              </Descriptions.Item>
              <Descriptions.Item label="Desa">
                {pasienDetailDesa.desaNama}
              </Descriptions.Item>
              <Descriptions.Item label="Alamat Domisili">
                {pasiendetail.alamatDomisili}
              </Descriptions.Item>
              <Descriptions.Item label="No. Telepon">
                {pasiendetail.noTelepon}
              </Descriptions.Item>
              <Descriptions.Item label="Pekerjaan">
                {pasienDetailPekerjaan.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Pendidikan">
                {pasienDetailPendidikan.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Status Kawin">
                {pasienDetailStatusKwn.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Suku Bangsa">
                {pasienDetailSuku.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Bahasa">
                {pasienDetailBahasa.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Nama Ibu">
                {pasiendetail.namaIbu}
              </Descriptions.Item>
              <Descriptions.Item label="Nama Ayah">
                {pasiendetail.namaAyah}
              </Descriptions.Item>
              <Descriptions.Item label="Nama Pasangan">
                {pasiendetail.namaPasangan}
              </Descriptions.Item>
              <Descriptions.Item label="Pembayaran" span={2}>
                Nama Pembayaran : {pasienDetailPembayaran.deskripsi}
                <br />
                No Pembayaran : {pasiendetail.noPolish}
                <br />
                Kelas Rawat : {pasienDetailKelasRwt.deskripsi}
              </Descriptions.Item>
              <Descriptions.Item label="Tanda Tangan">
                {pasiendetail.tandaTangan === null ? null : (
                  <Image
                    width={200}
                    src={`data:image/jpeg;base64,` + pasiendetail.tandaTangan}
                  />
                )}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            // title="Riwayat Pemeriksaan"
            bordered
            size="small"
            style={{ margin: 3 }}
            loading={loadingdetail}
          >
            <RiwayatPemeriksaan />
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default RiwayatMedisPasien;
