import { Card, Table } from "antd";
import axios from "axios";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
const { Column } = Table;
const MasterDokter = () => {
  const [dokterall, setDokterAll] = useState([]);

  useEffect(() => {
    const apiku = sessionStorage.getItem("api");
    const token = sessionStorage.getItem("userData");
    const options = {
      headers: { Authorization: "Bearer " + token },
    };

    axios
      .get(`${apiku}/MstDokter/Lookup/%20/1/1000`, {
        headers: options.headers,
      })
      .then((res) => {
        if (res.data.statusCode === 200) {
          setDokterAll(res.data.result);
        } else {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <Card
        title="List Master Dokter"
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
        size="small"
        style={{
          borderWidth: "2px",
          borderColor: "darkgray",
          borderRadius: "4px",
        }}
      >
        <Table dataSource={dokterall} size="small">
          <Column
            title="ID Dokter"
            render={(text, record) => <span>{record.dokterId}</span>}
          />
          <Column
            title="Nama Dokter"
            render={(text, record) => <span>{record.namaDokter}</span>}
          />
          <Column
            title="Status Aktif"
            render={(text, record) => (
              <span>
                {record.status ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <CloseCircleOutlined twoToneColor="#eb2f96" />
                )}
              </span>
            )}
          />
        </Table>
      </Card>
    </div>
  );
};

export default MasterDokter;
