import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  Slider,
  Space,
  Row,
  Col,
  DatePicker,
} from "antd";

const { Option } = Select;
const formItemLayoutdpjp = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const FormBraden = () => {
  const [form] = Form.useForm();
  const [totalScore, setTotalScore] = useState(0);

  const onFinish = (values) => {
    // Hitung total skor Braden
    const total =
      parseInt(values.sensasi) +
      parseInt(values.kelembaban) +
      parseInt(values.aktivitas) +
      parseInt(values.mobilitas) +
      parseInt(values.nutrisi) +
      parseInt(values.friksi);

    setTotalScore(total);
  };
  return (
    <div>
      <Row>
        <Col span={24}>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Tanggal Pengkajian"
            style={{ marginBottom: 5 }}
          >
            <DatePicker
              //   value={tanggalKontrol}
              format="DD-MM-YYYY"
              onChange={(date) => {}}
              style={{ width: "100%" }}
              placeholder="..."
            />
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Persepsi Sensori"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={smftujuan}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {}}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>
                Tidak merasakan atau respon terhadap stimulus nyeri, kesadaran
                menurun
              </Option>
              <Option value={2}>
                Gangguan sensori pada bagian 1/2 permukaan tubuh atau hanya
                berespon pada stimuli nyeri
              </Option>
              <Option value={3}>
                Gangguan sensori pada 1 atau 2 ekstremitas atau berespon pada
                perintah verbal tapi tidak selalu mampu mengatakan
                ketidaknyamanan
              </Option>
              <Option value={4}>
                Tidak ada gangguan sensori, berespon penuh terhadap perintah
                verbal
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Kelembaban"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={smftujuan}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {}}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>
                Selalu terpapar oleh keringat atau urine basah
              </Option>
              <Option value={2}>Sangat lembab</Option>
              <Option value={3}>Kadang lembab</Option>
              <Option value={4}>Kulit kering</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Aktivitas"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={smftujuan}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {}}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Terbaring di tempat tidur</Option>
              <Option value={2}>Tidak bisa berjalan</Option>
              <Option value={3}>Berjalan dengan atau tanpa bantuan</Option>
              <Option value={4}>Dapat berjalan sekitar ruangan</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Mobilitas"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={smftujuan}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {}}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Tidak mampu bergerak</Option>
              <Option value={2}>
                Tidak dapat merubah posisi secara tepat dan teratur
              </Option>
              <Option value={3}>
                Dapat membuat perubahan posisi tubuh atau ekstremitas dengan
                mandiri
              </Option>
              <Option value={4}>Dapat merubah posisi tanpa bantuan</Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Nutrisi"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={smftujuan}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {}}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>
                Tidak dapat menghabiskan 1/3 porsi makannya, sedikit minum,
                puasa atau minum air putih atau mendapat infus lebih dari 5 hari
              </Option>
              <Option value={2}>
                Jarang mampu menghabiskan 1/2 porsi makanannya atau intake
                cairan kurang dari jumlah optimum
              </Option>
              <Option value={3}>
                Mampu menghabiskan lebih dari 1/2 porsi makannya
              </Option>
              <Option value={4}>
                Dapat menghabiskan porsi makannya tidak memerlukan suplementasi
                nutrisi
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Gesekan"
            style={{ marginBottom: 5 }}
          >
            <Select
              // value={smftujuan}
              // dataSource={listSpesialisDBRS}
              onChange={(e) => {}}
              showSearch
              style={{ width: "100%" }}
              placeholder="..."
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>
                Tidak mampu mengangkat badannya sendiri atau spastik, kontraktur
                atau gelisah
              </Option>
              <Option value={2}>
                Membutuhkan bantuan minimal mengangkat tubuhnya
              </Option>
              <Option value={3}>
                Membutuhkan bantuan minimal mengangkat tubuhnya
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            {...formItemLayoutdpjp}
            label="Keterangan"
            style={{ marginBottom: 5 }}
          >
            <Input.Group compact>
              <Input
                type="text"
                placeholder="..."
                readOnly
                // value={}
                style={{ width: "20%" }}
              />
              <Input
                type="text"
                placeholder="..."
                disabled
                // value={ketLatchScore}
                // style={stylekuLatchScore}
                readOnly
                style={{ width: "80%" }}
              />
            </Input.Group>
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default FormBraden;
