import React, { Fragment, useContext, useState, useRef } from "react";
import {
  Table,
  Input,
  Button,
  Tag,
  Tooltip,
  Empty,
  Typography,
  Switch,
  Row,
  Space,
  Pagination,
  Tour,
} from "antd";
import { UndoOutlined, SoundOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { PasienContext } from "../rawatjalan/context/PasienContext";
import { HasilRadiologiContext } from "../rawatjalan/context/HasilRadiologiContext";
import { PrintOutContext } from "../PrintOutDokumen/PrintOutContext";
import { AnamnesaContext } from "../rawatjalan/context/AnamnesaContext";
import { AlergiContext } from "../rawatjalan/context/AlergiContext";
import { CatatanmedisContext } from "../rawatjalan/context/CatatanmedisContext";
import { BillingContext } from "../rawatjalan/context/BillingContext";
import { ChatContext } from "../chat/Chatcontext";
import { ResepContext } from "../rawatjalan/orderresep/ResepContext";
const { Search } = Input;
const { Text } = Typography;
const TabelpasienIGD = () => {
  const {
    pasien,
    ruangasal,
    detailPasien,
    setLebar,
    setRefresh,
    nomorantrian,
    curantri,
    detailAntrian,
    cariPasienNamaHariIni,
    setOpen,
    open,
    currentt,
    setCurrentt,
    layout,
    cariPasienHariIni,
  } = useContext(PasienContext);
  const { setURL } = useContext(HasilRadiologiContext);
  const { kosongkanPrintOut } = useContext(PrintOutContext);
  const { detailTV } = useContext(AnamnesaContext);
  const { detailAllergy } = useContext(AlergiContext);
  const { detailCatatanmedis } = useContext(CatatanmedisContext);
  const { detailBilling } = useContext(BillingContext);
  const { panggilAntrianPoliKlinik } = useContext(ChatContext);
  const { setLoadingresep } = useContext(ResepContext);
  const [pilih, setPilih] = useState(false);
  const ref = useRef(null);

  const [lebarnama, setLebarNama] = useState("150px");
  const steps = [
    {
      title: "Pembaruan!",
      description:
        "Untuk mengoptimalkan / mempercepat pengambilan data pasien, data list pasien akan dibatasi sebanyak 15 Pasien.",
      target: null,
    },
    {
      title: "Klik ke halaman berikutnya",
      description: "Untuk mencari pasien berikutnya dihalaman berikutnya.",
      placement: "right",
      target: () => ref.current,
    },
  ];

  const data = {
    ruangId: curantri.ruangId,
    namaRuangan: curantri.ruangDeskripsi,
    registrasiId: curantri.registrasiId,
    nama: curantri.namaPasien,
    alamat: curantri.alamat,
    nomorAntrian: nomorantrian.toString(),
  };
  const panggil = () => {
    panggilAntrianPoliKlinik(data);
  };

  const onLebar = (e) => {
    e === true ? setLebar(450) : setLebar(250);
    e === true ? setLebarNama("200px") : setLebarNama("120px");
  };

  const handleClick = (props, id) => {
    detailPasien(props);
    detailAllergy(props);
    detailTV(props);
    detailCatatanmedis(props);
    setPilih(props);
    detailBilling(props);
    // setCurrent("anamnesa");
    sessionStorage.setItem("noreg", props);
    sessionStorage.setItem("norm", id);
    // setLoading(true);
    kosongkanPrintOut();
    setLoadingresep(false);
    setURL("");
  };
  const handleCari = (e) => {
    cariPasienNamaHariIni(e);
  };
  const [visible, setVisible] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const Popup = () =>
    visible && (
      <ul className="popup" style={{ left: `${x}px`, top: `${y - 85}px` }}>
        <li
          style={{ color: "green" }}
          onClick={() => {
            panggil();
          }}
        >
          <SoundOutlined /> Panggil
        </li>
      </ul>
    );

  const history = useHistory();
  const routeChange = () => {
    history.push("/app/form/anamnesa");
  };

  const columnsrsms = [
    {
      title: "Nama",
      fixed: "left",
      width: lebarnama,
      dataIndex: "namaPasien",
      sorter: (a, b) => a.namaPasien.localeCompare(b.namaPasien),
      render(text, record) {
        return {
          props: {
            style: {
              background:
                record.statusTindakLanjut === 1 ? "rgb(48, 231, 240)" : "",
              color: record.telemedicine ? "red" : "#000000d9",
              cursor: "default",
              fontSize: "7",
            },
          },
          children:
            record.statusTindakLanjut === 1 ? (
              <Tooltip title="Dirawat">
                <Button
                  style={{
                    fontSize: 11,
                    padding: 0,
                    textAlign: "left",
                    whiteSpace: "normal",
                    height: "auto",
                  }}
                  danger={record.telemedicine ? true : false}
                  type="link"
                  size="small"
                >
                  {text}
                </Button>
              </Tooltip>
            ) : record.telemedicine ? (
              <Tooltip title="Pasien Telemedicine">
                <Button
                  style={{
                    fontSize: 11,
                    padding: 0,
                    textAlign: "left",
                    whiteSpace: "normal",
                    height: "auto",
                  }}
                  danger={record.telemedicine ? true : false}
                  type="link"
                  size="small"
                >
                  {text}
                </Button>
              </Tooltip>
            ) : (
              <Button
                style={{
                  fontSize: 11,
                  padding: 0,
                  textAlign: "left",
                  whiteSpace: "normal",
                  height: "auto",
                }}
                danger={record.telemedicine ? true : false}
                type="link"
                size="small"
              >
                {text}
              </Button>
            ),
        };
      },
    },

    {
      title: "DPJP",
      width: "250px",
      dataIndex: "namaDPJP",
      sorter: (a, b) => a.namaDPJP.localeCompare(b.namaDPJP),
      render(text, record) {
        return {
          props: {
            style: {
              cursor: "default",
            },
          },
          children: <Text style={{ fontSize: 12 }}>{text}</Text>,
        };
      },
    },
    {
      title: "No Reg",
      width: "75px",
      dataIndex: "registrasiId",
      sorter: (a, b) => a.registrasiId - b.registrasiId,
      render(text, record) {
        return {
          props: {
            style: {
              background:
                record.ruangKonsul !== null
                  ? record.ruangKonsul === ruangasal &&
                    record.ruangId !== ruangasal
                    ? "lime"
                    : record.ruangKonsul === ruangasal &&
                      record.ruangId === ruangasal
                    ? "yellow"
                    : record.ruangKonsul !== ruangasal &&
                      record.ruangId === ruangasal &&
                      !record.ruangKonsul.includes("A")
                    ? "lightpink"
                    : record.ruangKonsul !== ruangasal &&
                      record.ruangId === ruangasal &&
                      record.ruangKonsul.substring(0, 1) === "A"
                    ? "gold"
                    : ""
                  : "",
              cursor: "default",
            },
          },
          children:
            record.ruangKonsul !== null ? (
              record.ruangKonsul === ruangasal &&
              record.ruangId !== ruangasal ? (
                <Tooltip title="Konsultasi perlu dijawab">{text}</Tooltip>
              ) : record.ruangKonsul === ruangasal &&
                record.ruangId === ruangasal ? (
                <Tooltip title="Konsultasi sudah dijawab">{text}</Tooltip>
              ) : record.ruangKonsul !== ruangasal &&
                record.ruangId === ruangasal &&
                !record.ruangKonsul.includes("A") ? (
                <Tooltip title="Konsultasi belum dijawab">{text}</Tooltip>
              ) : record.ruangKonsul !== ruangasal &&
                record.ruangId === ruangasal &&
                record.ruangKonsul.substring(0, 1) === "A" ? (
                <Tooltip title="Konsultasi Alih Rawat">{text}</Tooltip>
              ) : (
                <Tag>{text}</Tag>
              )
            ) : (
              text
            ),
        };
      },
    },
    {
      title: "No. RM",
      width: "80px",
      dataIndex: "pasienId",
      render(text, record) {
        return {
          props: {
            style: {
              cursor: "default",
            },
          },
          children: <Text style={{ fontSize: 12 }}>{text}</Text>,
        };
      },
    },
    {
      title: "Penjamin",
      width: "200px",
      dataIndex: "namaPembayaran",
      sorter: (a, b) => a.namaPembayaran.localeCompare(b.namaPembayaran),
      render(text, record) {
        return {
          props: {
            style: {
              cursor: "default",
            },
          },
          children: <Text style={{ fontSize: 12 }}>{text}</Text>,
        };
      },
    },
  ];

  const MyPagination = ({ total, onChange, current }) => {
    return (
      <Pagination
        onChange={onChange}
        total={total}
        current={current}
        pageSize={15}
        simple={true}
        style={{ textAlign: "center" }}
        size="small"
        // showTotal={(total) => `Total ${total} pasien`}
        showSizeChanger={false}
        // showQuickJumper
      />
    );
  };
  const getData = (current, pageSize) => {
    // Normally you should get the data from the server
    return pasien.slice((current - 1) * pageSize, current * pageSize);
  };

  const onrefresh = () => {
    cariPasienHariIni("9510", dayjs().format("YYYY-MM-DD"));
    setRefresh(true);
  };
  return (
    <Fragment>
      <div>
        <Search
          placeholder="Cari Nama Pasien / No Reg..."
          onChange={(e) => handleCari(e.target.value)}
        />
        {layout ? (
          <div>
            <div ref={ref}>
              <MyPagination
                total={pasien.length}
                current={currentt}
                onChange={setCurrentt}
              />
            </div>
            <Table
              showSorterTooltip={false}
              onRow={(record, rowIndex) => {
                return {
                  onContextMenu: (event) => {
                    event.preventDefault();
                    if (!visible) {
                      document.addEventListener(
                        `click`,
                        function onClickOutside() {
                          setVisible(false);
                          document.removeEventListener(`click`, onClickOutside);
                        }
                      );
                    }
                    detailAntrian(record.registrasiId);
                    setVisible(true);
                    setX(event.clientX);
                    setY(event.clientY);
                  },
                  onClick: (event) => {
                    handleClick(record.registrasiId, record.pasienId);
                    // routeChange();
                  },
                };
              }}
              rowClassName={(record, index) =>
                record.registrasiId === pilih ? "colorpilih" : "fontkecil"
              }
              style={{ maxHeight: 570, fontSize: 8 }}
              columns={columnsrsms}
              // dataSource={pasien}
              dataSource={getData(currentt, 15)}
              size="small"
              scroll={{ x: 300, y: "52vh" }}
              // pagination={{
              //   size: "small",
              //   position: ["bottomCenter"],
              //   simple: [true],
              //   defaultPageSize: [15],
              // }}
              pagination={false}
              locale={{
                emptyText: (
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={"Silahkan Pilih Ruangan Terlebih Dahulu"}
                  />
                ),
              }}
              footer={() => (
                <div>
                  <Space>
                    Atur Lebar :
                    <Switch
                      onChange={onLebar}
                      checkedChildren="Max"
                      unCheckedChildren="Min"
                    />
                    <Button
                      size="small"
                      type="primary"
                      onClick={onrefresh}
                      style={{
                        backgroundColor: "forestgreen",
                        borderColor: "green",
                      }}
                    >
                      <UndoOutlined />
                      Refresh Pasien
                    </Button>
                  </Space>
                  <br />
                  Keterangan Warna Konsul :
                  <Row>
                    <div className="backkonsulsudah">Sudah Dijawab</div>&nbsp;
                    <div className="backkonsuljawab">Perlu Dijawab</div>&nbsp;
                    <div className="backkonsulperlu">Belum Dijawab</div>
                  </Row>
                </div>
              )}
            />
          </div>
        ) : (
          <Table
            showSorterTooltip={false}
            onRow={(record, rowIndex) => {
              return {
                onContextMenu: (event) => {
                  event.preventDefault();
                  if (!visible) {
                    document.addEventListener(
                      `click`,
                      function onClickOutside() {
                        setVisible(false);
                        document.removeEventListener(`click`, onClickOutside);
                      }
                    );
                  }
                  detailAntrian(record.registrasiId);
                  setVisible(true);
                  setX(event.clientX);
                  setY(event.clientY);
                },
                onClick: (event) => {
                  handleClick(record.registrasiId, record.pasienId);
                  //   routeChange();
                },
              };
            }}
            rowClassName={(record, index) =>
              record.registrasiId === pilih ? "colorpilih" : "fontkecil"
            }
            style={{ maxHeight: 570, fontSize: 8 }}
            columns={columnsrsms}
            dataSource={pasien}
            // dataSource={getData(currentt, 15)}
            size="small"
            scroll={{ x: 300, y: "52vh" }}
            // pagination={{
            //   size: "small",
            //   position: ["bottomCenter"],
            //   simple: [true],
            //   defaultPageSize: [15],
            // }}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={"Silahkan Pilih Ruangan Terlebih Dahulu"}
                />
              ),
            }}
            footer={() => (
              <div>
                <Space>
                  Atur Lebar :
                  <Switch
                    onChange={onLebar}
                    checkedChildren="Max"
                    unCheckedChildren="Min"
                  />
                  <Button
                    size="small"
                    type="primary"
                    onClick={onrefresh}
                    style={{
                      backgroundColor: "forestgreen",
                      borderColor: "green",
                    }}
                  >
                    <UndoOutlined />
                    Refresh Pasien
                  </Button>
                </Space>
                <br />
                Keterangan Warna Konsul :
                <Row>
                  <div className="backkonsulsudah">Sudah Dijawab</div>&nbsp;
                  <div className="backkonsuljawab">Perlu Dijawab</div>&nbsp;
                  <div className="backkonsulperlu">Belum Dijawab</div>
                </Row>
              </div>
            )}
          />
        )}
        <Popup />
      </div>
      <Tour
        open={open}
        onClose={() => setOpen(false)}
        steps={steps}
        // mask={false}
        // type="primary"
      />
    </Fragment>
  );
};

export default TabelpasienIGD;
