import { Card, Tabs } from 'antd'
import React, { useContext } from 'react'
import FormStatusEksternal from '../FormStatusEksternal';
import { RadioterapiContext } from '../../context/RadioterapiContext';
import RiwayatStsEksterna from './RiwayatStsEksterna';
import '../../style/style.css'

const { TabPane } = Tabs;

const MenuTabStsEksternal = () => {
    const {
        tabKeyStsEks, settabKeyStsEks,
    } = useContext(RadioterapiContext)

    return (
        <div>
            <Card bodyStyle={{ padding: '0px' }}>
                <Tabs
                    // defaultActiveKey={tabKey}
                    activeKey={tabKeyStsEks}
                    onChange={(e) => settabKeyStsEks(e)}
                    size='small'
                    type='card'>
                    <TabPane tab="Status Eksterna" key="1">
                        <FormStatusEksternal />
                    </TabPane>
                    <TabPane tab="Riwayat" key="2">
                        <RiwayatStsEksterna />
                    </TabPane>
                </Tabs>
            </Card>
        </div>
    )
}

export default MenuTabStsEksternal