import { Col, DatePicker, Input, Row, Select } from "antd";
import React from "react";
const { Option } = Select;

const FormNIHSS = () => {
  return (
    <div>
      <Row>
        <Col span={1}></Col>
        <Col span={8}>
          <p>Tanggal Pengkajian</p>
        </Col>
        <Col span={11}>
          <DatePicker
            //   value={tanggalKontrol}
            format="DD-MM-YYYY"
            onChange={(date) => {}}
            style={{ width: "100%" }}
            placeholder="..."
          />
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>1</p>
        </Col>
        <Col span={8}>
          <p>a. Tingkat kesadaran</p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Sadar Penuh</Option>
            <Option value={1}>Somnolen</Option>
            <Option value={2}>Stupor</Option>
            <Option value={3}>Koma</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p></p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>b. Menjawab pertanyaan. Tanyakan bulan dan usia pasien. </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Benar semua (2 pertanyaan)</Option>
            <Option value={1}>1 Benar/ETT/disartria</Option>
            <Option value={2}>Salah semua/afasia/stupor/koma</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p></p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>
            c. Mengikuti perintah. Berikan 2 perintah sederhana, membuka dan
            menutup mata, menggenggam tangan dan melepaskannya atau 2 perintah
            lain
          </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Mampu melakukan 2 perintah</Option>
            <Option value={1}>Mampu melakukan 1 perintah</Option>
            <Option value={2}>Tidak mampu melakukan perintah</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>2.</p>
        </Col>
        <Col span={8}>
          <p>Gaze : gerakan mata konyugat horizontal</p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Normal</Option>
            <Option value={1}>Abnormal pada satu mata</Option>
            <Option value={2}>
              Deviasi konyugat kuat atau paresis konyugat pada 2 mata
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>3</p>
        </Col>
        <Col span={8}>
          <p>Visual : lapang pandang pada tes konfrontasi</p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada gangguan</Option>
            <Option value={1}>Kuadrianopsia</Option>
            <Option value={2}>Hemianopia total</Option>
            <Option value={3}>Hemianopia bilateral/buta kortikal</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>4</p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>
            Paresis wajah. Anjurkan pasien menyeringai atau mengangkat alis dan
            menutup mata
          </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Normal</Option>
            <Option value={1}>
              Paresis wajah ringan /lipatan nasolabial datar, senyum asimetris
            </Option>
            <Option value={2}>
              Paresis wajah partial /paresis wajah bawah total atau hampir total
            </Option>
            <Option value={3}>
              Paresis wajah total /paresis wajah sesisi atau 2 sisi
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>5.</p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>
            KANAN Motorik lengan. Anjurkan pasien mengangkat lengan 45⁰ bila
            tidur berbaring atau 90⁰ bila posisi duduk. Bila pasien afasia
            berikan perintah menggunakan pantomime atau peragaan
          </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Mampu mengangkat lengan minimal 10 detik</Option>
            <Option value={1}>Lengan terjatuh sebelum 10 detik</Option>
            <Option value={2}>
              Tidak mampu mengangkat secara penuh 90⁰ atau 45⁰
            </Option>
            <Option value={3}>Tidaka mampu mengakat hanya bergeser</Option>
            <Option value={4}>Tidak ada gerakan</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p></p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>
            KIRI Motorik lengan. Anjurkan pasien mengangkat lengan 45⁰ bila
            tidur berbaring atau 90⁰ bila posisi duduk. Bila pasien afasia
            berikan perintah menggunakan pantomime atau peragaan
          </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Mampu mengangkat lengan minimal 10 detik</Option>
            <Option value={1}>Lengan terjatuh sebelum 10 detik</Option>
            <Option value={2}>
              Tidak mampu mengangkat secara penuh 90⁰ atau 45⁰
            </Option>
            <Option value={3}>Tidaka mampu mengakat hanya bergeser</Option>
            <Option value={4}>Tidak ada gerakan</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>6</p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>
            KANAN Motorik tungkai. Anjurkan pasien tidur terlentang dan
            mengangkat tungkai 30⁰
          </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Mampu mengangkat tungkai minimal 5 detik</Option>
            <Option value={1}>
              Tungkai jatuh ke tempat tidur pada akhir detik ke-5 secara
              perlahan
            </Option>
            <Option value={2}>
              Tungkai jatuh sebelum 5 detik tetapi ada usaha untuk melawan
              gravitasi
            </Option>
            <Option value={3}>Tidaka mampu melawan gravitasi</Option>
            <Option value={4}>Tidak ada gerakan</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p></p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>
            KIRI Motorik tungkai. Anjurkan pasien tidur terlentang dan
            mengangkat tungkai 30⁰
          </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Mampu mengangkat tungkai minimal 5 detik</Option>
            <Option value={1}>
              Tungkai jatuh ke tempat tidur pada akhir detik ke-5 secara
              perlahan
            </Option>
            <Option value={2}>
              Tungkai jatuh sebelum 5 detik tetapi ada usaha untuk melawan
              gravitasi
            </Option>
            <Option value={3}>Tidaka mampu melawan gravitasi</Option>
            <Option value={4}>Tidak ada gerakan</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>7.</p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>Ataksia anggota badan. Menggunakan test unjuk jari hidung</p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada ataksia</Option>
            <Option value={1}>Ataksia pada satu ekstremitas</Option>
            <Option value={2}>Ataksia pada dua atau lebih ekstremitas</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>8.</p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>
            Sensorik. Lakukan tes pada seluruh tubuh, tungkai, lengan, badan,
            dan wajah. Pasien afasia di beri nilai 1 Pasien stupor atau koma
            diberi nilai 2
          </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Normal</Option>
            <Option value={1}>
              Gangguan sensorik ringan hingga sedang. Ada gangguan sensorik
              terhadap nyeri tetapi masih merasa bila disentuh
            </Option>
            <Option value={2}>Gangguan sensorik berat atau total</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>9.</p>
        </Col>
        <Col span={8} style={{ wordWrap: "break-word" }}>
          <p>
            Kemampuan berbahasa. Anjurkan pasien untuk menjelaskan suatu gambar
            atau membaca suatu tulisan. Bila pasien mengalami kebutaan, letakkan
            suatu benda di tangan pasien dan anjurkan untuk menjelaskan benda
            tersebut.
          </p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Normal</Option>
            <Option value={1}>Afasia ringan hingga sedang</Option>
            <Option value={2}>Afasia berat</Option>
            <Option value={3}>Mute, afasia global, coma</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>10.</p>
        </Col>
        <Col span={8}>
          <p>Disartria</p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Normal</Option>
            <Option value={1}>Disartria ringan</Option>
            <Option value={2}>Disartria berat</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p>11.</p>
        </Col>
        <Col span={8}>
          <p>Neglect atau inatensi</p>
        </Col>
        <Col span={11}>
          <Select
            // value={smftujuan}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {}}
            showSearch
            style={{ width: "100%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada neglect</Option>
            <Option value={1}>
              Tidak ada antensi pada salah satu modalitas berikut: visual,
              tactile, auditory, spatial, or personal inattention
            </Option>
            <Option value={2}>
              Tidak ada atensi pada lebih dari satu modalitas
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={1}>
          <p></p>
        </Col>
        <Col span={8}>
          <p>Keterangan</p>
        </Col>
        <Col span={11}>
          <Input.Group compact>
            <Input
              type="text"
              placeholder="..."
              readOnly
              // value={}
              style={{ width: "20%" }}
            />
            <Input
              type="text"
              placeholder="..."
              disabled
              // value={ketLatchScore}
              // style={stylekuLatchScore}
              readOnly
              style={{ width: "80%" }}
            />
          </Input.Group>
        </Col>
      </Row>
    </div>
  );
};

export default FormNIHSS;
