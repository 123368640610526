/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { createContext, useContext, useEffect, useState } from 'react'
import { LoginContext } from '../../../rawatjalan/context';
import { Modal, message } from 'antd';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';

export const SatuSehatEncounterContext = createContext();

const SatuSehatEncounterContextProvider = (props) => {
    const { token, namauser } = useContext(LoginContext);
    const options = {
        headers: { Authorization: "Bearer " + token },
    };

    const ipEndpoint = sessionStorage.getItem("apiPenunjang");

    const baseURL = `${ipEndpoint}/`
    // const baseURL = "http://182.168.7.251:5577/"
    // const baseURL = "http://182.168.0.119/api/"

    const ip = sessionStorage.getItem("IP");
    const host = sessionStorage.getItem("Host");
    const env = sessionStorage.getItem("environment");

    const [sstoken, setsstoken] = useState(null);
    const [SSTokenExp, setSSTokenExp] = useState();
    const [baseUrlSS, setbaseUrlSS] = useState();
    const [tempA, settempA] = useState();
    const [username, setusername] = useState(namauser);
    const [ihsRS, setihsRS] = useState();
    const [listRuang, setlistRuang] = useState([]);
    const [gedung, setgedung] = useState(null);
    const [listPasien, setlistPasien] = useState([]);
    const [ruangId, setruangId] = useState(null);
    const [tglPelayanan, settglPelayanan] = useState(dayjs());
    // formKirim
    const [uuidEncounter, setuuidEncounter] = useState(null);
    const [regId, setregId] = useState(null);
    const [pasienId, setpasienId] = useState(null);
    const [namaPasien, setnamaPasien] = useState(null);
    const [nikPasien, setnikPasien] = useState(null);
    const [alamat, setalamat] = useState(null);
    const [ihsPasien, setihsPasien] = useState(null);
    const [jenkelPasien, setjenkelPasien] = useState();
    const [tglLahirPasien, settglLahirPasien] = useState();
    const [namaDokter, setnamaDokter] = useState(null);
    const [ihsPracticioner, setihsPracticioner] = useState(null);
    const [mulaiPelayanan, setmulaiPelayanan] = useState(null);
    const [selesaiPelayanan, setselesaiPelayanan] = useState(null);
    const [namaRuang, setnamaRuang] = useState(null);
    const [ihsRuang, setihsRuang] = useState(null);
    const [dcPlanning, setdcPlanning] = useState(null);
    const [pendStart, setpendStart] = useState(null);
    const [pendEnd, setpendEnd] = useState(null);
    const [pmrStart, setpmrStart] = useState(null);
    const [pmrEnd, setpmrEnd] = useState(null);
    const [pulangStart, setpulangStart] = useState(null);
    const [pulangEnd, setpulangEnd] = useState(null);
    const [diagnosis, setdiagnosis] = useState([]);
    const [listResourceId, setlistResourceId] = useState([]);
    // observation
    const [nadi, setnadi] = useState();
    const [respRate, setrespRate] = useState();
    const [sistol, setsistol] = useState();
    const [diastol, setdiastol] = useState();
    const [suhu, setsuhu] = useState();
    const [jamTdVital, setjamTdVital] = useState();
    // procedure
    const [SnProcedure, setSnProcedure] = useState([]);
    const [procedure, setprocedure] = useState([]);
    // Composition
    const [composition, setcomposition] = useState();
    // Madecation
    const [medication, setmedication] = useState([]);
    const [medValid, setmedValid] = useState([]);
    const [waktuOrderResep, setwaktuOrderResep] = useState();
    const [waktuValidResep, setwaktuValidResep] = useState();
    // Service Request
    const [serviceRequest, setserviceRequest] = useState([]);
    // Diag Report
    const [diagReport, setdiagReport] = useState([]);
    const [chkBtaPositif, setchkBtaPositif] = useState(false);
    // Allergi Intoleran
    const [allergi, setallergi] = useState([]);
    const [chkAllergi, setchkAllergi] = useState(false);
    // Clinical Impression
    const [clinicImp, setclinicImp] = useState([]);
    const [chkClinicImp, setchkClinicImp] = useState(false);
    // Rencana Tindak Lanjut
    const [rencanaTL, setrencanaTL] = useState([]);
    const [chkRencanaTL, setchkRencanaTL] = useState(false);
    // respon quisioner
    const [chkKuisioner, setchkKuisioner] = useState(false);
    // catatan pengobatan
    const [chkNoteMed, setchkNoteMed] = useState(false);
    // form detail ihs pasien
    const [ihstgllahir, setihstgllahir] = useState(null);
    const [ihsalamat, setihsalamat] = useState(null);
    const [ihsjenkel, setihsjenkel] = useState(null);
    const [ihsihsnumber, setihsihsnumber] = useState(null);
    const [ihsnik, setihsnik] = useState(null);
    const [ihslastupdate, setihslastupdate] = useState(null);
    const [ihsnama, setihsnama] = useState(null);
    // temp display notif
    const [tempNotif, settempNotif] = useState(0);
    // md
    const [mdDetailKirim, setmdDetailKirim] = useState(false);
    const [mdIhsPasien, setmdIhsPasien] = useState(false);
    // sp
    const [spTbPasien, setspTbPasien] = useState(false);
    const [spDetailKirim, setspDetailKirim] = useState(false);

    const usr = sessionStorage.getItem("user");

    useEffect(() => {
        getDetailEnv(env);
    }, [])

    // get token
    const SatuSehatGetToken = () => {
        setspTbPasien(true);
        axios
            .post(`${baseURL}SatuSehat/Token`, {
                headers: options.headers,
            })
            .then((res) => {
                // console.log('satuSehat : ', res);
                setspTbPasien(false);
                if (res.status === 200) {
                    setsstoken(res.data.access_token);
                } else {
                    Modal.error({
                        title: 'Gagal!',
                        content: `Gagal mengambil Token SATUSEHAT!`,
                    });
                }
            })
            .catch((err) => {
                setspTbPasien(false);
                Modal.error({
                    title: "ERROR!",
                    content: `ERROR!, melakukan proses mengambil Token SATUSEHAT! -> ${err}`,
                });
            });
    }

    // get environment
    const getDetailEnv = (data) => {
        setspTbPasien(true)
        axios
            .get(`${baseURL}SatuSehat/GetEnvbyEnv/${data}`, options)
            .then((response) => {
                setspTbPasien(false)
                console.log('getDetailEnv : ', response);
                if (response.data && response.data.statusCode) {
                    Modal.error({
                        title: "Gagal!",
                        content: `Gagal! GET detail Environment! -> ${response.data.message}`,
                    });
                }
                else {
                    Modal.info({
                        title: "Informasi!",
                        content: `Berhasil! -> ${response.data.message}`,
                        onOk: () => {
                            setsstoken(response.data.result.token)
                            setbaseUrlSS(response.data.result.base_url)
                            setihsRS(response.data.result.org_id)
                            setSSTokenExp(response.data.result.expired)
                            settempA(1)
                            console.log('detail Env : ', response.data)
                        },
                    });

                }
            })
            .catch((err) => {
                setspTbPasien(false)
                Modal.error({
                    title: "ERROR!",
                    content: `ERROR! GET data Detail Environment! -> ${err}`,
                });
            });
    }

    const getListRuang = (gedung) => {
        setspTbPasien(true)
        axios
            .get(`${baseURL}SisJwt/RuangByUser/${usr}/%20/2/${gedung}`, options)
            .then((response) => {
                setspTbPasien(false)
                console.log('getListKtg : ', response);
                if (response.data.statusCode === 200) {
                    if (response.data.result !== 0) {
                        message.success("Berhasil ambil Daftar Ruang!");
                        setlistRuang(response.data.result)
                    } else {
                        setlistRuang([]);
                        Modal.info({
                            title: "Info",
                            content: 'Tidak ada List Ruang.',
                        });
                    }
                }
                else {
                    Modal.error({
                        title: "Gagal!",
                        content: `Gagal! GET data List Ruang! -> ${response.data.message}`,
                    });
                }
            })
            .catch((err) => {
                setspTbPasien(false)
                Modal.error({
                    title: "ERROR!",
                    content: `ERROR! GET data List Ruang! -> ${err}`,
                });
            });
    }

    const getListPasien = (ruangId, tgl) => {
        setspTbPasien(true)
        axios
            .get(`${baseURL}SatuSehat/lookuppasienbypoli/${ruangId}/${tgl}`, options)
            .then((response) => {
                setspTbPasien(false)
                settempNotif(0)
                console.log('getListKtg : ', response);
                if (response.data.statusCode === 200) {
                    if (response.data.result.length !== 0) {
                        setlistPasien(response.data.result)
                    } else {
                        setlistPasien([]);
                        Modal.info({
                            title: "Info",
                            content: 'Tidak ada Pasien ditemukan.',
                        });
                    }
                }
                else {
                    Modal.error({
                        title: "Gagal!",
                        content: `Gagal! GET data Daftar Pasien! -> ${response.data.message}`,
                    });
                }
            })
            .catch((err) => {
                setspTbPasien(false)
                Modal.error({
                    title: "ERROR!",
                    content: `ERROR! GET data Daftar Pasien! -> ${err}`,
                });
            });
    }

    const rstDetailKirim = () => {
        setuuidEncounter(null);
        setregId(null);
        setnamaPasien(null);
        setnikPasien(null);
        setalamat(null);
        setihsPasien(null);
        setjenkelPasien();
        settglLahirPasien();
        setnamaDokter(null);
        setihsPracticioner(null);
        setmulaiPelayanan(null);
        setselesaiPelayanan(null);
        setnamaRuang(null);
        setihsRuang(null);
        setdcPlanning(null);
        setpendStart(null);
        setpendEnd(null);
        setpmrStart(null);
        setpmrEnd(null);
        setpulangStart(null);
        setpulangEnd(null);
        setdiagnosis([]);
        setSnProcedure([]);
        setprocedure([]);
        setcomposition();
        setmedication([]);
        setmedValid([]);
        setwaktuOrderResep("-");
        setwaktuValidResep("-");
        setnadi(null);
        setrespRate(null);
        setsistol(null);
        setdiastol(null);
        setsuhu(null);
        setjamTdVital(dayjs());
        setserviceRequest([]);
        setdiagReport([]);
        setchkBtaPositif(false);
        setallergi([]);
        setchkAllergi(false);
        setclinicImp([]);
        setchkClinicImp(false);
        setrencanaTL([]);
        setchkRencanaTL(false);
        setchkKuisioner(false);
        setchkNoteMed(false);
    }

    const getDetailKirim = (regid, ruangId) => {
        setspTbPasien(true)
        axios
            .get(`${baseURL}SatuSehat/detailkirimss/${regid}/${ruangId}/${env}`, options)
            .then((response) => {
                setspTbPasien(false)
                console.log('getDetailKirim : ', response);
                if (response.data.result.code === "200") {
                    let testuuid = uuidv4();

                    setuuidEncounter(testuuid);
                    setregId(response.data.result.result.RegistrasiId);
                    setnamaPasien(response.data.result.result.Nama);
                    setnikPasien(response.data.result.result.Nik);
                    setalamat(response.data.result.result.Alamat);
                    setihsPasien(response.data.result.result.IHSNumber); // => ws masih kurang get ihs
                    setjenkelPasien(response.data.result.result.JenisKelamin);
                    settglLahirPasien(response.data.result.result.TanggalLahir);
                    setnamaDokter(response.data.result.result.NamaDokter);
                    setihsPracticioner(response.data.result.result.IhsNumber);
                    setmulaiPelayanan(response.data.result.result.JamRegistrasi);
                    setselesaiPelayanan(response.data.result.result.JamEndLayanan);
                    setnamaRuang(response.data.result.result.NamaRuang);
                    setihsRuang(response.data.result.result.SatuSehatId);
                    setdcPlanning(response.data.result.result.Planning);

                    if (response.data.result.result.BaruLama === 'L') {
                        setpendStart(dayjs(response.data.result.result.JamRegistrasi).subtract(60, 'second'));
                    }
                    else {
                        setpendStart(dayjs(response.data.result.result.JamRegistrasi).subtract(180, 'second'));
                    }

                    setpendEnd(response.data.result.result.JamRegistrasi);
                    setpmrStart(response.data.result.result.JamMulaiLayanan);
                    setpmrEnd(response.data.result.result.JamEndLayanan);
                    setpulangStart(response.data.result.result.JamEndLayanan);
                    setpulangEnd(response.data.result.result.JamEndLayanan);

                    const updatedData = response.data.result.result.Diagnosis.map((item) => {
                        const uuid = uuidv4();
                        return { ...item, uuid }; // Menambahkan properti uuid ke setiap objek data
                    });
                    setdiagnosis(updatedData);
                    console.log('Diagnosa : ', updatedData);

                    // set observation
                    if (response.data.result.result.Observation) {
                        setnadi(response.data.result.result.Observation.FrekuensiNadi);
                        setrespRate(response.data.result.result.Observation.FrekuensiNafas);
                        setsistol(response.data.result.result.Observation.TekananDarahSistolik);
                        setdiastol(response.data.result.result.Observation.TekananDarahDiastolik);
                        setsuhu(response.data.result.result.Observation.SuhuTubuh);
                        setjamTdVital(response.data.result.result.Observation.Jam);
                    }

                    // set SnProcedure
                    if (response.data.result.result && response.data.result.result.SNProcedure && response.data.result.result.SNProcedure.length !== 0) {
                        const dataSnProc = response.data.result.result.SNProcedure.map((item) => {
                            const uuid = uuidv4();
                            return { ...item, uuid }; // Menambahkan properti uuid ke setiap objek data
                        });
                        setSnProcedure(dataSnProc);
                    }

                    // set procedure
                    if (response.data.result.result && response.data.result.result.Icd9 && response.data.result.result.Icd9.length !== 0) {
                        // const dataICD9 = response.data.result.result.Icd9.map((item) => {
                        //     const uuid = uuidv4();
                        //     return { ...item, uuid }; // Menambahkan properti uuid ke setiap objek data
                        // });
                        setprocedure(response.data.result.result.Icd9);
                    }

                    // set composition
                    if (response.data.result.result.Composition) {
                        setcomposition(response.data.result.result.Composition.Planning);
                    }

                    // set medication order
                    if (response.data.result.result.Medication.orderList.length !== 0) {
                        setmedication(response.data.result.result.Medication.orderList);

                        let a = response.data.result.result.Medication.orderList.length - 1;

                        // console.log('b : ', b);
                        setwaktuOrderResep(dayjs(response.data.result.result.Medication.orderList[a].WaktuEntry).format('DD-MM-YYYY HH:mm'));
                    }
                    console.log('medication : ', response.data.result.result.Medication.orderList);

                    if (response.data.result.result.MedicationDispense.length !== 0) {
                        let b = response.data.result.result.MedicationDispense.length - 1;
                        // Jika properti DATEENTRY ada dalam objek Valid, maka lakukan format tanggal
                        setwaktuValidResep(
                            dayjs(response.data.result.result.MedicationDispense[b].WaktuResep).format('DD-MM-YYYY HH:mm')
                        );
                        // set medication valid
                        setmedValid(response.data.result.result.MedicationDispense);
                    }

                    // set service order
                    if (response.data.result.result.ServiceRequest.length !== 0) {
                        setserviceRequest(response.data.result.result.ServiceRequest);
                    }

                    // set diagReport
                    if (response.data.result.result.DiagReport.length !== 0) {
                        setdiagReport(response.data.result.result.DiagReport);
                    }

                    // set allergi
                    if (response.data.result.result.AllergiIntolerance.length !== 0) {
                        setallergi(response.data.result.result.AllergiIntolerance);
                    }

                    // set clinical imp
                    if (response.data.result.result.ClinicalImpression.length !== 0) {
                        setclinicImp(response.data.result.result.ClinicalImpression);
                    }

                    // set rencana tindak lanjut
                    if (response.data.result.result.RencanaTindakLanjut.length !== 0) {
                        setrencanaTL(response.data.result.result.RencanaTindakLanjut);
                    }

                    setmdDetailKirim(true);

                    // generateUUIDs(response.data.result.Diagnosis)
                    // if (response.data.result.length !== 0) {
                    //     setlistPasien(response.data.result)
                    // } else {
                    //     setlistPasien([]);
                    //     Modal.info({
                    //         title: "Info",
                    //         content: 'Tidak ada Pasien ditemukan.',
                    //     });
                    // }
                }
                else {
                    Modal.error({
                        title: "Gagal!",
                        content: `Gagal! GET data Detail Kirim Pasien! -> ${response.data.result.message}, No.Registrasi : ${response.data.result.result}`,
                    });
                }
            })
            .catch((err) => {
                setspTbPasien(false)
                Modal.error({
                    title: "ERROR!",
                    content: `ERROR! GET data Daftar Pasien! -> ${err}`,
                });
            });
    }

    const generateUUIDs = (data) => {
        const uuids = data.map((item) => {
            const uuid = uuidv4();
            return uuid;
        });

        console.log('generateUUIDs : ', uuids);
    };

    const getIhsPasien = (nik) => {
        setspDetailKirim(true)
        // console.log('token url : ', SSToken, encodeURIComponent(baseUrlSS));

        settempA(0)

        // Waktu 1 dalam format ISO
        const waktu1 = dayjs(SSTokenExp);

        // Waktu 2 adalah waktu sekarang
        const waktu2 = dayjs();

        // Menghitung selisih waktu dalam menit
        const selisihMenit = waktu2.diff(waktu1, "minute");

        // Menentukan jika selisih lebih dari 45 menit
        if (selisihMenit >= 45) {
            // Lakukan sesuatu jika lebih dari 45 menit
            console.log("Lebih dari 45 menit");

            getDetailEnv(env);
        } else {
            // Lakukan sesuatu jika kurang dari atau sama dengan 45 menit
            console.log("Kurang dari atau sama dengan 45 menit");
            settempA(1)
        }

        if (tempA === 1) {
            axios
                .get(`${baseURL}SatuSehat/GetPasienByNIK/${nik}/${sstoken}/${encodeURIComponent(baseUrlSS)}`, options)
                .then((response) => {
                    setspDetailKirim(false)
                    console.log('getIhsPasien : ', response);
                    if (response.data.hasOwnProperty('statusCode')) {
                        Modal.error({
                            title: "Gagal!",
                            content: response.data.result,
                        });
                    }
                    else {
                        if (response.data.length === 1) {
                            setihstgllahir(response.data[0].resource.hasOwnProperty('birthDate') ? response.data[0].resource.birthDate : '-')
                            setihsalamat(response.data[0].resource.hasOwnProperty('address') ? response.data[0].resource.address.line[0] : '-')
                            setihsjenkel(response.data[0].resource.hasOwnProperty('gender') ? response.data[0].resource.gender : '-')
                            setihsihsnumber(response.data[0].resource.hasOwnProperty('id') ? response.data[0].resource.id : '-')
                            setihsnik(response.data[0].resource.hasOwnProperty('identifier') ? response.data[0].resource.identifier[1].value : '-')
                            setihslastupdate(response.data[0].resource.hasOwnProperty('meta') ? response.data[0].resource.meta.lastUpdated : '-')
                            setihsnama(response.data[0].resource.hasOwnProperty('name') ? response.data[0].resource.name[0].text : '-')
                            setmdIhsPasien(true)
                        }
                        else if (response.data.length > 1) {
                            Modal.info({
                                title: "Informasi",
                                content: 'Ditemukan detail Pasien dari SatuSehat LEBIH DARI 1. Cek Console log untuk melihat detail response.',
                            });
                            console.log('isi detail respon : ', response.data);
                        }
                        else {
                            Modal.info({
                                title: "Informasi",
                                content: 'Tidak ditemukan detail Pasien dari SatuSehat.',
                            });
                        }
                    }
                })
                .catch((err) => {
                    setspDetailKirim(false)
                    Modal.error({
                        title: "ERROR!",
                        content: `ERROR! GET IHS Number Pasien! -> ${err}`,
                    });
                });
        }
    }

    const insertIhsPasien = (data) => {
        setspDetailKirim(true)
        axios
            .post(`${baseURL}SatuSehat/insertihspasien
            `, data, {
                headers: options.headers,
            })
            .then((res) => {
                setspDetailKirim(false)
                console.log('satuSehat : ', res);
                console.log('satuSehat data : ', res.data.statusCode);
                if (res.data.statusCode === 200) {
                    Modal.success({
                        title: "Sukses",
                        content: "Berhasil Disimpan data Ihs Number Pasien.",
                        onOk: () => {
                            setmdIhsPasien(false)
                            setihsPasien(res.data.result)
                        },
                    });
                }
                else {
                    Modal.error({
                        title: "Gagal!",
                        content: `Gagal Disimpan Ihs Number Pasien! -> ${res.data.message}`,
                    });
                }
            })
            .catch((err) => {
                setspDetailKirim(false)
                Modal.error({
                    title: "ERROR!",
                    content: `ERROR Disimpan Ihs Number Pasien! -> ${err}`,
                });
            });
    }

    const kirimBundle = (data) => {
        setspDetailKirim(true)
        // console.log('token url : ', SSToken, encodeURIComponent(baseUrlSS));

        settempA(0)

        // Waktu 1 dalam format ISO
        const waktu1 = dayjs(SSTokenExp);

        // Waktu 2 adalah waktu sekarang
        const waktu2 = dayjs();

        // Menghitung selisih waktu dalam menit
        const selisihMenit = waktu2.diff(waktu1, "minute");

        // Menentukan jika selisih lebih dari 45 menit
        if (selisihMenit >= 45) {
            // Lakukan sesuatu jika lebih dari 45 menit
            console.log("Lebih dari 45 menit");

            getDetailEnv(env);
        } else {
            // Lakukan sesuatu jika kurang dari atau sama dengan 45 menit
            console.log("Kurang dari atau sama dengan 45 menit");
            settempA(1)
        }

        if (tempA === 1) {
            axios
                .post(`${baseURL}SatuSehat/Bundle/${sstoken}/${encodeURIComponent(baseUrlSS)}`, data, {
                    headers: options.headers,
                })
                .then((res) => {
                    setspDetailKirim(false)
                    console.log('kirimBundle response : ', res);

                    if (res.data.result.result.hasOwnProperty('entry')) {
                        setlistResourceId(res.data.result.result.entry)
                        Modal.success({
                            title: "Sukses",
                            content: 'Berhasil mengirim data ke SatuSehat.',
                            onOk: () => {
                                let temp = res.data.result.result.entry;

                                const updatedEntryData = temp.map((entry) => {
                                    entry.registrasiId = regId;
                                    entry.userId = username;
                                    entry.clientHost = host;
                                    entry.clientIP = ip;
                                    entry.environment = env;
                                    entry.ruangId = ruangId;
                                    return entry;
                                });
                                insertResourceId(updatedEntryData)

                                console.log('simpan resourceID : ', updatedEntryData);
                            },
                        });
                    }
                    else {
                        Modal.error({
                            title: "Gagal!",
                            content: 'Gagal! mengirim data ke SatuSehat!',
                        });
                    }
                })
                .catch((err) => {
                    setspDetailKirim(false)
                    Modal.error({
                        title: "ERROR!",
                        content: `ERROR! Kirim Bundle SatuSehat! -> ${err}`,
                    });
                });
        }

        // axios
        //     .post(`${baseURL}SatuSehat/Bundle/${sstoken}/${encodeURIComponent(baseUrlSS)}`, data, {
        //         headers: options.headers,
        //     })
        //     .then((res) => {
        //         setspDetailKirim(false)
        //         console.log('response kirimBundle : ', res);
        //         // if (res.data.result.result.hasOwnProperty('entry')) {
        //         //     setlistResourceId(res.data.result.result.entry)
        //         //     Modal.success({
        //         //         title: "Sukses",
        //         //         content: 'Berhasil mengirim data ke SatuSehat.',
        //         //         onOk: () => {
        //         //             let temp = res.data.result.result.entry;

        //         //             const updatedEntryData = temp.map((entry) => {
        //         //                 entry.registrasiId = regId;
        //         //                 entry.userId = username;
        //         //                 entry.clientHost = host;
        //         //                 entry.clientIP = ip;
        //         //                 return entry;
        //         //             });
        //         //             insertResourceId(updatedEntryData)

        //         //             console.log('simpan resourceID : ', updatedEntryData);
        //         //         },
        //         //     });
        //         // }
        //         // else {
        //         //     Modal.error({
        //         //         title: "Gagal!",
        //         //         content: 'Gagal! mengirim data ke SatuSehat!',
        //         //     });
        //         // }
        //         // setresPostEncounter(res.data.result)
        //         // setidEncounter(res.data.result.id)
        //     })
        //     .catch((err) => {
        //         setspDetailKirim(false)
        //         Modal.error({
        //             title: "ERROR!",
        //             content: `ERROR! Mengirim data SatuSehat! -> ${err}`,
        //         });
        //     });
    }

    const insertResourceId = (data) => {
        setspDetailKirim(true)
        axios
            .post(`${baseURL}SatuSehat/insertresourceid`, data, {
                headers: options.headers,
            })
            .then((res) => {
                setspDetailKirim(false)
                console.log('satuSehat : ', res);
                if (res.data.statusCode === 200) {
                    Modal.success({
                        title: "Sukses",
                        content: "Berhasil Disimpan Resource Id SatuSehat.",
                        onOk: () => {
                            setmdDetailKirim(false)
                            getListPasien(ruangId, dayjs(tglPelayanan).format('YYYY-MM-DD'))
                        },
                    });
                }
                else {
                    Modal.error({
                        title: "Gagal!",
                        content: `Gagal Disimpan Resource Id SatuSehat! -> ${res.data.message}`,
                    });
                }
            })
            .catch((err) => {
                setspDetailKirim(false)
                Modal.error({
                    title: "ERROR!",
                    content: `ERROR Disimpan Resource Id SatuSehat! -> ${err}`,
                });
            });
    }

    const kirimTele = () => {
        // setspDetailKirim(true)
        let data = {
            clientId: "MON",
            emote: "2",
            message: `UWER-UWER TERDETEKSII GAEES....!!!! IP Pengirim : ${ip}`
        };

        if (data) {
            axios
                .post(`${baseURL}BridgeTele`, data, {
                    headers: options.headers,
                })
                .then((res) => {
                    // setspDetailKirim(false)
                    console.log('BridgeTele : ', res);
                    // if (res.data.statusCode === 200) {
                    //     Modal.success({
                    //         title: "Sukses",
                    //         content: "Berhasil Disimpan Resource Id SatuSehat.",
                    //         onOk: () => {
                    //             setmdDetailKirim(false)
                    //             getListPasien(ruangId, dayjs(tglPelayanan).format('YYYY-MM-DD'))
                    //         },
                    //     });
                    // }
                    // else {
                    //     Modal.error({
                    //         title: "Gagal!",
                    //         content: `Gagal Disimpan Resource Id SatuSehat! -> ${res.data.message}`,
                    //     });
                    // }
                })
                .catch((err) => {
                    // setspDetailKirim(false)
                    Modal.error({
                        title: "ERROR!",
                        content: `ERROR Pengiriman Pesan Telegram! -> ${err}`,
                    });
                });
        }

    }

    return (
        <SatuSehatEncounterContext.Provider
            value={{
                usr,
                username, setusername,
                sstoken, setsstoken,
                ihsRS, setihsRS,
                listRuang, setlistRuang,
                gedung, setgedung,
                listPasien, setlistPasien,
                ruangId, setruangId,
                tglPelayanan, settglPelayanan,
                // formKirim
                uuidEncounter, setuuidEncounter,
                regId, setregId,
                pasienId, setpasienId,
                namaPasien, setnamaPasien,
                nikPasien, setnikPasien,
                alamat, setalamat,
                ihsPasien, setihsPasien,
                jenkelPasien, setjenkelPasien,
                tglLahirPasien, settglLahirPasien,
                namaDokter, setnamaDokter,
                ihsPracticioner, setihsPracticioner,
                mulaiPelayanan, setmulaiPelayanan,
                selesaiPelayanan, setselesaiPelayanan,
                namaRuang, setnamaRuang,
                ihsRuang, setihsRuang,
                dcPlanning, setdcPlanning,
                pendStart, setpendStart,
                pendEnd, setpendEnd,
                pmrStart, setpmrStart,
                pmrEnd, setpmrEnd,
                pulangStart, setpulangStart,
                pulangEnd, setpulangEnd,
                diagnosis, setdiagnosis,
                // observation
                nadi, setnadi,
                respRate, setrespRate,
                sistol, setsistol,
                diastol, setdiastol,
                suhu, setsuhu,
                jamTdVital, setjamTdVital,
                // procedure
                SnProcedure, setSnProcedure,
                procedure, setprocedure,
                // composition
                composition, setcomposition,
                // medication
                medication, setmedication,
                medValid, setmedValid,
                waktuOrderResep, setwaktuOrderResep,
                waktuValidResep, setwaktuValidResep,
                // service order
                serviceRequest, setserviceRequest,
                // diagReport
                diagReport, setdiagReport,
                chkBtaPositif, setchkBtaPositif,
                // allergi
                allergi, setallergi,
                chkAllergi, setchkAllergi,
                // clinic imp
                clinicImp, setclinicImp,
                chkClinicImp, setchkClinicImp,
                // rencana tindak lanjut
                rencanaTL, setrencanaTL,
                chkRencanaTL, setchkRencanaTL,
                // kuisioner
                chkKuisioner, setchkKuisioner,
                // notemed
                chkNoteMed, setchkNoteMed,
                // form detail ihs pasien
                ihstgllahir, setihstgllahir,
                ihsalamat, setihsalamat,
                ihsjenkel, setihsjenkel,
                ihsihsnumber, setihsihsnumber,
                ihsnik, setihsnik,
                ihslastupdate, setihslastupdate,
                ihsnama, setihsnama,
                // func
                getListRuang,
                getListPasien,
                getDetailKirim,
                rstDetailKirim,
                SatuSehatGetToken,
                getIhsPasien,
                insertIhsPasien,
                kirimBundle,
                kirimTele,
                // notifikasi
                tempNotif, settempNotif,
                // md
                mdIhsPasien, setmdIhsPasien,
                mdDetailKirim, setmdDetailKirim,
                // sp
                spTbPasien, setspTbPasien,
                spDetailKirim, setspDetailKirim,
            }}>
            {props.children}
        </SatuSehatEncounterContext.Provider>
    )
}

export default SatuSehatEncounterContextProvider