import { Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useState } from "react";
const { Option } = Select;

const FormTrauma = () => {
  const [pilih, setpilih] = useState("");
  return (
    <div>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          Tanggal Pengkajian
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          {" "}
          <DatePicker
            //   value={tanggalKontrol
            format="DD-MM-YYYY"
            onChange={(date) => {}}
            style={{ width: "80%" }}
            placeholder="..."
          />
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          1. Keadaan perasaan sedih (sedih , putus asa, tdk berdaya, tdk
          berguna)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Perasaan ini ada hanya bila ditanya</Option>
            <Option value={2}>
              Perasaan dinyatakan spontan secara verbal dan non verbal
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          2. Perasaan Bersalah
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>
              Menyalahkan diri sendiri, merasa telah mengecewakan orang lain
            </Option>
            <Option value={2}>
              Ide-ide bersalah atau renungan tentang perbuatan salah atau
              berdosa di masa lalu
            </Option>
            <Option value={3}>Sakit merupakan hukuman, waham bersalah</Option>
            <Option value={4}>
              Mendengar suara-suara kutukan atau mengalami halusinasi yang
              mengancam
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          3. Bunuh Diri
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Merasa hidup tidak berharga</Option>
            <Option value={2}>
              Mengharapkan kematian atau segala hal tentang kemungkinan tersebut
            </Option>
            <Option value={3}>
              Ide-ide atau gerakan-gerakan tentang bunuh diri
            </Option>
            <Option value={4}>Percobaan bunuh diri</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          4. Insomnia (early)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada kesulitan jatuh tidur</Option>
            <Option value={1}>
              Kadang-kadang mengeluh sulit tidur &gt; 15 menit
            </Option>
            <Option value={2}>Mengeluh sulit tidur setiap malam</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          4. Insomnia (middle)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada kesulitan mempertahankan tidur</Option>
            <Option value={1}>Mengeluh gelisah dan terganggu tiap malam</Option>
            <Option value={2}>Terjaga sepanjang malam</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          5. Insomnia (late)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada kesulitan</Option>
            <Option value={1}>Bangun terlalu pagi tetapi tidur kembali</Option>
            <Option value={2}>
              Bila telah bangun/bangkit tidak dapat tidur kembali
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          7. Kerja dan Kegiatan
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada kesulitan</Option>
            <Option value={1}>
              Pikiran dan perasaan tentang ketidakmampuan, keletihan, atau
              kelemahan sehubungan dengan kegiatan atau kerja
            </Option>
            <Option value={2}>
              Hilangnya minat dalam melakukan kegiatan dilaporkan langsung atau
              tidak langsung melalui kelesuan, keraguan, dan bimbang
            </Option>
            <Option value={3}>
              Berkurangnya waktu aktual yang dihabiskan dalam melakukan kegiatan
              dan menurunnya produktivitas. Di RS diberikan nilai 3 bila tidak
              menghabiskan waktu 3 jam sehari dalam melakukan kegiatan
            </Option>
            <Option value={4}>
              Berhenti bekerja karena sakitnya sekarang, di RS diberi nilai 4
              jika karena sakitnya tidak melakukan kegiatan apapun
            </Option>
          </Select>
        </Col>
      </Row>

      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          8. Retardasi (Lambat dalam berpikir dan berbicara, kemampuan
          konsentrasi, menurunkan katifitas motorik)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Normal</Option>
            <Option value={1}>Sedikit lamban dalam wawancara</Option>
            <Option value={2}>Jelas lamban dalam wawancara</Option>
            <Option value={3}>Sulit diwawancara</Option>
            <Option value={4}>Stupor lengkap</Option>
          </Select>
        </Col>
      </Row>

      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          9. Agitasi
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Memainkan tangan, rambut, dan lain-lain</Option>
            <Option value={2}>
              Meremas tangan, menggigit kuku, menggigit bibir
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          10. Anxietas Psikis
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada kesulitan</Option>
            <Option value={1}>
              Ketegangan dan mudah tersinggung, bersifat obyektif
            </Option>
            <Option value={2}>Menguatkan hal-hal kecil</Option>
            <Option value={3}>
              Sikap khawatir yang tercermin di wajah atau berbicara
            </Option>
            <Option value={4}>Ketakutan di ekspresi tanpa ditanya</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          11. Anxietas somatic
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Ringan</Option>
            <Option value={2}>Sedang</Option>
            <Option value={3}>Berat</Option>
            <Option value={4}>
              Inkapasitas, keadaan fisiologis yang mengiringi anxietas seperti
              gastrointestinal, kardiovaskuler, pernafasan, sering BAK,
              berkeringat
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          12. Gejala somatic (Gastroinntestinal)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>
              Tidak ada nafsu makan tanpa dorongan orang lain
            </Option>
            <Option value={2}>
              Sulit makan tanpa dorongan orang lain, meminta atau membutuhkan
              pencahar atau obat-obatan untuk buang air besar atau obat simptom
              gastrointestinal
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          13. Gejala somatik (umum)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>
              Anggota gerak punggung dan kepala berat, nyeri punggung, nyeri
              kepala, nyeri otot, hilang tenaga, dan kelelahan
            </Option>
            <Option value={2}>Segala gejala di atas diberi nilai</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          14. Gejala Genital (misalnya hilang libido, gangguan menstruasi)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Ringan</Option>
            <Option value={2}>Berat</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          15. Hipokondriasis
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Dihayati sendiri</Option>
            <Option value={2}>Preokupasi tentang kesehatan diri</Option>
            <Option value={3}>Sering mengeluh, meminta pertolongan, dll</Option>
            <Option value={4}>Waham hipokondriasis</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          16. Kehilangan berat badan (pilih antara A atau B)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            value={pilih}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              setpilih(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value="A">A. Bila di nilai berdasarkan riwayat</Option>
            <Option value="B">
              B. Bila di ukur perubahan berat badan aktual, dinilai setiap
              minggu oleh psikiater
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        ></Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          {pilih === "A" ? (
            <>
              {" "}
              <Select
                // value={disfagia6}
                // dataSource={listSpesialisDBRS}
                onChange={(e) => {
                  //   setdisfagia6(e);
                }}
                showSearch
                style={{ width: "80%" }}
                placeholder="..."
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={0}>Tidak ada kehilangan berat badan</Option>
                <Option value={1}>
                  Kemungkinan berat badan berkurang sehubungan sakit sekarang
                </Option>
                <Option value={2}>Berat badan jelas berkurang</Option>
              </Select>
            </>
          ) : pilih === "B" ? (
            <>
              {" "}
              <Select
                // value={disfagia6}
                // dataSource={listSpesialisDBRS}
                onChange={(e) => {
                  //   setdisfagia6(e);
                }}
                showSearch
                style={{ width: "80%" }}
                placeholder="..."
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={0}>Kehilangan BB &lt; 0.5 KG / Minggu</Option>
                <Option value={1}>Kehilangan BB &gt; 0.5 KG / Minggu</Option>
                <Option value={2}>Kehilangan BB &gt; 1 KG seminggu</Option>
              </Select>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>

      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          17. Tilikan
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Mengetahui dirinya depresi dan sakit</Option>
            <Option value={1}>
              Mengetahui dirinya sakit tetapi disebabkan oleh makanan buruk,
              iklim, kerja berlebihan, virus, perlu istirahat, dll
            </Option>
            <Option value={2}>Menyangkal sepenuhnya bahwa dirinya sakit</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          18. Variasi Diurnal
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Ringan</Option>
            <Option value={2}>Berat</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          19. Depersonalisasi dan derealisasi (misalnya merasa tidak nyata, ide
          mhilistic)
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Ringan</Option>
            <Option value={2}>Sedang</Option>
            <Option value={3}>Berat</Option>
            <Option value={4}>Inkapasitas</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          20. Gejala Paranoid
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Kecurigaan ringan</Option>
            <Option value={2}>Ide Referensi</Option>
            <Option value={3}>Waham</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          21. Gejala Obsesif dan Kompulsif
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>Ringan</Option>
            <Option value={2}>Berat</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          22. Ketidakberdayaan
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>
              Perasan subyektif yang diperoleh hanya tanya
            </Option>
            <Option value={2}>
              Perasaan tidak berdaya dinyatakan langsung oleh pasien
            </Option>
            <Option value={3}>
              Memerlukan dorongan, bimbingan, dan penentraman hati untuk
              menyelesaikan tugas bangsal dan hygiene diri
            </Option>
            <Option value={4}>
              Memerlukan bantuan fisik untuk berpakaian, makan, bedside task,
              atau hygiene diri
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          23. Keputusasaan
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>
              Sering merasa ragu bahwa keadaan akan membaik tetapi masih dapat
              di tentramkan
            </Option>
            <Option value={2}>
              Merasa putus asa secara konsisten tetapi masih menerima
              penentraman
            </Option>
            <Option value={3}>
              Mengekspresikan perasaan putus asa, hilang harapan, pesimis
              tentang masa depan, yang tidak dapat dihilangkan
            </Option>
            <Option value={4}>
              Keteguhan spontan dan tidak sesuai bahwa saya tidak akan pernah
              sembuh atau padanannya
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " left", paddingRight: 5, paddingBottom: 2 }}
        >
          24. Perasaan tidak berharga (terentang dari hilangnya harga diri,
          perasaan rendah diri, mencela diri yang ringan sampai waham tentang
          ketidakberhargaan
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Select
            // value={disfagia6}
            // dataSource={listSpesialisDBRS}
            onChange={(e) => {
              //   setdisfagia6(e);
            }}
            showSearch
            style={{ width: "80%" }}
            placeholder="..."
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            <Option value={0}>Tidak ada</Option>
            <Option value={1}>
              Menunjukkan perasaan tidak berharga (kehilangan harga diri) hanya
              bila ditanya
            </Option>
            <Option value={2}>
              Menunjukkan perasaan tidak berharga (kehilangan harga diri) secara
              spontan
            </Option>
            <Option value={3}>
              Berbeda dengan nilai 2 di atas, berdasarkan derajat pasien secara
              sukarela menyatakan bahwa dia tidak baik rendah
            </Option>
            <Option value={4}>
              Waham tentang ketidakberhargaan, misalnya saya ada tumpukan sampah
              atau padannanya
            </Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col
          span={8}
          style={{ textAlign: " right", paddingRight: 5, paddingBottom: 2 }}
        >
          <p style={{ backgroundColor: "beige" }}>Keterangan</p>
        </Col>
        <Col span={16} style={{ paddingBottom: 2 }}>
          <Input.Group compact>
            <Input
              type="text"
              placeholder="..."
              readOnly
              // value={}
              style={{ width: "20%" }}
            />
            <Input
              type="text"
              placeholder="..."
              disabled
              // value={ketLatchScore}
              // style={stylekuLatchScore}
              readOnly
              style={{ width: "60%" }}
            />
          </Input.Group>
        </Col>
      </Row>
    </div>
  );
};

export default FormTrauma;
