import { Button, Input, Modal } from "antd";
import React, { useContext, useState } from "react";
import { VClaimContext } from "../context/VClaimContext";
import { ReminderContext } from "../context/ReminderContext";

const ButtonCekSuratKontrol = () => {
  const { norujukan } = useContext(ReminderContext);
  const {
    loadingBPJS,
    getSuratKontrolBPJSbyNoSurat,
    tanggalkontrolBPJStampil,
    datakontrolBPJS,
  } = useContext(VClaimContext);
  const [open, setOpen] = useState(false);

  return (
    <div>
      <Input.Group compact>
        <Input
          style={{
            width: "calc(100% - 30%)",
          }}
          value={norujukan}
        />
        <Button
          loading={loadingBPJS}
          type="primary"
          style={{
            width: "calc(100% - 70%)",
          }}
          onClick={() => {
            getSuratKontrolBPJSbyNoSurat(norujukan);
            setOpen(true);
          }}
        >
          {tanggalkontrolBPJStampil}
        </Button>
      </Input.Group>
      <Modal
        open={open}
        title="Data Surat Rencana Kontrol"
        footer={[
          <Button key="back" onClick={() => setOpen(false)}>
            Kembali
          </Button>,
        ]}
      >
        <p>
          Nama : {datakontrolBPJS.sep.peserta.nama}
          <br />
          No Surat : {datakontrolBPJS.noSuratKontrol}
          <br />
          Tgl. Kontrol : {datakontrolBPJS.tglRencanaKontrol}
          <br />
          Tgl. Dibuat : {datakontrolBPJS.tglTerbit}
          <br />
          Poli Tujuan : {datakontrolBPJS.namaPoliTujuan}
          <br />
          Nama Dokter : {datakontrolBPJS.namaDokter}
          <br />
          Diagnosa : {datakontrolBPJS.sep.diagnosa}
        </p>
      </Modal>
    </div>
  );
};

export default ButtonCekSuratKontrol;
