import {
  Card,
  Col,
  ConfigProvider,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from "antd/es";
import TextArea from "antd/es/input/TextArea";
import React from "react";
const { Option } = Select;
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};
const formItemLayout2 = {
  labelCol: { span: 9 },
  wrapperCol: { span: 13 },
};
const DokumenPerawat = () => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Card: { fontWeightStrong: "bolder", headerBg: "beige" },
            Form: { itemMarginBottom: 0 },
          },
        }}
      >
        <Card
          title="RM 01 ( Perawat )"
          size="small"
          style={{
            borderWidth: "2px",
            borderColor: "darkgray",
            borderRadius: "4px",
          }}
        >
          <Form {...formItemLayout} labelWrap labelAlign="left">
            <Form.Item label="Pemberi Asuhan">
              <Input />
            </Form.Item>
            <Form.Item label="Tanggal Datang">
              <DatePicker />
            </Form.Item>
            <Divider
              orientation="center"
              style={{ backgroundColor: "#d9f7be", margin: "0px" }}
            >
              Identifikasi Awal Pasien
            </Divider>
            <Form.Item label="Macam Kasus">
              <Select style={{ width: "25%" }}>
                <Option key="BEDAH">BEDAH</Option>
                <Option key="NON BEDAH">NON BEDAH</Option>
                <Option key="OBSGYN">OBSGYN</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Jenis Obsgyn">
              <Select style={{ width: "25%" }}>
                <Option key="BEDAH">BEDAH</Option>
                <Option key="NON BEDAH">NON BEDAH</Option>
                <Option key="OBSGYN">OBSGYN</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Macam Trauma">
              <Select style={{ width: "25%" }}>
                <Option key="BEDAH">BEDAH</Option>
                <Option key="NON BEDAH">NON BEDAH</Option>
                <Option key="OBSGYN">OBSGYN</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Jenis Kasus">
              <Select style={{ width: "25%" }}>
                <Option key="BEDAH">BEDAH</Option>
                <Option key="NON BEDAH">NON BEDAH</Option>
                <Option key="OBSGYN">OBSGYN</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Tempat Kejadian">
              <TextArea />
            </Form.Item>
            <Form.Item label="Waktu Kejadian">
              <DatePicker />
            </Form.Item>
            <Form.Item label="Cara Datang">
              <Select style={{ width: "25%" }}>
                <Option key="BEDAH">DATANG SENDIRI</Option>
                <Option key="NON BEDAH">DIANTAR</Option>
              </Select>
              <Select style={{ width: "25%" }}>
                <Option key="BEDAH">KELUARGA</Option>
                <Option key="NON BEDAH">POLISI</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Dikirm Polisi, Permintaan visum et repertum">
              <Select style={{ width: "25%" }}>
                <Option key="YA">YA</Option>
                <Option key="TIDAK">TIDAK</Option>
              </Select>
            </Form.Item>
          </Form>
          <Row>
            <Col span={8}>
              <Form {...formItemLayout2} labelWrap labelAlign="left">
                <Form.Item label="Transportasi">
                  <Select style={{ width: "100%" }}>
                    <Option key="YA">PRIBADI</Option>
                    <Option key="TIDAK">AMBULANCE</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Rujukan Dari">
                  <Select style={{ width: "100%" }}>
                    <Option key="YA">PUSKESMAS</Option>
                    <Option key="TIDAK">NON RUJUKAN</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col span={8}>
              <Form {...formItemLayout2} labelWrap labelAlign="left">
                <Form.Item label="Hubungan Keluarga">
                  <Select style={{ width: "100%" }}>
                    <Option key="YA">BAIK</Option>
                    <Option key="TIDAK">KURANG</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Kondisi Psikologis">
                  <Select style={{ width: "100%" }}>
                    <Option key="YA">CEMAS</Option>
                    <Option key="TIDAK">TENANG</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
            <Col span={8}>
              <Form {...formItemLayout2} labelWrap labelAlign="left">
                <Form.Item label="Keyakinan Nilai Agama">
                  <Select style={{ width: "100%" }}>
                    <Option key="YA">YA</Option>
                    <Option key="TIDAK">TIDAK</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Dukunga Keluarga">
                  <Select style={{ width: "100%" }}>
                    <Option key="YA">ADA</Option>
                    <Option key="TIDAK">TIDAK</Option>
                  </Select>
                </Form.Item>
              </Form>
            </Col>
          </Row>
          <Form {...formItemLayout} labelWrap labelAlign="left">
            <Form.Item label="Informasi Dari">
              <Select style={{ width: "25%" }}>
                <Option key="AUTO">AUTO ANAMNESA</Option>
                <Option key="ALLO">ALLO ANAMNESA</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Nama">
              <Input />
            </Form.Item>
            <Form.Item label="Hubungan">
              <Input />
            </Form.Item>
          </Form>
        </Card>
      </ConfigProvider>
    </div>
  );
};

export default DokumenPerawat;
