import { Form, Input, Slider } from "antd";
import React from "react";
import oedema from "../../../assets/img/oedema.png";

const formItemLayout2 = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};

const FormFungsiOtot = () => {
  const marks1 = {
    0: "0%",
    1: "10%",
    2: "25%",
    3: "50%",
    4: "75%",
    5: "100%",
  };
  return (
    <div>
      {" "}
      <Form.Item {...formItemLayout2} label="Skala" style={{ marginBottom: 5 }}>
        {/* <img src={oedema} style={{ width: "333px" }} /> */}
        <Slider
          min={0}
          max={5}
          marks={marks1}
          //   value={skalaNyeri1}
          onChange={(e) => {
            // setskalaNyeri1(e);
            console.log(e);
          }}
          style={{
            width: "90%",
          }}
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout2}
        label="Keterangan"
        style={{ marginBottom: 5 }}
      >
        <Input
          style={{
            width: "90%",
          }}
          type="text"
          placeholder="..."
          //   value={tinggiBadan}
          readOnly
        />
      </Form.Item>
    </div>
  );
};

export default FormFungsiOtot;
