import React, { useContext } from "react";
import { Tag, Tooltip, Space, Row, Col, Button, Tabs, Input, Card } from "antd";
import { PageHeader } from "@ant-design/pro-layout";
import dayjs from "dayjs";
import { useState } from "react";
import { PasienRIContext } from "../context/PasienRIContext";
import FormRM14 from "../pencetakan/FormRM14";
import { RM14Context } from "../context/RM14Context";
const { TabPane } = Tabs;
const { TextArea, Search } = Input;

const FormKepulangan = () => {
  const {
    getobatpasien,
    infoPasien,
    setinfoPasien,
    getinfoPasienPulang,
    getKepulangan,
    infoKepulangan,
    setinfoKepulangan,
    getDiagnosaPulang,
    procedurePulang,
    setprocedurePulang,
    getProcedurePulang,
  } = useContext(RM14Context);
  const [aktifTab, setaktifTab] = useState("1");
  return (
    <div>
      <Card size="small">
        <Row>
          <Col span={12}>
            <Search
              placeholder="Ketikan No Registrasi Pasien"
              onSearch={(e) => {
                getinfoPasienPulang(e);
              }}
              // onChange={(e) => {
              //   setnoregPulang(e.target.value);
              //   console.log(e.target.value);
              // }}
              style={{ width: "100%" }}
              enterButton
            />
          </Col>
          <Col span={24}>
            <PageHeader
              // style={{
              //   // border: "1px solid rgb(235, 237, 240)",
              //   zIndex: 1,
              //   paddingTop: 6,
              //   paddingBottom: 6,
              //   paddingRight: 6,
              //   paddingLeft: 28,
              //   backgroundColor: "white",
              // }}
              title={
                <div style={{ marginLeft: 5 }}>{infoPasien.namaPasien}</div>
              }
              subTitle={
                <div>
                  <Row>
                    <Col>
                      <Tooltip title="Nomor Registrasi">
                        <Tag>{infoPasien.registrasiId}</Tag>
                      </Tooltip>
                      <Tooltip title="No. RM Pasien">
                        <Tag>{infoPasien.pasienId}</Tag>
                      </Tooltip>
                      <Tooltip title="Umur">
                        <Tag>
                          {infoPasien.umur === null ||
                          infoPasien.umur === undefined
                            ? null
                            : infoPasien.umur +
                              " Tahun " +
                              infoPasien.umurBulan +
                              " Bulan " +
                              infoPasien.umurHari +
                              " Hari"}
                        </Tag>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Tooltip title="DPJP">
                        <Tag>{infoPasien.namaDpjp}</Tag>
                      </Tooltip>
                      <Tooltip title="Pembayaran">
                        <Tag>{infoPasien.namaPembayaran}</Tag>
                      </Tooltip>
                      <Tooltip title="Tanggal Masuk">
                        <Tag>
                          {dayjs(infoPasien.tanggalMasukRi).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                        </Tag>
                      </Tooltip>
                    </Col>
                  </Row>
                </div>
              }
              bordered
            />
          </Col>
        </Row>
      </Card>

      <Tabs
        activeKey={aktifTab}
        type="card"
        size="small"
        onChange={(e) => {
          setaktifTab(e);
        }}
        style={{ marginTop: "5px" }}
      >
        <TabPane tab="RM 14" key="1">
          <FormRM14 />
        </TabPane>
        <TabPane tab="Surat Keterangan" key="2"></TabPane>
      </Tabs>
    </div>
  );
};
export default FormKepulangan;
