import { Card, ConfigProvider, Form, Select, Table } from "antd";
import { Button, Col, Flex, Input, Row } from "antd/es";
import TextArea from "antd/es/input/TextArea";
import React from "react";
const formItemLayout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const { Option } = Select;
const Triase = () => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Card: { fontWeightStrong: "bolder", headerBg: "beige" },
            Form: { itemMarginBottom: 0 },
          },
        }}
      >
        <Card
          title="Triase"
          size="small"
          style={{
            borderWidth: "2px",
            borderColor: "darkgray",
            borderRadius: "4px",
          }}
        >
          <Form {...formItemLayout}>
            <Form.Item label="Vaksinasi Covid-19" labelAlign="left">
              <Select style={{ width: "25%" }}>
                <Option key="BELUM">BELUM</Option>
                <Option key="1 KALI">VAKSINASI 1 KALI</Option>
                <Option key="2 KALI">VAKSINASI 2 KALI</Option>
                <Option key="3 KALI">VAKSINASI 3 KALI</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Keluhan" labelAlign="left">
              <Select style={{ width: "25%" }}>
                <Option key="DEMAM">DEMAM</Option>
                <Option key="DIARE">DIARE</Option>
              </Select>
              <Select style={{ width: "25%" }}>
                <Option key="<5">Kurang dari 5 hari</Option>
              </Select>
              <Button type="primary">Pilih</Button>
              <br />
              <Table size="small" />
              <TextArea />
            </Form.Item>
            <Form.Item label="Dokter" labelAlign="left">
              <Select style={{ width: "25%" }}>
                <Option key="1">Abdillah, Dr.</Option>
              </Select>
            </Form.Item>
            <Form.Item label="GCS" labelAlign="left">
              E: <Input style={{ width: "15%" }} /> V:{" "}
              <Input style={{ width: "15%" }} /> M:{" "}
              <Input style={{ width: "15%" }} /> Keterangan :{" "}
              <Input style={{ width: "15%" }} />
            </Form.Item>
            <Form.Item label="Triase (Mode ATS)" labelAlign="left">
              <Select style={{ width: "25%" }}>
                <Option key="MERAH">MERAH</Option>
                <Option key="KUNING">KUNING</Option>
                <Option key="HIJAU">HIJAU</Option>
                <Option key="HITAM">HITAM</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Pemeriksaan" labelAlign="left">
              <Select style={{ width: "25%" }}>
                <Option key="PRIORITAS 1">PRIORITAS 1</Option>
                <Option key="PRIORITAS 2">PRIORITAS 2</Option>
                <Option key="PRIORITAS 3">PRIORITAS 3</Option>
                <Option key="PRIORITAS 4">PRIORITAS 4</Option>
                <Option key="PRIORITAS 5">PRIORITAS 5</Option>
                <Option key="PRIORITAS 0">PRIORITAS 0</Option>
              </Select>
            </Form.Item>
          </Form>
          <Row>
            <Col span={24}>
              <Flex
                style={{
                  width: "100%",
                }}
                justify={"space-between"}
                align={"center"}
              >
                <Table />
                <Table />
                <Table />
                <Table />
              </Flex>
            </Col>
          </Row>
        </Card>
      </ConfigProvider>
    </div>
  );
};

export default Triase;
