import { Button, Card, Col, Modal, Row, Tabs } from 'antd'
import React, { useContext } from 'react'
import FormPermintaanDarah from '../FormPermintaanDarah';
import RiwayatTranfusi from './RiwayatTranfusi';
import HasilCrossmatch from './HasilCrossmatch';
import { BankDarahContext } from '../../context/BankDarahContext';
import DaftarOrderRuangan from './DaftarOrderRuangan';
import dayjs from 'dayjs';
import { ExclamationCircleTwoTone } from '@ant-design/icons';

const { TabPane } = Tabs;

const MenuTabBarOrderDarah = () => {
    const {
        tabOrder, settabOrder,
        ruangId,
        dtPasien,
        tglInfo,
        getKantongDarahSiap,
        getListAdvice,
        getRiwReaksi,
        getInfoRuangan,
        getListOrder,
        mdInfoUpdate, setmdInfoUpdate,
    } = useContext(BankDarahContext)

    const onChange = (key) => {
        console.log(key);
        settabOrder(key)
        if (key === '1') {
            getListOrder(dtPasien.RegistrasiId) // mengambil list order bank darah
        }
        if (key === '2') {
            getKantongDarahSiap(dtPasien.RegistrasiId, '0')
            getListAdvice(dtPasien.RegistrasiId)
        }
        else if (key === '3') {
            getKantongDarahSiap(dtPasien.RegistrasiId, '1')
            getRiwReaksi(dtPasien.PasienId)
        }
        else if (key === '4') {
            getInfoRuangan(ruangId, dayjs(tglInfo).format('YYYY-MM-DD'))
        }
    };

    return (
        <div>
            <Card bodyStyle={{ padding: '5px' }}>
                <Tabs
                    activeKey={tabOrder}
                    onTabClick={(e) => onChange(e)}
                    size='small'
                    type='card'>
                    <TabPane tab="Order Darah" disabled={Object.keys(dtPasien).length !== 0 ? false : true} key="1">
                        <FormPermintaanDarah />
                    </TabPane>
                    <TabPane tab="Hasil Crossmatch" disabled={Object.keys(dtPasien).length !== 0 ? false : true} key="2">
                        <HasilCrossmatch />
                    </TabPane>
                    <TabPane tab="Riwayat Transfusi" disabled={Object.keys(dtPasien).length !== 0 ? false : true} key="3">
                        <RiwayatTranfusi />
                    </TabPane>
                    <TabPane tab="Daftar Order Ruangan" disabled={!ruangId} key="4">
                        <DaftarOrderRuangan />
                    </TabPane>
                </Tabs>
            </Card>

            <Modal
                // title="Informasi Update"
                open={mdInfoUpdate}
                closable={false}
                footer={null}
                width={1000}
                style={{ top: 100 }}
            >
                <Card
                    title='Informasi Update - RME Unit Transfusi Darah'
                    headStyle={{ backgroundColor: '#91caff' }}>
                    <h3><ExclamationCircleTwoTone /> Update tanggal : 13-05-2024</h3>
                    <ul>
                        <li>Penambahan Keterangan <b>UserId</b> order untuk <b>Peringatan Hapus Order</b> Bank Darah</li>
                    </ul>
                    <h3 style={{ color: '#d9d9d9' }}><ExclamationCircleTwoTone /> Update tanggal : 24-04-2024</h3>
                    <ul style={{ color: '#d9d9d9' }}>
                        <li>Penambahan Fitur <b>Indikator Warna Advice</b> di Tab Menu <b>Daftar Order Ruangan - Tabel Daftar Kantong Darah Siap dan Info Advice</b>.</li>
                        <li>Ket. Warna : <b>Tanpa Warna</b> = Tidak ada Advice, <b>Kuning</b> = Advice perlu dijawab, <b>Hijau</b> = Advice sudah dijawab</li>
                        <li>Informasi Tambahan : Untuk inputan <b>Reaksi Transfusi Darah</b>, diisikan hanya <b>jika memang terjadi Reaksi</b>, jika tidak ada reaksi biarkan kosong.</li>
                    </ul>
                </Card>
                <Row style={{ marginTop: '5px' }}>
                    <Col span={24}>
                        <Button
                            onClick={() => setmdInfoUpdate(false)}
                            type='primary'
                            style={{ float: 'right', width: '100px' }}>
                            OK
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    )
}

export default MenuTabBarOrderDarah