import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  Layout,
  Select,
  Spin,
  Radio,
  Row,
  Col,
  DatePicker,
  message,
  // Form,
  Button,
  Switch,
  Popover,
  // Button,
} from "antd";
import { PasienContext } from "../rawatjalan/context/PasienContext";
import { PelayananContext } from "../rawatjalan/context/Pelayanancontext";
import dayjs from "dayjs";
import { LoginContext } from "../rawatjalan/context";
import { ResepContext } from "../rawatjalan/orderresep/ResepContext";
import TabelpasienIGD from "./TablePasienIGD";
const { Sider } = Layout;
const { Option } = Select;

const SidebarIGD = () => {
  const { setUnitOrder } = useContext(ResepContext);
  const {
    poli,
    poli1,
    poli2,
    setPoli2,
    setRuangasal,
    setCurpas,
    lebar,
    refresh,
    setPoli1,
    rs,
    setRS,
    listRuangPolibyRS,
    cariPasienHariIni,
    tanggal,
    setTanggal,
    jumlahpasien,
    layout,
    setLayout,
    collapsed,
    setCollapsed,
  } = useContext(PasienContext);

  useEffect(() => {
    cariPasienHariIni("9510", dayjs().format("YYYY-MM-DD"));
  }, []);

  return (
    <Fragment>
      <Sider
        breakpoint="lg"
        collapsible
        collapsed={collapsed}
        collapsedWidth={0}
        trigger={null}
        width={lebar}
        theme="light"
        style={{
          height: "100%",
          position: "sticky",
          top: 35,
          left: 0,
          zIndex: 2,
        }}
      >
        <span
          className={
            "ant-layout-sider-zero-width-trigger ant-layout-sider-zero-width-trigger-left"
          }
          style={{ backgroundColor: "#001529", top: "1px", zIndex: 11 }}
          onClick={() => setCollapsed(!collapsed)}
        >
          <span role="img" aria-label="bars" className="anticon anticon-bars">
            <svg
              viewBox="0 0 1024 1024"
              focusable="false"
              data-icon="bars"
              width="1em"
              height="1em"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                style={{ color: "white" }}
                d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0zm0 284a56 56 0 10112 0 56 56 0 10-112 0z"
              ></path>
            </svg>
          </span>
        </span>
        <Row>
          <Col span={12}>
            <Button block type="default">
              Jumlah Pasien : {jumlahpasien}
            </Button>
          </Col>
          <Col span={12} style={{ alignSelf: "center", textAlign: "center" }}>
            <Popover
              placement="right"
              content={
                <div>
                  Untuk mengoptimalkan / mempercepat pengambilan data pasien,
                  <br />
                  list pasien dapat dibuat menjadi metode Paging per Halaman
                  dengan
                  <br />
                  data list pasien akan dibatasi sebanyak 15 Pasien per Halaman.
                </div>
              }
              title="Jenis List Pasien"
            >
              <Switch
                checkedChildren="Paging"
                unCheckedChildren="List Full"
                onChange={(e) => setLayout(e)}
                checked={layout}
              />
            </Popover>
          </Col>
        </Row>

        <Spin spinning={refresh} tip="Sedang memuat" style={{ opacity: 1 }}>
          <TabelpasienIGD />
        </Spin>
      </Sider>
    </Fragment>
  );
};

export default SidebarIGD;
