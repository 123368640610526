import { Button, Card, DatePicker, Space, Table, message } from "antd";
import React, { useContext } from "react";
import { ReportingContext } from "../rawatjalan/context/ReportingContext";
import dayjs from "dayjs";

const RekapKunjunganRJ = () => {
  const {
    rekapkunjunganrj,
    getReportingRJ,
    tanggalawal,
    setTanggalAwal,
    tanggalakhir,
    setTanggalAKhir,
    loading,
  } = useContext(ReportingContext);
  const columnreport = [
    {
      title: "Nama Poli",
      dataIndex: "Deskripsi",
      key: "namapoli",
    },
    {
      title: "Baru",
      dataIndex: "Baru",
      width: 60,
    },
    {
      title: "Lama",
      dataIndex: "Lama",
      width: 60,
    },
    {
      title: "Konsul",
      dataIndex: "Konsul",
      width: 60,
    },
    {
      title: "Jumlah",
      width: 60,
      render(text, record) {
        return {
          props: {
            style: {
              background: "#e6fffb",
              cursor: "default",
            },
          },
          children: record.Baru + record.Lama + record.Konsul,
        };
      },
    },
  ];
  return (
    <div>
      <Card
        title="Rekap Kunjungan Rawat Jalan"
        size="small"
        style={{
          borderWidth: "2px",
          borderColor: "darkgray",
          borderRadius: "4px",
        }}
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
      >
        <Space>
          <DatePicker
            placeholder="Tanggal Awal"
            onChange={(date, dateString) => {
              setTanggalAwal(dateString);
            }}
          />
          <DatePicker
            placeholder="Tanggal Akhir"
            onChange={(date, dateString) => {
              setTanggalAKhir(dateString);
            }}
          />
          <Button
            onClick={() =>
              tanggalawal === null || tanggalakhir === null
                ? message.warning("Harap pilih tanggal terlebih dulu.")
                : getReportingRJ(tanggalawal, tanggalakhir)
            }
            type="primary"
          >
            Cari
          </Button>
          <Button
            onClick={() =>
              getReportingRJ(
                dayjs().format("YYYY-MM-DD"),
                dayjs().format("YYYY-MM-DD")
              )
            }
            type="primary"
          >
            Hari ini
          </Button>
          {/* <DatePicker
            placeholder="Tanggal"
            onChange={(date, dateString) => {
              setTanggalAwal(dateString);
              setTanggalAKhir(dateString);
            }}
          /> */}
        </Space>
        <Table
          loading={loading}
          style={{ width: "50%" }}
          bordered
          dataSource={rekapkunjunganrj}
          columns={columnreport}
          size="small"
          pagination={false}
          scroll={{ y: "50vh" }}
          summary={(pageData) => {
            let totalBaru = 0;
            let totalLama = 0;
            let totalKonsul = 0;
            let totalJumlah = 0;
            pageData.forEach(({ Baru, Lama, Konsul }) => {
              totalBaru += Baru;
              totalLama += Lama;
              totalKonsul += Konsul;
              totalJumlah += Baru + Lama + Konsul;
            });
            return (
              <>
                <tr>
                  <th>Total</th>
                  <td>{totalBaru}</td>
                  <td>{totalLama}</td>
                  <td>{totalKonsul}</td>
                  <td>{totalJumlah}</td>
                </tr>
              </>
            );
          }}
        />
      </Card>
    </div>
  );
};

export default RekapKunjunganRJ;
