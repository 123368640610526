import { Button, Card, message, Table } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { CheckCircleTwoTone, CloseCircleOutlined } from "@ant-design/icons";

const SyncPembayaran = () => {
  const [pembayaran, setPembayaran] = useState([]);
  const apiku = sessionStorage.getItem("api");
  const tok = sessionStorage.getItem("userData");
  const options = {
    headers: { Authorization: "Bearer " + tok },
  };
  const syncPembayaran = () => {
    axios
      .get(`${apiku}/MstPembayaran/SyncMstPembayaran`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success(res.data.message);
        } else {
          message.info(res.data.message);
          console.log(res.data);
        }
      })
      .catch((err) => {
        message.error("Gagal Sync!");
        console.log(err);
      });
  };

  const syncRuang = () => {
    axios
      .get(`${apiku}/MstRuang/SinkronRuang`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success(res.data.message);
        } else {
          message.info(res.data.message);
          console.log(res.data);
        }
      })
      .catch((err) => {
        message.error("Gagal Sync!");
        console.log(err);
      });
  };

  const ambilPembayaran = () => {
    axios
      .get(`${apiku}/MstPembayaran/Lookup/%20/1/100`, options)
      .then((res) => {
        setPembayaran(res.data.result);
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const tabList = [
    {
      key: "tab1",
      tab: "Pembayaran",
    },
    {
      key: "tab2",
      tab: "Ruang Bagian",
    },
  ];
  const contentList = {
    tab1: (
      <div>
        <Button onClick={() => ambilPembayaran()} type="default">
          Cek Master Pembayaran Aktif
        </Button>
        {"   "}
        <Button onClick={() => syncPembayaran()} type="primary">
          Sync Pembayaran
        </Button>
        <Table
          columns={[
            {
              title: "No.",
              key: "no",
              render: (text, record, index) => <span>{index + 1}</span>,
            },
            {
              title: "ID",
              dataIndex: "pembayaranId",
              key: "id",
            },
            {
              title: "Pembayaran",
              dataIndex: "deskripsi",
              key: "deskripsi",
            },
            {
              title: "Tanggal Habis",
              dataIndex: "tanggalAkhir",
              key: "tanggalAkhir",
              render: (text, record, index) => (
                <span>{text.substring(0, 10)}</span>
              ),
            },
            {
              title: "Status",
              dataIndex: "status",
              key: "status",
              render: (text, record, index) =>
                record.status ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                ) : (
                  <CloseCircleOutlined twoToneColor="#eb2f96" />
                ),
            },
          ]}
          dataSource={pembayaran}
          size="small"
          pagination={false}
        />
      </div>
    ),
    tab2: (
      <div>
        <Button onClick={() => syncRuang()} type="primary">
          Sync Ruangan
        </Button>
      </div>
    ),
  };
  const [activeTabKey1, setActiveTabKey1] = useState("tab1");
  const onTab1Change = (key) => {
    setActiveTabKey1(key);
  };
  return (
    <div>
      <Card
        size="small"
        title="Sync DBRS to Smart"
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
        style={{
          borderWidth: "2px",
          borderColor: "darkgray",
          borderRadius: "4px",
        }}
        extra={<a href="#">Home</a>}
        tabList={tabList}
        activeTabKey={activeTabKey1}
        onTabChange={onTab1Change}
      >
        {contentList[activeTabKey1]}
      </Card>
    </div>
  );
};

export default SyncPembayaran;
