import { Button, Card, Input, Radio, Table, Tooltip, Typography } from "antd";
import React, { useContext, useState } from "react";
import { LoginContext } from "../rawatjalan/context";
import { PasienContext } from "../rawatjalan/context/PasienContext";
const { Column } = Table;
const { Text } = Typography;
const PasienAllHariIni = () => {
  const { getPasienByUser, pasien, setPasien, rs, setRS, setLoading, loading } =
    useContext(PasienContext);
  const { namauser } = useContext(LoginContext);
  const [searchkey, setSearchKey] = useState(" ");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20); // Default jumlah baris per halaman

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
    setPageSize(pagination.pageSize);
  };
  return (
    <div>
      <Card
        size="small"
        title="Pasien Hari Ini"
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
        style={{
          borderWidth: "2px",
          borderColor: "darkgray",
          borderRadius: "4px",
        }}
      >
        <Radio.Group
          onChange={(e) => setRS(e.target.value)}
          value={rs}
          buttonStyle="solid"
          defaultValue="%20"
        >
          <Radio.Button value="RSMS">RSMS</Radio.Button>
          <Radio.Button value="ABIYASA">Abiyasa</Radio.Button>
          <Radio.Button value="%20">Semua</Radio.Button>
        </Radio.Group>
        {"  "}
        <Button
          type="primary"
          onClick={() => {
            setPasien([]);
          }}
        >
          Clear
        </Button>
        <Button
          block
          type="primary"
          onClick={() => {
            getPasienByUser(searchkey === "" ? " " : searchkey, namauser, rs);
            setLoading(true);
          }}
        >
          Pasien RJ Hari Ini
        </Button>
        <Input addonBefore="Jumlah Pasien" value={pasien.length} />

        <Input
          onChange={(e) => setSearchKey(e.target.value)}
          placeholder="Cari No Registrasi"
          onPressEnter={() => {
            getPasienByUser(searchkey === "" ? " " : searchkey, namauser, rs);
            setLoading(true);
          }}
        />

        <Table
          loading={loading}
          bordered
          // pagination={false}
          dataSource={pasien}
          size="small"
          rowKey="reg"
          scroll={{ x: 700, y: 700 }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            pageSizeOptions: ["20", "50", "100"],
            showSizeChanger: true,
            onChange: (page, pageSize) => {
              setCurrentPage(page);
              setPageSize(pageSize);
            },
            onShowSizeChange: (current, size) => {
              setPageSize(size);
              setCurrentPage(1); // Reset ke halaman 1 setiap kali ukuran halaman berubah
            },
          }}
          onChange={handleTableChange}
        >
          <Column
            style={{ verticalAlign: "top" }}
            title="No."
            key="reg"
            className="tabeltabel"
            width="20px"
            render={(text, record, index) => (
              <span>{(currentPage - 1) * pageSize + index + 1}</span>
            )}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Antrian"
            key="reg"
            className="tabeltabel"
            width="20px"
            render={(text, record) => {
              return {
                props: {
                  style: {
                    background: record.anamnesa
                      ? record.verified
                        ? "#7ad5ff"
                        : "rgb(255, 241, 184)"
                      : "",
                    cursor: "default",
                  },
                },
                children: record.anamnesa ? (
                  record.verified ? (
                    <Tooltip
                      title="Sudah ditandatangan Dokter"
                      placement="leftTop"
                    >
                      <Text strong>{record.noAntrianKlinik}</Text>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Sudah dianamnesa" placement="leftTop">
                      <Text strong>{record.noAntrianKlinik}</Text>
                    </Tooltip>
                  )
                ) : (
                  <Text strong>{record.noAntrianKlinik}</Text>
                ),
              };
            }}
          />
          <Column
            title="No. Registrasi"
            key="reg"
            className="tabeltabel2"
            width={50}
            sorter={(a, b) => a.registrasiId - b.registrasiId}
            render={(text, record) => {
              return {
                props: {
                  style: {
                    background:
                      record.flag !== null
                        ? record.flag === "A"
                          ? "gold"
                          : record.flag === "KJ"
                          ? "yellow"
                          : record.flag === "K"
                          ? "lime"
                          : ""
                        : "",
                    cursor: "default",
                  },
                },
                children: record.registrasiId,
              };
            }}
          />
          <Column
            title="Nama Pasien"
            width={100}
            key="reg"
            className="tabeltabel"
            sorter={(a, b) => a.namaPasien.localeCompare(b.namaPasien)}
            render={(pasien) => <span>{pasien.namaPasien}</span>}
          />
          <Column
            title="Pembayaran"
            width={100}
            key="reg"
            className="tabeltabel"
            sorter={(a, b) => a.namaPembayaran.localeCompare(b.namaPembayaran)}
            render={(pasien) => <span>{pasien.namaPembayaran}</span>}
          />
          <Column
            title="RuangId"
            width={50}
            key="reg"
            className="tabeltabel"
            sorter={(a, b) => a.ruangId.localeCompare(b.ruangId)}
            render={(pasien) => <span>{pasien.ruangId}</span>}
          />
          <Column
            title="Ruang"
            width={125}
            key="reg"
            className="tabeltabel"
            sorter={(a, b) => a.ruangDeskripsi.localeCompare(b.ruangDeskripsi)}
            render={(pasien) => <span>{pasien.ruangDeskripsi}</span>}
          />
          <Column
            title="DPJP"
            width={100}
            key="reg"
            className="tabeltabel"
            sorter={(a, b) => a.namaDPJP.localeCompare(b.namaDPJP)}
            render={(pasien) => <span>{pasien.namaDPJP}</span>}
          />
        </Table>
      </Card>
    </div>
  );
};

export default PasienAllHariIni;
