/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, DatePicker, Modal, Row, Select, Space, Switch, Table } from 'antd'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { SatuSehatEncounterContext } from '../context/SatuSehatEncounterContext';
import { CheckCircleOutlined, ReloadOutlined, SendOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import SSDetailKirim from './SSDetailKirim';
import '../style/style.css';

const { Option } = Select;

const SSListEncounter = () => {
    const {
        sstoken,
        listRuang,
        gedung, setgedung,
        ruangId, setruangId,
        tglPelayanan, settglPelayanan,
        listPasien,
        setpasienId,
        // notifikasi
        tempNotif, settempNotif,
        // formEdit
        mdDetailKirim, setmdDetailKirim,
        // func
        getListRuang,
        getListPasien,
        getDetailKirim,
        rstDetailKirim,
        SatuSehatGetToken,
        kirimTele,
        // sp
        spTbPasien,
    } = useContext(SatuSehatEncounterContext)

    const buttonRef = useRef(null);

    const [encounterPilih, setencounterPilih] = useState()
    const [paramAutoKlik, setparamAutoKlik] = useState(false)

    const columns = [
        {
            title: 'RegistrasiId',
            dataIndex: 'RegistrasiId',
            key: 'RegistrasiId',
            ellipsis: true,
            width: 75,
        },
        {
            title: 'PasienId',
            dataIndex: 'PasienId',
            key: 'PasienId',
            width: 75,
        },
        {
            title: 'Nama',
            dataIndex: 'Nama',
            key: 'Nama',
        },
        {
            title: 'NamaDokter',
            dataIndex: 'NamaDokter',
            key: 'NamaDokter',
        },
        {
            title: 'Kirim',
            dataIndex: 'StsKirim',
            key: 'StsKirim',
            align: 'center',
            width: 50,
            render: (value) => {
                if (value === 'true') {
                    return <CheckCircleOutlined style={{ color: 'green' }} />;
                } else {
                    return null;
                }
            }
        },
        {
            title: 'Aksi',
            key: 'operation',
            fixed: 'right',
            align: 'center',
            width: 150,
            render: (text, record, index) =>
                <div>
                    <Button
                        type='primary'
                        onClick={() => klikKirim(record.RegistrasiId, record.PasienId, index)}
                        disabled={!sstoken || record.StsKirim === 'true'}
                        icon={<SendOutlined />}
                        size='small'
                        style={{ backgroundColor: '#73d13d', borderColor: '#73d13d' }}
                    >
                        Kirim ke SatuSehat
                    </Button>
                </div>
        },
    ];

    const tempdata = [];

    for (let i = 1; i <= 100; i++) {
        const dokter = {
            RegistrasiId: '2306130000',
            PasienId: '00984299',
            Nama: 'JAUHARI DESLO ANGKASA WIJAYA, DR, TN',
            NamaDokter: 'ARIADNE TIARA H,dr,MSi,MED,SPA',
            Kirim: i % 2 === 0 ? true : false,
        };
        tempdata.push(dokter);
    }

    const listGedung = [
        {
            id: 'RSMS',
            desk: 'RSMS'
        },
        {
            id: 'ABIYASA',
            desk: 'ABIYASA',
        }
    ]

    useEffect(() => {
        const intervalId = setInterval(() => {
            // Menggunakan 'click()' untuk mensimulasikan klik pada button
            if (paramAutoKlik) {
                buttonRef.current.click();
            }
        }, 5000);

        // Membersihkan interval saat komponen unmount
        return () => clearInterval(intervalId);
    }, [paramAutoKlik]); // useEffect hanya dijalankan setelah render pertama

    const onChangeGedung = (data) => {
        setgedung(data)
        getListRuang(data)
    }

    const onChangeRuang = (data) => {
        setruangId(data)
        getListPasien(data, dayjs(tglPelayanan).format('YYYY-MM-DD'))
    }

    const onChangeTgl = (data) => {
        settglPelayanan(dayjs(data))
        getListPasien(ruangId, dayjs(data).format('YYYY-MM-DD'))
    }

    const klikRefresh = () => {
        getListPasien(ruangId, dayjs(tglPelayanan).format('YYYY-MM-DD'))
        console.log('klik 5 detik jalan, ', dayjs().format('DD-MM-YYYY HH:mm:ss'), tempNotif);
        let temp = tempNotif + 1;
        console.log('temp : ', temp);
        settempNotif(temp)

        if (temp % 6 === 0) {
            klikNotif();
            kirimTele();
        }
    }

    const klikKirim = (regId, pasienId, index) => {
        rstDetailKirim()
        setpasienId(pasienId)
        getDetailKirim(regId, ruangId)
        setencounterPilih(index)
    }

    const onCheck = (checked) => {
        setparamAutoKlik(checked)
        console.log(`switch to ${checked}`);
    };

    const klikNotif = () => {
        // Periksa apakah Notifikasi didukung oleh browser
        if ('Notification' in window) {
            // Minta izin untuk menampilkan notifikasi
            Notification.requestPermission()
                .then((permission) => {
                    if (permission === 'granted') {
                        // Buat objek notifikasi
                        const notification = new Notification('Peringatan!', {
                            body: `Terdeteksi UWER-UWER gaes.. ${tempNotif}`,
                            // icon: 'icon-url' // URL ikon notifikasi (opsional)
                        });

                        // Tindakan saat notifikasi diklik
                        notification.onclick = () => {
                            console.log('Notifikasi diklik');
                        };
                    } else {
                        console.log('Izin notifikasi ditolak');
                    }
                });
        }
    }

    return (
        <div>
            <Row style={{ marginBottom: '2px' }}>
                <Col span={2}>
                    Gedung :
                </Col>
                <Col span={4}>
                    <Select
                        value={gedung}
                        style={{ width: "100%" }}
                        size="small"
                        placeholder="..."
                        onChange={(e) => onChangeGedung(e)}
                    >
                        {listGedung.map((item, index) => (
                            <Option key={index} value={item.id}>{item.desk}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={2} style={{ marginLeft: '50px' }}>
                    Unit :
                </Col>
                <Col span={6}>
                    <Select
                        value={ruangId}
                        style={{ width: "100%" }}
                        size="small"
                        placeholder="..."
                        onChange={(e) => onChangeRuang(e)}
                    >
                        {listRuang.map((item, index) => (
                            <Option key={index} value={item.ruangId}>{item.ruangId} - {item.deskripsi}</Option>
                        ))}
                    </Select>
                </Col>
                <Col span={2} style={{ marginLeft: '50px' }}>
                    Tanggal :
                </Col>
                <Col span={4}>
                    <DatePicker
                        value={tglPelayanan}
                        onChange={(e) => onChangeTgl(e)}
                        // disabledDate={(current) => {
                        //     let customDate = dayjs().format("YYYY-MM-DD");
                        //     return current && current < dayjs(customDate, "YYYY-MM-DD");
                        // }}
                        size="small"
                        format="DD-MM-YYYY"
                        allowClear={false}
                        inputReadOnly={true}
                        style={{ width: '100%' }}
                    />
                </Col>
                <Col span={2}>
                    <Button
                        type='primary'
                        size='small'
                        onClick={() => SatuSehatGetToken()}
                        disabled
                        style={{ marginLeft: '5px', width: '95%' }}
                    >
                        Get Token
                    </Button>
                </Col>
            </Row>

            <Row
                style={{ marginBottom: '5px' }}>
                <Col span={12}>
                    <Space>
                        <Button
                            type='primary'
                            size='small'
                            icon={<ReloadOutlined />}
                            disabled={!ruangId}
                            ref={buttonRef}
                            onClick={() => klikRefresh()}
                            style={{ width: '150px' }}>
                            Refresh Pasien
                        </Button>
                        {/* <Button
                            type='primary'
                            size='small'
                            onClick={klikNotif}
                            style={{ width: '150px' }}>
                            Coba Notifikasi
                        </Button> */}
                    </Space>
                </Col>
                <Col span={12}>
                    <Space style={{ float: 'right' }}>
                        <span>Tombol klik Refresh otomatis 5 detik &#129058;</span>
                        <Switch
                            checked={paramAutoKlik}
                            onChange={onCheck} />
                    </Space>
                </Col>
            </Row>

            <Table
                bordered
                rowClassName={(record, index) => (
                    record.StsKirim === 'true' && encounterPilih !== index ? "sudah_kirim" : encounterPilih === index ? "pilih_daftar" : "belum_kirim"
                )}
                loading={spTbPasien}
                columns={columns}
                dataSource={listPasien}
                size='small'
            />

            <Modal
                visible={mdDetailKirim}
                onCancel={() => setmdDetailKirim(false)}
                width={1000}
                footer={null}
                closable={false}
                style={{ top: 50 }}
            >
                <SSDetailKirim />
            </Modal>

        </div>
    )
}

export default SSListEncounter