import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, PlusOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Space, Table } from 'antd';
import React, { useContext } from 'react';
import { ProtokolKemoContext } from '../context/ProtokolKemoContext';
import FormCreateProtokol from './FormCreateProtokol';
import ReactHtmlParser from 'react-html-parser';
import '../style/style.css';

const { confirm } = Modal;

const DaftarProtokolKemo = () => {
    const {
        listMaster,
        rstMdCreate,
        tempFormat, settempFormat,
        setidPrtkl,
        setValue,
        setcodeDokterId,
        setnamaProtokol,
        setprosedur,
        ipKomp,
        hostKomp,
        getListMasterProtokol,
        deleteProtokol,
        mdCreateProtokol, setmdCreateProtokol,
        spListMaster,
    } = useContext(ProtokolKemoContext)

    const columns = [
        {
            title: 'ID',
            dataIndex: 'Id',
            key: 'Id',
            align: 'center',
            width: 50,
        },
        {
            title: <div style={{ textAlign: 'center' }}>NAMA PROTOKOL</div>,
            dataIndex: 'NamaProtokol',
            key: 'NamaProtokol',
            width: 200,
            // align: 'center',
        },
        {
            title: <div style={{ textAlign: 'center' }}>NAMA DOKTER</div>,
            dataIndex: 'NAMADOKTER',
            key: 'NAMADOKTER',
            width: 200,
            // align: 'center',
        },
        {
            title: <div style={{ textAlign: 'center' }}>OBAT</div>,
            dataIndex: 'Obat',
            key: 'Obat',
            render: (text) => <div>{ReactHtmlParser(text)}</div>,
            width: 300,
            // align: 'center',
        },
        {
            title: <div style={{ textAlign: 'center' }}>PROSEDUR PELAKSANAAN</div>,
            dataIndex: 'ProsedurPelaksanaan',
            key: 'ProsedurPelaksanaan',
            render: (text) => <div>{ReactHtmlParser(text)}</div>,
            // align: 'center',
        },
        {
            title: "AKSI",
            key: "operation",
            // fixed: "right",
            align: "center",
            width: 90,
            render: (text, record, index) => (
                <Space size="small">
                    <Button
                        onClick={() => klikEdit(record)}
                        type="primary"
                        icon={<EditOutlined />}
                        size="small"
                        style={{ width: '30px' }}
                    />
                    <Button
                        type="primary"
                        danger
                        onClick={() => klikDelete(record)}
                        icon={<DeleteOutlined />}
                        size="small"
                        style={{ width: '30px' }}
                    />
                </Space>
            ),
        },
    ]

    const dataTemp = [];
    for (let i = 0; i < 50; i++) {
        dataTemp.push({
            Id: i + 1,
            NamaProtokol: `Protokol ${i + 1}`,
            NamaDokter: `Dokter ${i + 1}`,
        });
    }

    const klikEdit = (data) => {
        setmdCreateProtokol(true);
        setidPrtkl(data.Id);
        setcodeDokterId(data.DokterId.trim());
        setnamaProtokol(data.NamaProtokol);
        setValue(data.Obat);
        setprosedur(data.ProsedurPelaksanaan);
    }

    const klikDelete = (data) => {
        confirm({
            title: 'Yakin akan Hapus Protokol Kemoterapi?',
            icon: <ExclamationCircleFilled />,
            content: `Nama Protokol : ${data.NamaProtokol}`,
            okText: 'Hapus',
            okType: 'danger',
            cancelText: 'Batal',
            onOk() {
                let kirim = {}
                kirim.id = data.Id;
                kirim.clientHost = ipKomp;
                kirim.clientIP = hostKomp;

                deleteProtokol(kirim);
            },
        });
    }

    return (
        <div>
            <Card bodyStyle={{ padding: '10px' }}>
                <Row style={{ marginBottom: '5px' }}>
                    <Col>
                        <Space>
                            <Button
                                type='primary'
                                icon={<PlusOutlined />}
                                onClick={() => {
                                    rstMdCreate();
                                    setmdCreateProtokol(true);
                                }}
                                style={{ width: '150px' }}
                            >
                                Tambah Master
                            </Button>
                            <Button
                                icon={<SyncOutlined />}
                                onClick={() => getListMasterProtokol()}
                                style={{ width: '50px' }}
                            />
                        </Space>
                    </Col>
                </Row>

                <Table
                    className="custom-table"
                    columns={columns}
                    dataSource={listMaster}
                    loading={spListMaster}
                    // size='small'
                    // scroll={{
                    //     y: 310,
                    // }}
                    bordered
                // pagination={false}
                // style={{ marginTop: '2px' }}
                />
            </Card>

            <Modal
                // title="Modal 1000px width"
                centered
                open={mdCreateProtokol}
                onOk={() => setmdCreateProtokol(false)}
                // onCancel={() => setmdCreateProtokol(false)}
                width={1000}
                closable={false}
                footer={null}
            >
                <FormCreateProtokol />
            </Modal>
        </div>
    )
}

export default DaftarProtokolKemo