import { Tabs, Typography, Form, Input, Button, Card } from "antd";
import React, { useContext, useState } from "react";
import { ChatContext } from "../chat/Chatcontext";
import { LoginContext } from "../rawatjalan/context";
import MasterGroup from "./MasterGroup";
import MasterGroupAntrian from "./MasterGroupAntrian";
import MasterModul from "./MasterModul";
import MasterUser from "./MasterUser";
import SetGroup from "./SetGroup";
import { UserContext } from "./UserContext";
const { TabPane } = Tabs;
const { Title } = Typography;
const formItemLayout = {
  labelCol: { span: 2 },
  wrapperCol: { span: 22 },
};
const UserManagement = () => {
  const { getModulList, getGrouplist, getUserList } = useContext(UserContext);
  const { passWordsaja, cekPasswordsaja, apisys, setAPIsys, namauser } =
    useContext(LoginContext);
  const { ambilUserGroupAntrian } = useContext(ChatContext);

  const [nama, setNama] = useState("");
  const [showpassword, setShowPassword] = useState(true);
  const cek = () => {
    cekPasswordsaja(nama.toUpperCase());
  };
  const onTabs = (e) => {
    if (e === "3") {
      getModulList();
    } else if (e === "5") {
      getGrouplist();
    } else if (e === "1") {
      getUserList();
    } else if (e === "2") {
      getGrouplist();
    } else if (e === "4") {
      getGrouplist();
    } else if (e === "6") {
      ambilUserGroupAntrian();
    }
  };
  return (
    <div
      className="backcontent"
      style={{ width: "100%", height: "92vh", overflowY: "scroll" }}
    >
      <Card style={{ margin: 5 }}>
        <Tabs
          defaultActiveKey="1"
          tabPosition="left"
          type="card"
          onChange={(e) => onTabs(e)}
        >
          <TabPane tab="Daftar User" key="1">
            <Title level={3}>User Info:</Title>
            <MasterUser />
          </TabPane>
          <TabPane tab="Daftar Grup" key="2">
            <Title level={3}>Daftar Grup :</Title>
            <MasterGroup />
          </TabPane>
          <TabPane tab="Daftar Modul" key="3">
            <Title level={3}>Daftar Modul:</Title>
            <MasterModul />
          </TabPane>
          <TabPane tab="Set Grup" key="4">
            <Title level={3}>Daftar Grup :</Title>
            <SetGroup />
          </TabPane>
          {namauser === "NUGRAHA" || namauser === "GALIH" ? (
            <TabPane tab="User Check" key="5">
              <Form {...formItemLayout}>
                <Form.Item
                  label="API"
                  labelAlign="left"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ width: 200 }}
                    value={apisys}
                    onChange={(e) => setAPIsys(e.target.value)}
                  />
                </Form.Item>
                <Form.Item
                  label="Cek Username"
                  labelAlign="left"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ width: 200 }}
                    onChange={(e) => setNama(e.target.value)}
                    onPressEnter={cek}
                  />
                </Form.Item>
                <Form.Item
                  label="Cek Password"
                  labelAlign="left"
                  style={{ marginBottom: 0 }}
                >
                  <Input
                    style={{ width: 200 }}
                    addonAfter={
                      <Button
                        type="text"
                        size="small"
                        onClick={() => setShowPassword(!showpassword)}
                      >
                        {showpassword ? "Show" : "Hide"}
                      </Button>
                    }
                    disabled={showpassword}
                    value={
                      showpassword
                        ? passWordsaja.replace(/\w(?=\w{0})/g, "*")
                        : passWordsaja
                    }
                  />
                </Form.Item>
              </Form>
            </TabPane>
          ) : (
            <></>
          )}
          <TabPane tab="User Grup Antrian" key="6">
            <Title level={3}>User Grup Antrian :</Title>
            <MasterGroupAntrian />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default UserManagement;
