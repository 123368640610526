import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Select,
  Input,
  Row,
  Col,
  Checkbox,
  Button,
  Table,
  Popconfirm,
  InputNumber,
  Modal,
  message,
  Drawer,
  AutoComplete,
  Alert,
  Space,
  Tag,
  Tooltip,
  Spin,
} from "antd";
import { useContext } from "react";
// import { PlusOutlined } from "@ant-design/icons";
import Draggable from "react-draggable";
import { Prompt } from "react-router-dom";
import { PelayananContext } from "../../rawatjalan/context/Pelayanancontext";
import { ResepContext } from "../../rawatjalan/orderresep/ResepContext";
import { PasienRIContext } from "../context/PasienRIContext";
import { PasienContext } from "../../rawatjalan/context/PasienContext";
import RiwayatOrder from "../../rawatjalan/orderresep/RiwayatOrder";
const { Option } = Select;
const { Column } = Table;
const formItemLayout = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};
const formItemLayout2 = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const formItemLayout3 = {
  labelCol: { span: 12 },
  wrapperCol: { span: 12 },
};
const draggleRef = React.createRef();

//Form Order Resep
const FormOrderResepRI = () => {
  const RsLokasiPenunjang = sessionStorage.getItem("RSMana");
  const { apotikrj } = useContext(PasienContext);
  const { curpasRI } = useContext(PasienRIContext);
  const { dokterall } = useContext(PelayananContext);
  const {
    getBarangRinci,
    listOrder,
    insertOrder,
    order,
    setOrder,
    getBarang,
    barang,
    getBarangDetail,
    // patenbarangdetail,
    // patennamabarang,
    // setPatenNamaBarang,
    // patenharga,
    // setPatenHarga,
    // patensatuan,
    // setPatenSatuan,
    // racikbarangdetail,
    // racikharga,
    // setRacikHarga,
    // raciknamabarang,
    // setRacikNamaBarang,
    // raciksatuan,
    // setRacikSatuan,
    kodebarang,
    setKodeBarang,
    aturanpakai,
    getAturanPakai,
    listorder,
    detailOrder,
    items,
    setItems,
    itemspaten,
    setItemsPaten,
    unitorder,
    setUnitOrder,
    masterjnsracikan,
    jumlah,
    // setQuantity,
    aturan,
    // setAturan,
    // retriksi,
    setRetriksi,
    // maxorderobat,
    // kekuatan,
    setMaxOrderObat,
    setKekuatan,
    // patenmarginprofit,
    nomorracik,
    setNomorRacik,
    getBarangNama,
    // racik,
    // setRacik,
    // itemsracik,
    // setItemsRacik,
    getBarangDetailRacik,
    resepracik,
    setResepRacik,
    noOrder,
    setNoOrder,
    itemracikan,
    setItemRacikan,
    detailracik,
    setDetailracik,
    // racikmarginprofit,
    deleteOrder,
    // retriksijadi,
    barangidracik,
    setBarangIdRacik,
    jenisracikid,
    setJenisRacikId,
    quantityracikan,
    setQuantityRacikan,
    aturanpakaiidracik,
    setAturanPakaiIdRacik,
    loadingOrder,
  } = useContext(ResepContext);

  useEffect(() => {
    getAturanPakai();
  }, []);
  const inputRef = React.useRef(null);
  const inputRefDr = React.useRef(null);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });
  const handleFocus = (e) => e.target.select();

  const [disabled, setDisabled] = useState(true);
  const [modalDr, setModalDr] = useState(false);
  const [cito, setCito] = useState(false);
  const [pelaksana, setPelaksana] = useState(curpasRI.dokterId);
  const [modalorder, setModalOrder] = useState(false);
  const namauser = sessionStorage.getItem("userId");
  const ip = sessionStorage.getItem("IP");
  const host = sessionStorage.getItem("Host");
  const [visible, setVisible] = useState(false);
  const [spanOrder, setSpanOrder] = useState(true);
  const [spanRiwayat, setSpanRiwayat] = useState(true);
  const [warnaRow, setWarnaRow] = useState("");

  //state racik
  const [tampilracikan, setTampilRacikan] = useState(false);
  const [dosisracik, setDosisRacik] = useState(null);
  const [noracikaktif, setNoracikaktif] = useState(1);
  const [indexracik, setIndexRacik] = useState(1);
  useEffect(() => {
    // Set totals on initial render
    const newData = [...itemspaten];
    for (let index = 0; index < itemspaten.length; index++) {
      onTotal(newData, index);
    }
    setItemsPaten(newData);

    const newData2 = [...detailracik];
    for (let index = 0; index < detailracik.length; index++) {
      onTotalRacik(newData2, index);
    }
    setDetailracik(newData2);

    const newData3 = [...itemracikan];
    for (let index = 0; index < itemracikan.length; index++) {
      onTotalRacik(newData3, index);
    }

    // resepracik[noracikaktif].obatracik
    setItemRacikan(newData3);
  }, []);

  const onTotal = (data, index) => {
    data[index]["hargaTotal"] = Number(
      data[index]["quantity"] * data[index]["harga"]
    );
  };

  const onTotalRacik = (data, index) => {
    data[index]["quantity"] = Math.ceil(
      (Number(data[index]["dosis"]) / Number(data[index]["kekuatan"])) *
        Number(quantityracikan)
    );
    data[index]["hargaTotal"] =
      Number(data[index]["harga"]) *
      Math.ceil(
        (Number(data[index]["dosis"]) / Number(data[index]["kekuatan"])) *
          Number(quantityracikan)
      );
  };

  const onTampilRacikan = (e, f) => {
    getBarangNama(" ", unitorder);
    console.log(resepracik[f], e);
    setTampilRacikan(true);
    setNoracikaktif(e);
    setIndexRacik(f);
    setDetailracik(resepracik[f].obatRacik);
    setQuantityRacikan(resepracik[f].quantity);
    setJenisRacikId(
      resepracik[f].jenisRacikId + "-" + resepracik[f].jenisRacikDesk
    );
    setAturanPakaiIdRacik(resepracik[f].aturanPakaiId);
    setRetriksi(null);
    setDosisRacik(null);
    setMaxOrderObat(null);
    setKekuatan(null);
    setWarnaRow(f);
    setBarangIdRacik(
      resepracik[f].obatRacik.map(
        (c) =>
          c.barangId +
          "-" +
          c.namaBarang +
          "_" +
          c.satuanId +
          "+" +
          c.hargaRwj +
          ">" +
          c.kodeSM +
          "!" +
          c.profitMargin +
          "?" +
          c.kekuatan
      )
    );
  };

  const dataorder = {
    orderId: noOrder,
    registrasiId: curpasRI.registrasiId,
    ruangOrderId: curpasRI.ruangId,
    ruangTujuanId: unitorder,
    kelasRawatId: curpasRI.kelasRawatId,
    totalBiaya:
      items
        .map((v) => v.hargaTotal)
        .reduce((sum, current) => sum + current, 0) +
      resepracik
        .map((v) =>
          v.obatRacik
            .map((p) => p.hargaTotal)
            .reduce((sum, current) => sum + current, 0)
        )
        .reduce((sum, current) => sum + current, 0),
    userId: namauser,
    dokterId:
      pelaksana === null || pelaksana === [] || pelaksana === ""
        ? curpasRI.dokterId
        : pelaksana,
    statusCito: cito ? "YA" : "TIDAK",
    clientHost: host,
    clientIP: ip,
    obatPaten: items,
    headerRacik: resepracik,
    isTele: false,
  };

  const onTambahRacik = () => {
    setNomorRacik(nomorracik + 1);
    setNoracikaktif("");
    setItemRacikan([]);
    setDetailracik([]);
    setBarangIdRacik([]);
    setResepRacik([
      ...resepracik,
      {
        noRacik: nomorracik.toString(),
        quantity: quantityracikan,
        jenisRacikId: jenisracikid.split("-").shift(),
        jenisRacikDesk: jenisracikid.split("-").pop(),
        aturanPakaiId: aturanpakaiidracik,
        obatRacik: itemracikan,
      },
    ]);
  };
  const [modaleditracik, setModalEditRacik] = useState(false);
  const [jenisracikedit, setJenisRacikEdit] = useState("");
  const [quantityracikedit, setQuantityRacikEdit] = useState("");
  const [aturanpakaiedit, setAturanPakaiEdit] = useState("");
  const [nomorracikedit, setNomorRacikEdit] = useState("");
  const onSetEditRacik = (e, d, f, g, h) => {
    console.log(e, d, f, g, h);
    setModalEditRacik(true);
    setNomorRacikEdit(f);
    setJenisRacikEdit(d);
    setQuantityRacikEdit(g);
    setAturanPakaiEdit(h);
  };
  const onEditRacik = (e) => {
    setModalEditRacik(false);
    setIsBlocking(true);
    const elementsIndex = resepracik.findIndex(
      (element) => element.noRacik === nomorracikedit
    );
    if (elementsIndex === -1) {
      console.log(elementsIndex);
      message.warning(elementsIndex);
    } else {
      let newArray = [...resepracik];

      newArray[elementsIndex] = {
        ...newArray[elementsIndex],
        quantity: quantityracikedit,
        jenisRacikId: jenisracikedit,
        aturanPakaiId: aturanpakaiedit,
      };
      setResepRacik(newArray);
      message.success("Berhasil diubah!");
    }
  };
  const onTambahItemRacikan = (e, f) => {
    console.log(dosisracik, quantityracikan);
    const detailRacikArray = [];
    // if (resepracik.some((el) => el.noRacik === e)) {
    for (var i = 0; i < barangidracik.length; i++) {
      const elementsIndex = detailracik.findIndex(
        (element) => element.barangId === barangidracik[i].split("-").shift()
      );
      if (elementsIndex === -1) {
        detailRacikArray.push(
          // ...detailracik,
          {
            noRacik: e,
            namaBarang: barangidracik[i].split("_").shift().split("-").pop(),
            barangId: barangidracik[i].split("-").shift(),
            quantity: Math.ceil(
              (dosisracik /
                barangidracik[i].split("?").pop().split("<").shift()) *
                quantityracikan
            ),
            satuanId: barangidracik[i].split("+").shift().split("_").pop(),
            harga: barangidracik[i].split(">").shift().split("+").pop(),
            hargaTotal:
              barangidracik[i].split(">").shift().split("+").pop() *
              Math.ceil(
                (dosisracik /
                  barangidracik[i].split("?").pop().split("<").shift()) *
                  quantityracikan
              ),
            dosis: dosisracik,
            kekuatan: barangidracik[i].split("?").pop().split("<").shift(),
            kodeSM: barangidracik[i].split("!").shift().split(">").pop(),
            profitMargin: barangidracik[i].split("?").shift().split("!").pop(),
            jenisRacikId: jenisracikid,
          }
        );
        resepracik[f].obatRacik.push(
          // ...itemracikan,
          {
            noRacik: noracikaktif,
            namaBarang: barangidracik[i].split("_").shift().split("-").pop(),
            barangId: barangidracik[i].split("-").shift(),
            quantity: Math.ceil(
              (dosisracik /
                barangidracik[i].split("?").pop().split("<").shift()) *
                quantityracikan
            ),
            satuanId: barangidracik[i].split("!").shift().split(">").pop(),
            harga: barangidracik[i].split(">").shift().split("+").pop(),
            hargaTotal:
              barangidracik[i].split(">").shift().split("+").pop() *
              Math.ceil(
                (dosisracik /
                  barangidracik[i].split("?").pop().split("<").shift()) *
                  quantityracikan
              ),
            dosis: dosisracik,
            kekuatan: barangidracik[i].split("?").pop().split("<").shift(),
            kodeSM: barangidracik[i].split("!").shift().split(">").pop(),
            profitMargin: barangidracik[i].split("?").shift().split("!").pop(),
            jenisRacikId: jenisracikid.split("-").shift(),
            isHantar: barangidracik[i].split("<").pop(),
          }
        );
      } else {
        // console.log(detailracik[i]);
        detailRacikArray.push(
          //   // ...itemracikan,
          {
            noRacik: detailracik[i].noRacik,
            namaBarang: detailracik[i].namaBarang,
            barangId: detailracik[i].barangId,
            quantity: detailracik[i].quantity,
            satuanId: detailracik[i].satuanId,
            harga: detailracik[i].harga,
            hargaTotal: detailracik[i].hargaTotal,
            dosis: detailracik[i].dosis,
            kekuatan: detailracik[i].kekuatan,
            kodeSM: detailracik[i].kodeSM,
            profitMargin: detailracik[i].profitMargin,
            jenisRacikId: detailracik[i].jenisRacikId,
            isHantar: detailracik[i].isHantar,
          }
        );

        resepracik[f].obatRacik[elementsIndex].quantity =
          detailracik[i].quantity;
        resepracik[f].obatRacik[elementsIndex].hargaTotal =
          detailracik[i].hargaTotal;
        resepracik[f].obatRacik[elementsIndex].dosis = detailracik[i].dosis;
      }
    }
    setDetailracik(detailRacikArray);
    // setItemRacikan(detailRacikArray);
  };
  const onHapusRacikan = (e) => {
    setResepRacik(resepracik.filter((item) => item.noRacik !== e));
    setBarangIdRacik([]);
  };
  const onHapusDetailRacik = (e, f, g) => {
    console.log(f);
    console.log(resepracik[g].obatRacik.filter((item) => item.barangId !== e));
    resepracik[g].obatRacik = resepracik[g].obatRacik.filter(
      (item) => item.barangId !== e
    );

    setDetailracik(detailracik.filter((item) => item.barangId !== e));
    setBarangIdRacik(
      barangidracik.filter((item) => item.split("-").shift() !== e)
    );
  };

  const columnResepRacik = [
    {
      dataIndex: "jenisRacikDesk",
      title: "Jenis Racikan",
      className: "bgcolortunggu",
    },
    {
      dataIndex: "noRacik",
      title: "No Racik",
    },
    {
      dataIndex: "quantity",
      title: "Qty",
    },
    {
      dataIndex: "aturanPakaiId",
      title: "Aturan Pakai",
    },
    {
      title: "Action",
      render: (text, record, index) => (
        <Space>
          <Button
            type="primary"
            size="small"
            onClick={() => onTampilRacikan(record.noRacik, index)}
            style={{ backgroundColor: "#13c2c2", borderColor: "#13c2c2" }}
          >
            Items
          </Button>
          <Popconfirm
            title="Anda Yakin Dihapus ?"
            onConfirm={() => onHapusRacikan(record.noRacik)}
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary" size="small" danger>
              Hapus
            </Button>
          </Popconfirm>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              onSetEditRacik(
                index,
                record.jenisRacikId,
                record.noRacik,
                record.quantity,
                record.aturanPakaiId
              )
            }
            style={{ backgroundColor: "#73d13d", borderColor: "#b7eb8f" }}
          >
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  const columnItemsRacik = [
    {
      dataIndex: "noRacik",
      title: "No Racik",
    },
    {
      dataIndex: "barangId",
      title: "Kode Barang",
    },
    {
      dataIndex: "namaBarang",
      title: "Nama Barang",
    },
    {
      dataIndex: "dosis",
      title: "Dosis",
      render: (text, record, index) => (
        <InputNumber
          step="0.1"
          stringMode
          value={text}
          onChange={onInputChangeRacik("dosis", index, indexracik)}
        />
      ),
    },
    {
      dataIndex: "kekuatan",
      title: "Kekuatan",
    },
    {
      dataIndex: "quantity",
      title: "Qty",
    },
    {
      title: "Action",
      render: (text, record, index) => (
        <div>
          <Popconfirm
            title="Anda Yakin Dihapus ?"
            onConfirm={() =>
              onHapusDetailRacik(record.barangId, record.noRacik, indexracik)
            }
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary" size="small" danger>
              Hapus
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const columnorder = [
    {
      dataIndex: "noOrder",
      title: "No Order",
      className: "bgcolortunggu",
    },
    {
      dataIndex: "dokterDesk",
      title: "Nama Dokter",
    },
    {
      dataIndex: "tanggalOrder",
      title: "Tanggal Order",
    },
    {
      dataIndex: "unitTujuanDesk",
      title: "Unit Tujuan",
    },
    {
      title: "Action",
      render: (listorder) => (
        <div>
          <Button
            type="primary"
            size="small"
            onClick={() =>
              onDetailOrder(listorder.noOrder, listorder.unitTujuan)
            }
          >
            Ambil
          </Button>
          <Popconfirm
            title="Anda Yakin Dihapus ?"
            onConfirm={() => deleteOrder(listorder.noOrder)}
            okText="Ya"
            cancelText="Tidak"
          >
            <Button type="primary" size="small" danger>
              Hapus
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onAmbilDetailBarang = (e) => {
    getBarangDetailRacik(e[e.length - 1].split("-").shift());
    setBarangIdRacik(e);
    console.log(e);
  };
  const showDrawer = () => {
    setSpanOrder(!spanOrder);
    setSpanRiwayat(!spanRiwayat);
  };
  const tutupSpan = () => {
    setSpanOrder(false);
    setSpanRiwayat(false);
  };
  const onDetailOrder = (e, f) => {
    getBarangNama(" ", f);
    detailOrder(e);
    setNoOrder(e);
    setUnitOrder(f);
    getBarang(f);
    setModalOrder(false);
    setTampilRacikan(false);
  };
  const onApotik = (e) => {
    setUnitOrder(e);
    // getBarang(e);
    getBarangRinci(e);
    setItemsPaten([]);
    setOrder("");
    setDetailracik([]);
    setItemRacikan([]);
    setTampilRacikan(false);
    setNoOrder(null);
    setItems([]);
    setIsBlocking(true);
  };
  const simpanOrder = () => {
    if (pelaksana === null) {
      setModalDr(true);
      message.warning("Isikan Dokter order terlebih dahulu.");
      // Modal.error({
      //   content:
      //     "Pasien merupakan pasien konsul isikan dokter pelaksana terlebih dahulu.",
      //   onOk: inputRefDr.current.focus({
      //     cursor: "all",
      //   }),
      // });
    } else {
      insertOrder(dataorder);
      setIsBlocking(false);
      setModalDr(false);
      console.log(dataorder);
      inputRef.current.focus({
        cursor: "all",
      });
    }
  };
  const onAmbilObat = (e) => {
    getBarangDetail(e[e.length - 1].split("-").shift());
    setKodeBarang(e);
  };

  const addItem = () => {
    const gjlbaruFungsiEndokrin = [];
    const gjlbaruFungsiEndokrin2 = [];
    for (var i = 0; i < kodebarang.length; i++) {
      const elementsIndex = itemspaten.findIndex(
        (element) => element.barangId === kodebarang[i].split("-").shift()
      );

      if (elementsIndex === -1) {
        console.log("ada");

        gjlbaruFungsiEndokrin2.push(
          // ...itemspaten,
          {
            isRacikan: false,
            id: itemspaten.length,
            barangId: kodebarang[i].split("-").shift(),
            namaBarang: kodebarang[i].split("_").shift().split("-").pop(),
            quantity: jumlah,
            harga: kodebarang[i].split("+").pop().split(">").shift(),
            hargaTotal:
              jumlah * kodebarang[i].split("+").pop().split(">").shift(),
            satuanId: kodebarang[i].split(">").pop().split("!").shift(),
            satuanDesk: kodebarang[i].split("_").pop().split("+").shift(),
            aturanPakaiId: aturan,
            profitMargin: kodebarang[i].split("!").pop().split("<").shift(),
            isHantar: kodebarang[i].split("<").pop(),
          }
        );
      } else {
        console.log("tidak");
        gjlbaruFungsiEndokrin2.push(
          // ...itemspaten,
          {
            isRacikan: false,
            id: itemspaten[i].id,
            barangId: itemspaten[i].barangId,
            namaBarang: itemspaten[i].namaBarang,
            quantity: itemspaten[i].quantity,
            harga: itemspaten[i].harga,
            hargaTotal: itemspaten[i].hargaTotal,
            satuanId: itemspaten[i].satuanId,
            satuanDesk: itemspaten[i].satuanDesk,
            aturanPakaiId: itemspaten[i].aturanPakaiId,
            profitMargin: itemspaten[i].profitMargin,
            isHantar: itemspaten[i].isHantar,
          }
        );
      }
    }
    setItems(gjlbaruFungsiEndokrin2);
    setItemsPaten(gjlbaruFungsiEndokrin2);
    setIsBlocking(true);
    console.log(gjlbaruFungsiEndokrin2);
  };

  const handleRemoveItem = (e) => {
    setItems(items.filter((item) => item.barangId !== e));
    setItemsPaten(itemspaten.filter((item) => item.barangId !== e));
    setKodeBarang(kodebarang.filter((item) => item.split("-").shift() !== e));
  };

  const mockData = [];
  // eslint-disable-next-line array-callback-return
  barang.map((b) => {
    mockData.push({
      key: b.KodeBarang,
      KodeBarang: b.KodeBarang,
      NamaBarang: b.NamaBarang,
      StockUnit: b.StockUnit,
      StockGudang: b.StockGudang,
    });
  });

  const simpan = () => console.log("simpan");
  const onModalOrder = () => {
    listOrder(curpasRI.registrasiId);
    setModalOrder(true);
  };

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y),
    });
  };

  //edit qty item paten
  const onInputChange = (key, index) => (e) => {
    const newData = [...itemspaten];
    newData[index][key] = e;
    onTotal(newData, index);
    setItemsPaten(newData);
  };
  //edit aturan item paten
  const onInputChange1 = (key, index) => (e) => {
    const newData = [...itemspaten];
    newData[index][key] = e;
    setItemsPaten(newData);
  };
  //edit dosis item racik
  const onInputChangeRacik = (key, index, f) => (e) => {
    const newData = [...detailracik];
    newData[index][key] = Number(e);
    console.log(newData);
    onTotalRacik(newData, index);
    setDetailracik(newData);

    //  console.log(resepracik[Number(f) - 1].obatRacik[index].dosis)

    resepracik[Number(f)].obatRacik[index].dosis = Number(e);
    resepracik[Number(f)].obatRacik[index].quantity = Math.ceil(
      (Number(e) / resepracik[Number(f)].obatRacik[index].kekuatan) *
        resepracik[Number(f)].quantity
    );
    resepracik[Number(f)].obatRacik[index].hargaTotal =
      resepracik[Number(f)].obatRacik[index].harga *
      Math.ceil(
        (Number(e) / resepracik[Number(f)].obatRacik[index].kekuatan) *
          resepracik[Number(f)].quantity
      );

    // resepracik[noracikaktif].obatRacik[index].dosis = Number(e.target.value);
    // itemracikan.push(newData);
  };
  let [isBlocking, setIsBlocking] = useState(false);
  return (
    <div>
      <Prompt
        when={isBlocking}
        message={() =>
          `Perubahan belum tersimpan, apakah Anda akan meninggalkan halaman ini?`
        }
      />
      <Row gutter={[8, 8]}>
        <Col span={spanOrder ? 14 : 24}>
          <Spin
            spinning={loadingOrder}
            tip="Mohon menunggu data sedang disimpan"
          >
            {/* Header */}
            <Form onFinish={simpanOrder}>
              <Card
                title="Order Resep"
                size="small"
                headStyle={{
                  fontWeight: "bolder",
                  backgroundColor: "aliceblue",
                }}
                style={{
                  borderWidth: "2px",
                  borderColor: "darkgray",
                  borderRadius: "4px",
                  marginBottom: 5,
                }}
                extra={
                  <Button
                    size="small"
                    type="primary"
                    style={{
                      backgroundColor: "#237804",
                      borderColor: "transparent",
                    }}
                    onClick={showDrawer}
                  >
                    Lihat Riwayat
                  </Button>
                }
              >
                <Form.Item
                  {...formItemLayout}
                  label="Apotik"
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    dataSource={
                      apotikrj
                      // RsLokasiPenunjang === "RSMS"
                      //   ? apotikrj.sort(
                      //       (a, b) =>
                      //         a.deskripsi
                      //           .split("- ")
                      //           .pop()
                      //           .localeCompare(b.deskripsi.split("- ").pop()) ||
                      //         parseFloat(a.ruangId) - parseFloat(b.ruangId)
                      //     )
                      //   : RsLokasiPenunjang === "ABIYASA"
                      //   ? apotikrj.sort(
                      //       (a, b) =>
                      //         a.deskripsi
                      //           .split("- ")
                      //           .pop()
                      //           .localeCompare(b.deskripsi.split("- ").pop()) ||
                      //         parseFloat(a.ruangId) - parseFloat(b.ruangId)
                      //     )
                      //   : apotikrj.sort(
                      //       (a, b) =>
                      //         b.deskripsi
                      //           .split("- ")
                      //           .pop()
                      //           .localeCompare(a.deskripsi.split("- ").pop()) ||
                      //         parseFloat(a.ruangId) - parseFloat(b.ruangId)
                      //     )
                    }
                    showSearch
                    value={unitorder}
                    placeholder="Pilih ruang..."
                    optionFilterProp="children"
                    onChange={onApotik}
                    filterOption={(input, option) =>
                      option.props.children
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {apotikrj.map((d) => (
                      <Option
                        key={d.ruangId}
                        className={
                          d.deskripsi.includes("ABIYASA") ? "backgroundaby" : ""
                        }
                      >
                        {d.ruangId} - {d.deskripsi}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Row>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Form.Item
                      {...formItemLayout2}
                      label="No Order"
                      style={{ marginBottom: 0 }}
                    >
                      <Input
                        value={order.orderId}
                        style={{ width: "60%" }}
                        ref={inputRef}
                      />
                      <Button
                        type="primary"
                        onClick={onModalOrder}
                        style={{ width: "40%" }}
                      >
                        Lihat Order
                      </Button>
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout2}
                      label="Tgl Order"
                      style={{ marginBottom: 0 }}
                    >
                      <Input value={curpasRI.tanggalMasuk} />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout2}
                      label="No Registrasi"
                      style={{ marginBottom: 0 }}
                    >
                      <Input value={curpasRI.registrasiId} />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout2}
                      label="No Pasien"
                      style={{ marginBottom: 0 }}
                    >
                      <Input value={curpasRI.pasienId} />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout2}
                      label="Kelas"
                      style={{ marginBottom: 0 }}
                    >
                      <Input value={curpasRI.kelasRawat} />
                    </Form.Item>
                  </Col>
                  <Col span={12} xs={24} sm={24} md={12} lg={12} xl={12}>
                    <Row>
                      <Col span={12}>
                        <Form.Item
                          {...formItemLayout3}
                          label="Cito"
                          style={{ marginBottom: 0 }}
                        >
                          <Checkbox onChange={(e) => setCito(e.target.checked)}>
                            {cito ? "Ya" : "Tidak"}
                          </Checkbox>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          {...formItemLayout3}
                          label="Status"
                          style={{ marginBottom: 0 }}
                        >
                          <Input defaultValue="Order" />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Form.Item
                      {...formItemLayout2}
                      label="Bagian"
                      style={{ marginBottom: 0 }}
                    >
                      <Input value={curpasRI.ruangDeskripsi} />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout2}
                      label="Nama Pasien"
                      style={{ marginBottom: 0 }}
                    >
                      <Input value={curpasRI.namaPasien} />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout2}
                      label="Penjamin"
                      style={{ marginBottom: 0 }}
                    >
                      <Input value={curpasRI.namaPembayaran} />
                    </Form.Item>
                    <Form.Item
                      {...formItemLayout2}
                      label="Dokter"
                      style={{ marginBottom: 0 }}
                      required
                    >
                      <Select
                        showAction="focus"
                        ref={inputRefDr}
                        dataSource={dokterall}
                        showSearch
                        value={
                          pelaksana === null ||
                          pelaksana === [] ||
                          pelaksana === ""
                            ? curpasRI.dokterId
                            : pelaksana
                        }
                        style={{ width: "100%" }}
                        placeholder="Pilih Pelaksana"
                        optionFilterProp="children"
                        onChange={(e) => setPelaksana(e)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dokterall.map((p) => (
                          <Option key={p.dokterId}>{p.namaDokter}</Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            </Form>

            {/* Obat Paten */}
            <Card
              title="Item Obat Jadi"
              size="small"
              headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
              style={{
                borderWidth: "2px",
                borderColor: "darkgray",
                borderRadius: "4px",
                marginBottom: 5,
              }}
            >
              <Form
                name="Form Obat resep"
                initialValues={{ remember: true }}
                onFinish={addItem}
              >
                <Row gutter={[8, 2]}>
                  <Col span={22} xs={24} sm={22} md={22} lg={22} xl={22}>
                    <Row>
                      <Col span={12} style={{ textAlign: "left" }}>
                        Nama Barang :
                      </Col>
                      <Col span={10} style={{ textAlign: "right" }}>
                        <Row>
                          <Col span={14}>
                            <Tooltip title="Stock Unit">
                              <Tag color="cyan">Stock Unit</Tag>
                            </Tooltip>
                          </Col>
                          <Col span={10}>
                            <Tooltip title="Stock Gudang">
                              <Tag color="volcano">Stock Gudang</Tag>
                            </Tooltip>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row gutter={[8, 8]}>
                      <Col span={22}>
                        <Form.Item style={{ marginBottom: 0 }}>
                          <Select
                            onFocus={() => {
                              getBarangNama(" ", unitorder);
                              if (unitorder === "") {
                                message.warning(
                                  "Pilih apotik terlebih dahulu!"
                                );
                              }
                            }}
                            mode="multiple"
                            showSearch
                            value={kodebarang}
                            style={{ width: "100%" }}
                            placeholder="Pilih Barang Obat"
                            filterOption={false}
                            onChange={(e) => {
                              onAmbilObat(e);
                              console.log(e);
                            }}
                            onSearch={(e) => {
                              getBarangNama(e === "" ? " " : e, unitorder);
                            }}
                          >
                            {barang.map((p) => (
                              <Option
                                value={
                                  p.KodeBarang +
                                  "-" +
                                  p.NamaBarang +
                                  "_" +
                                  p.Satuan +
                                  "+" +
                                  p.HargaRwj +
                                  ">" +
                                  p.KodeSm +
                                  "!" +
                                  p.MarginProfit +
                                  "<" +
                                  p.isHantar
                                }
                              >
                                {
                                  <div>
                                    <Row>
                                      <Col
                                        span={12}
                                        style={{ textAlign: "left" }}
                                      >
                                        {p.NamaBarang}
                                      </Col>
                                      <Col
                                        span={12}
                                        style={{ textAlign: "right" }}
                                      >
                                        <Row>
                                          <Col span={14}>
                                            <Tooltip title="Stock Unit">
                                              <Tag color="cyan">
                                                {p.StockUnit}
                                              </Tag>
                                            </Tooltip>
                                          </Col>
                                          <Col span={10}>
                                            <Tooltip title="Stock Gudang">
                                              <Tag color="volcano">
                                                {p.StockGudang}
                                              </Tag>
                                            </Tooltip>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                }
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Button
                          type="primary"
                          onClick={(e) => {
                            console.log(kodebarang);
                            addItem();
                          }}
                        >
                          Ambil
                        </Button>
                      </Col>
                    </Row>

                    {/* {retriksijadi === null || retriksijadi === "" ? null : (
                    <Alert message={retriksijadi} type="info" />
                  )} */}
                  </Col>
                </Row>
              </Form>
              <Table
                rowKey="barangId"
                bordered
                dataSource={itemspaten}
                pagination={false}
                size="small"
                scroll={{ y: 800 }}
              >
                <Column
                  title="No."
                  key="reg"
                  className="bgcolortunggu"
                  width="40px"
                  render={(text, record, index) => <span>{index + 1}</span>}
                />
                <Column
                  title="Kode Barang"
                  key="reg"
                  className="bgcolortunggu"
                  width="100px"
                  render={(items) => <span>{items.barangId}</span>}
                />
                <Column
                  title="Barang"
                  key="reg"
                  className="bgcolortunggu"
                  width="275px"
                  render={(items) => <span>{items.namaBarang}</span>}
                />
                <Column
                  dataIndex="quantity"
                  title="Quantity"
                  width="70px"
                  render={(text, record, index) => (
                    <InputNumber
                      style={{ width: "100%" }}
                      max={1000}
                      onFocus={(e) => e.target.select()}
                      value={text}
                      onChange={onInputChange("quantity", index)}
                    />
                  )}
                />
                {/* render={(items) => <span>{items.quantity}</span>}
              /> */}
                <Column
                  title="Satuan"
                  width="50px"
                  render={(items) => <span>{items.satuanDesk}</span>}
                />
                <Column
                  dataIndex="aturanPakaiId"
                  title="Aturan"
                  width="80px"
                  render={(text, record, index) => (
                    <AutoComplete
                      style={{ width: "100%" }}
                      value={text}
                      options={aturanpakai}
                      onSelect={onInputChange1("aturanPakaiId", index)}
                      // onSearch={onSearch}
                      onChange={onInputChange1("aturanPakaiId", index)}
                      placeholder="Ketik Aturan Pakai"
                      filterOption={(inputValue, option) =>
                        option.value &&
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  )}
                />
                {/* render={(items) => <span>{items.aturanPakaiId}</span>}
              /> */}
                <Column
                  title="Action"
                  width="70px"
                  render={(items) => (
                    <span>
                      <Popconfirm
                        title="Anda Yakin Dihapus ?"
                        onConfirm={() => handleRemoveItem(items.barangId)}
                        okText="Ya"
                        cancelText="Tidak"
                      >
                        <Button danger type="primary" size="small">
                          Hapus
                        </Button>
                      </Popconfirm>
                    </span>
                  )}
                />
              </Table>
            </Card>

            {/* Obat Racik */}
            <Card
              title="Item Racikan"
              size="small"
              headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
              style={{
                borderWidth: "2px",
                borderColor: "darkgray",
                borderRadius: "4px",
                marginBottom: 5,
              }}
            >
              <Form {...formItemLayout2}>
                <Row gutter={[8, 8]}>
                  <Col span={12}>
                    <Form.Item
                      label="Jenis Racikan"
                      style={{ marginBottom: 0 }}
                    >
                      <Select
                        dataSource={masterjnsracikan}
                        showSearch
                        value={jenisracikid}
                        style={{ width: "100%" }}
                        placeholder="Pilih Racikan"
                        optionFilterProp="children"
                        onChange={(e) => setJenisRacikId(e)}
                        filterOption={(input, option) =>
                          option.props.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {masterjnsracikan.map((p) => (
                          <Option value={p.JenisRacikId + "-" + p.Deskripsi}>
                            {p.Deskripsi}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item label="Quantity" style={{ marginBottom: 0 }}>
                      <InputNumber
                        step="0.1"
                        min={1}
                        placeholder="Ketik Quantity Racik"
                        value={quantityracikan}
                        style={{ width: "100%" }}
                        onChange={(e) => setQuantityRacikan(e)}
                        onFocus={handleFocus}
                      />
                    </Form.Item>
                    <Form.Item label="Aturan" style={{ marginBottom: 5 }}>
                      <AutoComplete
                        options={aturanpakai}
                        value={aturanpakaiidracik}
                        onSelect={(e) => setAturanPakaiIdRacik(e)}
                        onChange={(e) => setAturanPakaiIdRacik(e)}
                        placeholder="Ketik Aturan Pakai"
                        filterOption={(inputValue, option) =>
                          option.value &&
                          option.value
                            .toUpperCase()
                            .indexOf(inputValue.toUpperCase()) !== -1
                        }
                      />
                    </Form.Item>
                    <Row>
                      <Col style={{ textAlign: "right" }} span={24}>
                        <Space>
                          <Button
                            type="primary"
                            onClick={() =>
                              unitorder === ""
                                ? message.warning(
                                    "Pilih apotik terlebih dahulu!"
                                  )
                                : aturanpakaiidracik === null ||
                                  jenisracikid === null ||
                                  quantityracikan === null
                                ? message.warning(
                                    "Form Order Racik Belum Lengkap!"
                                  )
                                : onTambahRacik()
                            }
                          >
                            Tambah Racikan
                          </Button>
                        </Space>
                      </Col>
                    </Row>
                    <Table
                      dataSource={resepracik}
                      columns={columnResepRacik}
                      size="small"
                      pagination={false}
                      rowClassName={(record, rowIndex) =>
                        rowIndex === warnaRow ? "colorpilihobat" : null
                      }
                    />
                  </Col>
                  {tampilracikan ? (
                    <Col span={12}>
                      <Row>
                        <Col span={12} style={{ textAlign: "left" }}>
                          Nama Barang :
                        </Col>
                        <Col span={10} style={{ textAlign: "right" }}>
                          <Row>
                            <Col span={10}>
                              <Tooltip title="Stock Unit">
                                <Tag color="cyan">Stock Unit</Tag>
                              </Tooltip>
                            </Col>
                            <Col span={10}>
                              <Tooltip title="Stock Gudang">
                                <Tag color="volcano">Stock Gudang</Tag>
                              </Tooltip>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={20}>
                          {/* <Form.Item style={{ marginBottom: 0 }}> */}
                          <Select
                            onFocus={() => {
                              getBarangNama(" ", unitorder);
                              if (unitorder === "") {
                                message.warning(
                                  "Pilih apotik terlebih dahulu!"
                                );
                              }
                            }}
                            mode="multiple"
                            showSearch
                            value={barangidracik}
                            style={{ width: "100%" }}
                            placeholder="Pilih Barang Obat"
                            filterOption={false}
                            onChange={(e) => onAmbilDetailBarang(e)}
                            onSearch={(e) => {
                              getBarangNama(e === "" ? " " : e, unitorder);
                            }}
                          >
                            {barang.map((p) => (
                              <Option
                                value={
                                  p.KodeBarang +
                                  "-" +
                                  p.NamaBarang +
                                  "_" +
                                  p.Satuan +
                                  "+" +
                                  p.HargaRwj +
                                  ">" +
                                  p.KodeSm +
                                  "!" +
                                  p.MarginProfit +
                                  "?" +
                                  p.Kekuatan +
                                  "<" +
                                  p.isHantar
                                }
                              >
                                {
                                  <div>
                                    <Row>
                                      <Col
                                        span={12}
                                        style={{ textAlign: "left" }}
                                      >
                                        {p.NamaBarang}
                                      </Col>
                                      <Col
                                        span={12}
                                        style={{ textAlign: "right" }}
                                      >
                                        <Row>
                                          <Col span={14}>
                                            <Tooltip title="Stock Unit">
                                              <Tag color="cyan">
                                                {p.StockUnit}
                                              </Tag>
                                            </Tooltip>
                                          </Col>
                                          <Col span={10}>
                                            <Tooltip title="Stock Gudang">
                                              <Tag color="volcano">
                                                {p.StockGudang}
                                              </Tag>
                                            </Tooltip>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                }
                              </Option>
                            ))}
                          </Select>
                          {/* </Form.Item> */}
                        </Col>
                        <Col style={{ textAlign: "right" }} span={4}>
                          <Button
                            htmlType="submit"
                            type="primary"
                            onClick={() =>
                              onTambahItemRacikan(noracikaktif, indexracik)
                            }
                          >
                            Ambil
                          </Button>
                        </Col>
                      </Row>
                      <Table
                        rowKey="barangId"
                        dataSource={detailracik}
                        columns={columnItemsRacik}
                        size="small"
                        pagination={false}
                      />
                    </Col>
                  ) : (
                    <></>
                  )}
                </Row>
              </Form>
            </Card>

            <Row>
              <Col style={{ textAlign: "right" }} span={24}>
                <Card
                  size="small"
                  style={{
                    borderWidth: "2px",
                    borderColor: "darkgray",
                    borderRadius: "4px",
                    marginBottom: 25,
                  }}
                >
                  <Button
                    size="large"
                    htmlType="submit"
                    type="primary"
                    onClick={simpanOrder}
                  >
                    Simpan
                  </Button>
                </Card>
              </Col>
            </Row>
          </Spin>
        </Col>
        <Col span={spanRiwayat ? 10 : 0}>
          <Card
            extra={
              <Button size="small" type="primary" danger onClick={tutupSpan}>
                Tutup
              </Button>
            }
            title="Riwayat Obat"
            size="small"
            headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
            style={{
              borderWidth: "2px",
              borderColor: "darkgray",
              borderRadius: "4px",
              marginBottom: 25,
            }}
          >
            <RiwayatOrder />
          </Card>
        </Col>
      </Row>

      {/* Daftar Order */}
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            Daftar Order
          </div>
        }
        visible={modalorder}
        onOk={simpan}
        onCancel={() => setModalOrder(false)}
        width="1000px"
        footer={null}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Table
          columns={columnorder}
          dataSource={listorder}
          size="small"
          pagination={false}
        />
      </Modal>

      {/* Edit Racik Header */}
      <Modal
        title={
          <div
            style={{
              width: "100%",
              cursor: "move",
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => {}}
            onBlur={() => {}}
          >
            Edit Racik
          </div>
        }
        visible={modaleditracik}
        onOk={() => onEditRacik()}
        onCancel={() => setModalEditRacik(false)}
        width="450px"
        okText="Simpan"
        cancelText="Batal"
        // footer={null}
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        <Form {...formItemLayout2}>
          <Form.Item label="Jenis Racikan" style={{ marginBottom: 0 }}>
            <Select
              dataSource={masterjnsracikan}
              showSearch
              value={jenisracikedit}
              style={{ width: "100%" }}
              placeholder="Pilih Racikan"
              optionFilterProp="children"
              onChange={(e) => setJenisRacikEdit(e)}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              {masterjnsracikan.map((p) => (
                <Option key={p.JenisRacikId}>{p.Deskripsi}</Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Quantity" style={{ marginBottom: 0 }}>
            <InputNumber
              step="0.1"
              min={1}
              value={quantityracikedit}
              style={{ width: "100%" }}
              onChange={(e) => setQuantityRacikEdit(e)}
              onFocus={handleFocus}
            />
          </Form.Item>
          <Form.Item label="Aturan" style={{ marginBottom: 0 }}>
            <AutoComplete
              options={aturanpakai}
              value={aturanpakaiedit}
              onSelect={(e) => setAturanPakaiEdit(e)}
              onChange={(e) => setAturanPakaiEdit(e)}
              placeholder="Ketik Aturan Pakai"
              filterOption={(inputValue, option) =>
                option.value &&
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                  -1
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Warning - Input belum lengkap"
        visible={modalDr}
        onOk={() => simpanOrder()}
        // onCancel={() => setModalDr(false)}
        // cancelText={null}
        cancelButtonProps={{ disabled: true }}
        okText="Simpan"
      >
        Pasien merupakan pasien konsul,silahkan isikan dokter pelaksana/order
        terlebih dahulu agar ORDER RESEP bisa tersimpan.
        <Form.Item
          {...formItemLayout2}
          label="Dokter Order"
          style={{ marginBottom: 0 }}
          required
        >
          <Select
            showAction="focus"
            ref={inputRefDr}
            dataSource={dokterall}
            showSearch
            value={pelaksana}
            style={{ width: "100%" }}
            placeholder="Pilih Pelaksana"
            optionFilterProp="children"
            onChange={(e) => setPelaksana(e)}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {dokterall.map((p) => (
              <Option key={p.dokterId}>{p.namaDokter}</Option>
            ))}
          </Select>
        </Form.Item>
      </Modal>

      {/* Riwayat Obat */}
      <Drawer
        title="Riwayat Resep Obat"
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        width={550}
      >
        <RiwayatOrder />
      </Drawer>
    </div>
  );
};

export default FormOrderResepRI;
