import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
// import { LoginContext } from "../rawatjalan/context";

export const UserContext = createContext();

const UserContextProvider = (props) => {
  const [userlist, setUserList] = useState([]);
  const [modullist, setModulList] = useState([]);
  const [modul, setModul] = useState([]);
  const [moduldetail, setModulDetail] = useState([]);
  const [grouplist, setGroupList] = useState([]);
  const [groupakses, setGroupAkses] = useState([]);
  const [menuuser, setMenuuser] = useState([]);
  const [modulMenuId, setModulMenuId] = useState("");
  const [modulMenuDeskripsi, setModulMenuDeskripsi] = useState("");
  const [modulAksesFull, setModulAksesFull] = useState(true);
  const [modulAksesUpdate, setModulAksesUpdate] = useState(true);
  const [modulAksesInsert, setModulAksesInsert] = useState(true);
  const [modulAksesDelete, setModulAksesDelete] = useState(true);
  const [modulAksesRead, setModulAksesRead] = useState(true);
  const [menuRJ, setMenuRJ] = useState(false);
  const [menuRI, setMenuRI] = useState(false);
  const [menuPnj, setMenuPnj] = useState(false);
  const [menuRM, setMenuRM] = useState(false);
  const [menuAkses, setMenuAkses] = useState(false);
  const [menuLaporanStatistik, setMenuLaporanStatistik] = useState(false);
  const [menuHD, setMenuHD] = useState(false);
  const [menuGizi, setMenuGizi] = useState(false);
  const [menuMaster, setMenuMaster] = useState(false);
  const [menuRM02, setMenuRM02] = useState(false);
  const [menuRadio, setMenuRadio] = useState(false);
  const [menuPenunjang, setMenuPenunjang] = useState(false);
  const [menuKemoterapi, setMenuKemoterapi] = useState(false);
  const [menuRadioterapi, setMenuRadioterapi] = useState(false);
  const [menuIBS, setMenuIBS] = useState(false);
  const [menuPnjLain, setMenuPnjLain] = useState(false);
  const [menuJadwalPnj, setMenuJadwalPnj] = useState(false);
  const [menuBridging, setMenuBridging] = useState(false);
  const [menuValidDarah, setMenuValidDarah] = useState(false);
  const [ruang, setRuang] = useState([]);
  const [listIP, setListIP] = useState([]);
  const apiku = sessionStorage.getItem("api");

  const tok = sessionStorage.getItem("userData");
  const options = {
    headers: { Authorization: "Bearer " + tok },
  };

  useEffect(() => {
    const auth = sessionStorage.getItem("authId");
    // const no = sessionStorage.getItem('no');
    const tok = sessionStorage.getItem("userData");

    const options = {
      headers: { Authorization: "Bearer " + tok },
    };
    axios
      .get(`${apiku}/SisUserRule/ReadAuthId/${auth}`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setMenuuser(res.data.result.rule);
          // console.log("Menu User", res.data.result.rule);
          // console.log(res.data.result.rule.some((x) => x.MenuId === "RJ0000"));
          setMenuRJ(res.data.result.rule.some((x) => x.MenuId === "RJ0000"));
          setMenuRI(res.data.result.rule.some((x) => x.MenuId === "RI0000"));
          setMenuPnj(res.data.result.rule.some((x) => x.MenuId === "PN0000"));
          setMenuAkses(res.data.result.rule.some((x) => x.MenuId === "MN0001"));
          setMenuLaporanStatistik(
            res.data.result.rule.some((x) => x.MenuId === "LP0001")
          );
          setMenuGizi(res.data.result.rule.some((x) => x.MenuId === "GZ0001"));
          setMenuHD(res.data.result.rule.some((x) => x.MenuId === "HD0001"));
          setMenuMaster(
            res.data.result.rule.some((x) => x.MenuId === "MST001")
          );
          setMenuRM(res.data.result.rule.some((x) => x.MenuId === "RM0001"));
          setMenuRadio(res.data.result.rule.some((x) => x.MenuId === "RT0002"));
          setMenuPenunjang(
            res.data.result.rule.some((x) => x.MenuId === "PNJ001")
          );
          setMenuIBS(res.data.result.rule.some((x) => x.MenuId === "BS0001"));
          setMenuPnjLain(
            res.data.result.rule.some((x) => x.MenuId === "PNJ002")
          );
          setMenuJadwalPnj(
            res.data.result.rule.some((x) => x.MenuId === "PNJ003")
          );
          setMenuRadioterapi(
            res.data.result.rule.some((x) => x.MenuId === "RT0001")
          );
          setMenuKemoterapi(
            res.data.result.rule.some((x) => x.MenuId === "KMO001")
          );
          setMenuRM02(res.data.result.rule.some((x) => x.MenuId === "RM0002"));
          setMenuBridging(
            res.data.result.rule.some((x) => x.MenuId === "BRG000")
          );
          setMenuValidDarah(
            res.data.result.rule.some((x) => x.MenuId === "DAR001")
          );
        } else {
          setMenuuser([]);
          setMenuRJ(false);
          setMenuRI(false);
          setMenuAkses(false);
          setMenuLaporanStatistik(false);
          setMenuGizi(false);
          setMenuHD(false);
          setMenuRadio(false);
          setMenuRadioterapi(false);
          setMenuPenunjang(false);
          setMenuPnjLain(false);
          setMenuJadwalPnj(false);
          setMenuIBS(false);
          setMenuRM02(false);
          setMenuBridging(false);
          setMenuValidDarah(false);
          console.log("Menu User", res.data);
        }
      })
      .catch((err) => {
        setMenuuser([]);
        setMenuRJ(false);
        setMenuRI(false);
        setMenuAkses(false);
        setMenuLaporanStatistik(false);
        setMenuGizi(false);
        setMenuHD(false);
        setMenuRadio(false);
        setMenuRadioterapi(false);
        setMenuPenunjang(false);
        setMenuPnjLain(false);
        setMenuJadwalPnj(false);
        setMenuIBS(false);
        setMenuRM02(false);
        setMenuBridging(false);
        setMenuValidDarah(false);
        console.log("Menu User", err);
      });
  }, [apiku]);

  const getMenu = (auth) => {
    axios
      .get(`${apiku}/SisUserRule/ReadAuthId/${auth}`, options)
      .then((res) => {
        // setUser(res.data.result);
        setMenuuser(res.data.result.rule);
        console.log(res.data.result.rule);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserList = () => {
    axios
      .get(`${apiku}/SisJwt/Lookup/%20`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUserList(res.data.result);
          // console.log(res.data.result.length);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getUserListName = (user) => {
    axios
      .get(`${apiku}/SisJwt/Lookup/${user}`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setUserList(res.data.result);
          // console.log(res.data.result.length);
        } else {
          setUserList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getModulList = () => {
    axios
      .get(`${apiku}/SisUserRule/Menu/%20`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setModulList(res.data.result);
          // console.log(res.data.result.length);
        } else {
          setModulList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getModulListName = (nama) => {
    axios
      .get(`${apiku}/SisUserRule/Menu/${nama}`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setModulList(res.data.result);
          // console.log(res.data.result.length);
        } else {
          setModulList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGrouplist = () => {
    axios
      .get(`${apiku}/SisUserRule/UserGroup/%20`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGroupList(res.data.result);
          // console.log(res.data.result.length);
        } else {
          setGroupList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getGrouplistName = (nama) => {
    axios
      .get(`${apiku}/SisUserRule/UserGroup/${nama}`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGroupList(res.data.result);
          // console.log(res.data.result.length);
        } else {
          setGroupList([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readModulList = (groupId) => {
    axios
      .get(`${apiku}/SisUserRule/Rule/${groupId}`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setModul(res.data.result);
          // console.log(res.data.result.length);
        } else {
          setModul([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readModul = (groupId, menuId) => {
    axios
      .get(`${apiku}/SisUserRule/ReadRule/${groupId}/${menuId}`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setModulDetail(res.data.result);
          setModulMenuId(res.data.result.MenuId);
          setModulMenuDeskripsi(res.data.result.MenuDeksripsi);
          setModulAksesFull(res.data.result.AksesFull);
          setModulAksesUpdate(res.data.result.AksesUpdate);
          setModulAksesInsert(res.data.result.AksesInsert);
          setModulAksesDelete(res.data.result.AksesDelete);
          setModulAksesRead(res.data.result.AksesRead);
          // console.log(res.data.result.length);
        } else {
          setModulDetail([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const readGroupAkses = (groupId) => {
    axios
      .get(`${apiku}/SisUserRule/ReadGroupAkses/${groupId}`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          setGroupAkses(res.data.result);
          // console.log(res.data.result.length);
        } else {
          setGroupAkses([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const insertRuleModul = (rule) => {
    axios
      .post(`${apiku}/SisUserRule/Rule`, rule, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success("Berhasil Disimpan!");
          readModulList(rule.userGroupId);
        } else {
          console.log(res.data.result);
          message.error("Gagal Disimpan!");
        }
      })
      .catch((err) => {
        console.log(err);
        message.error("Gagal Disimpan!");
      });
  };

  const deleteRuleModul = (groupId, menuId) => {
    axios
      .delete(`${apiku}/SisUserRule/Rule/${groupId}/${menuId}`, options)
      .then((res) => {
        console.log(res.data.result);
        readModulList(groupId);
        message.success("Berhasil Dihapus!");
      })
      .catch((err) => {
        message.error("Gagal Dihapus!");
        console.log(err);
      });
  };

  const insertModul = (modul) => {
    axios
      .post(`${apiku}/SisUserRule/Menu`, modul, options)
      .then((res) => {
        const elementsIndex = modullist.findIndex(
          (element) => element.authId === res.data.result.authId
        );

        if (elementsIndex === -1) {
          setGroupAkses([...modullist, res.data.result]);
        } else {
          let newArray = [...modullist];

          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            authId: res.data.result.authId,
            username: res.data.result.username,
            namaLengkap: res.data.result.namaLengkap,
          };
          setGroupAkses(newArray);
        }

        console.log(elementsIndex);
        message.success("Berhasil Disimpan!");
      })
      .catch((err) => {
        message.error("Gagal Disimpan!");
        console.log(err);
      });
  };

  const deleteModul = (modul) => {
    axios
      .delete(`${apiku}/SisUserRule/Menu/${modul}`, options)
      .then((res) => {
        console.log(res.data.result);
        message.success("Berhasil Dihapus!");
      })
      .catch((err) => {
        message.error("Gagal Dihapus!");
        console.log(err);
      });
  };

  const insertGroupAkses = (group) => {
    axios
      .post(`${apiku}/SisUserRule/GroupAkses`, group, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          message.success("Berhasil Disimpan!");
          readGroupAkses(res.data.result.userGroupId);
        } else {
          console.log(res.data.result);
          message.warning("Gagal Disimpan!");
        }
      })
      .catch((err) => {
        message.error("Gagal Disimpan!");
        console.log(err);
      });
  };

  const deleteGroupAkses = (authId, groupId) => {
    axios
      .delete(
        `${apiku}/SisUserRule/UserGroupAkses/${authId}/${groupId}`,
        options
      )
      .then((res) => {
        console.log(res.data.result);
        readGroupAkses(groupId);
        message.success("Berhasil Dihapus!");
      })
      .catch((err) => {
        message.error("Gagal Dihapus!");
        console.log(err);
      });
  };

  const getListIP = () => {
    axios
      .get(`${apiku}/SisGetIP/GetListIP`, options)
      .then((res) => {
        console.log(res.data.result);
        setListIP(res.data.result);
      })
      .catch((err) => {
        message.error("Gagal Dihapus!");
        setListIP([]);
        console.log(err);
      });
  };

  const userBag = (user) => {
    axios
      .get(`${apiku}/SisJwt/RuangByUser/${user}/%20/2/%20`, options)
      .then((res) => {
        if (res.data.statusCode === 200) {
          console.log(res.data.result);
          setRuang(res.data.result);
        } else {
          console.log(res.data);
          setRuang([]);
        }
      })
      .catch((err) => {
        setRuang([]);
        console.log(err);
      });
  };

  return (
    <UserContext.Provider
      value={{
        getUserList,
        getModulList,
        getGrouplist,
        readGroupAkses,
        insertModul,
        insertGroupAkses,
        readModulList,
        deleteModul,
        readModul,
        insertRuleModul,
        deleteRuleModul,
        getUserListName,
        deleteGroupAkses,
        userlist,
        modullist,
        grouplist,
        groupakses,
        modul,
        moduldetail,
        modulMenuId,
        setModulMenuId,
        modulMenuDeskripsi,
        setModulMenuDeskripsi,
        modulAksesFull,
        setModulAksesFull,
        modulAksesUpdate,
        setModulAksesUpdate,
        modulAksesInsert,
        setModulAksesInsert,
        modulAksesDelete,
        setModulAksesDelete,
        modulAksesRead,
        setModulAksesRead,
        menuuser,
        getMenu,
        menuJadwalPnj,
        setMenuJadwalPnj,
        menuRJ,
        menuRI,
        menuPnj,
        menuAkses,
        menuLaporanStatistik,
        menuGizi,
        menuHD,
        menuMaster,
        menuRM,
        menuRadio,
        menuIBS,
        menuPenunjang,
        menuPnjLain,
        setMenuRM02,
        menuRM02,
        getModulListName,
        getGrouplistName,
        listIP,
        getListIP,
        menuKemoterapi,
        setMenuKemoterapi,
        menuRadioterapi,
        setMenuRadioterapi,
        userBag,
        ruang,
        setRuang,
        menuBridging,
        menuValidDarah,
        setMenuValidDarah,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};

export default UserContextProvider;
