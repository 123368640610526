import React from "react";
import PageheadIGD from "./PageheadIGD";
import MenubarIGD from "./MenubarIGD";
import SidebarIGD from "./SidebarIGD";
import { Layout } from "antd";
import MasterFormIGD from "./MasterFormIGD";
const { Content } = Layout;

const MainIGD = () => {
  return (
    <div>
      <MenubarIGD />
      <Layout>
        <SidebarIGD />
        <Content
          style={{
            padding: 7,
            height: "100%",
            flexDirection: "column",
            msFlexDirection: "column",
            display: "flex",
          }}
        >
          <PageheadIGD />
          <MasterFormIGD />
        </Content>
      </Layout>
    </div>
  );
};

export default MainIGD;
