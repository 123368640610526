import { Form, Input, Slider } from "antd";
import React from "react";
import oedema from "../../../assets/img/oedema.png";

const formItemLayout2 = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};
const FormOedema = () => {
  const marks1 = {
    1: "Tingkat 1",
    2: "Tingkat 2",
    3: "Tingkat 3",
    4: "Tingkat 4",
  };
  return (
    <div>
      <Form.Item {...formItemLayout2} label="Skala" style={{ marginBottom: 5 }}>
        {/* <img src={oedema} style={{ width: "333px" }} /> */}
        <Slider
          min={1}
          max={4}
          marks={marks1}
          //   value={skalaNyeri1}
          onChange={(e) => {
            // setskalaNyeri1(e);
            console.log(e);
          }}
          style={{
            width: "90%",
          }}
        />
      </Form.Item>
      <Form.Item
        {...formItemLayout2}
        label="Keterangan"
        style={{ marginBottom: 5 }}
      >
        <Input
          style={{
            width: "90%",
          }}
          type="text"
          placeholder="..."
          //   value={tinggiBadan}
          readOnly
        />
      </Form.Item>
    </div>
  );
};

export default FormOedema;
