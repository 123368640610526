import React, { useContext, Fragment, useState } from "react";
import {
  Table,
  Typography,
  Select,
  Button,
  Form,
  Input,
  Popconfirm,
  message,
  Row,
  Col,
  Empty,
  InputNumber,
  Card, Space, Modal, Divider, TreeSelect
} from "antd";
import { BillingContext } from "../../rawatjalan/context/BillingContext";
import { PelayananContext } from "../../rawatjalan/context/Pelayanancontext";
import { PasienContext } from "../../rawatjalan/context/PasienContext";
import { LoginContext } from "../../rawatjalan/context";
import { MasterRuangContext } from "../../master/context/masterreferensi/MasterRuangContext";
const { Text } = Typography;
const { Column } = Table;
const { Option } = Select;
const { TreeNode } = TreeSelect;
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
};
const formItemLayout1 = {
  labelCol: { span: 3 },
  wrapperCol: { span: 21 },
};

const FormBillingRI = () => {
  const { getRuang, ruangList } = useContext(MasterRuangContext);
  const [modalMutasi, setmodalMutasi] = useState(false)
  const {
    billing,
    insertBiiling,
    deleteBilling,
    pel,
    setPelayanan,
    pemeriksa,
    setPemeriksa,
    jumlah,
    setJumlah,
    syncBillKHS,
  } = useContext(BillingContext);
  const { pelayanan, pelaksana, detpel, detailPelayanan, dokterall } =
    useContext(PelayananContext);
  const { curpas } = useContext(PasienContext);
  const { namauser } = useContext(LoginContext);
  const ip = sessionStorage.getItem("IP");
  const host = sessionStorage.getItem("Host");
  const billpelayanan = {
    registrasiId: curpas.registrasiId,
    ruangId: curpas.ruangId,
    pelayananId: pel,
    pembayaranId: curpas.pembayaranId,
    // dokterPengirimId: String(authId),
    dokterPemeriksaId: pemeriksa === null ? curpas.dokterId : pemeriksa,
    kdgrptrf: curpas.kelasRawatId,
    jmlPelayanan: parseInt(jumlah),
    biayaPelayanan: parseInt(Number(detpel.total).toFixed(2)),
    userId: namauser,
    clientHost: host,
    clientIp: ip,
  };

  const simpanBill = () => {
    // e.preventDefault();
    insertBiiling(billpelayanan);
    console.log(billpelayanan);
  };
  const onPelayanan = (e) => {
    setPelayanan(e);
    detailPelayanan(e);
  };
  const onDelete = (noreg, pelayanan) => {
    deleteBilling(noreg, pelayanan);
    console.log(noreg, pelayanan);
  };
  const cancel = () => {
    message.warning("Batal Dihapus");
  };
  const onChange = (e) => {
    console.log(e);
  };
  const [form] = Form.useForm();
  return (
    <Fragment>
      <Card
        title="Billing"
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
        size="small"
        style={{
          borderWidth: "2px",
          borderColor: "darkgray",
          borderRadius: "4px",
        }}
        extra={
          <Space>
            <Button
              style={{
                backgroundColor: '#d48806'
              }}
              type="primary"
              size="small"
              onClick={() => {
                setmodalMutasi(true);
              }
                // syncBillKHS(curpas.registrasiId)
              }
            >
              Mutasi
            </Button>
            <Button
              type="primary"
              size="small"
              onClick={() => syncBillKHS(curpas.registrasiId)}
            >
              Sync data KHS
            </Button>
          </Space>
        }
      >
        <Form form={form} name="billing" onFinish={simpanBill}>
          <Row gutter={[4, 2]}>
            <Col span={4} xs={24} sm={18} md={16} lg={12} xl={10}>
              Pelayanan :{" "}
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  dataSource={pelayanan}
                  showSearch
                  // searchValue={kosong}
                  style={{ width: "100%" }}
                  placeholder="Pilih Pelayanan"
                  optionFilterProp="children"
                  onChange={(value) => onPelayanan(value)}
                  value={pel}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {pelayanan.map((d) => (
                    <Option key={d.pelayananId}>
                      {d.pelayananId + "-" + d.deskripsi}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} xs={24} sm={4} md={4} lg={6} xl={2}>
              <Form.Item style={{ marginBottom: 0 }}>
                Jumlah :{" "}
                <InputNumber
                  min={1}
                  max={10}
                  onChange={(e) => setJumlah(e)}
                  style={{ width: "100%" }}
                  defaultValue={jumlah}
                  value={jumlah}
                />
              </Form.Item>
            </Col>
            <Col span={3} xs={24} sm={4} md={4} lg={6} xl={2}>
              Cito :{" "}
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  // defaultValue={Tidak}
                  // dataSource={dokterall}
                  showSearch
                  style={{ width: "100%" }}
                  // placeholder="Pilih Pelaksana"
                  optionFilterProp="children"
                  // onChange={(e) => setPemeriksa(e)}
                  // value={pemeriksa === null ? curpas.dokterId : pemeriksa}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {/* {dokterall.map((p) => ( */}
                  <Option key={1}>YA</Option>
                  <Option key={0}>TIDAK</Option>
                  {/* ))} */}
                </Select>
              </Form.Item>
            </Col>
            <Col span={3} xs={24} sm={6} md={6} lg={6} xl={3}>
              <Form.Item style={{ marginBottom: 0 }}>
                Biaya :{" "}
                <Input
                  disabled
                  style={{ width: "100%" }}
                  value={Number(detpel.total).toFixed(2)}
                />
              </Form.Item>
            </Col>
            <Col span={3} xs={24} sm={14} md={14} lg={14} xl={5}>
              Pelaksana :{" "}
              <Form.Item style={{ marginBottom: 0 }}>
                <Select
                  dataSource={dokterall}
                  showSearch
                  style={{ width: "100%" }}
                  placeholder="Pilih Pelaksana"
                  optionFilterProp="children"
                  onChange={(e) => setPemeriksa(e)}
                  value={pemeriksa === null ? curpas.dokterId : pemeriksa}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {dokterall.map((p) => (
                    <Option key={p.dokterId}>{p.namaDokter}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col
              span={24}
              xs={24}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              style={{ display: "flex", alignItems: "end" }}
            >
              <Form.Item style={{ marginBottom: 0 }}>
                <Button type="primary" htmlType="submit">
                  Ambil
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
        <Table
          // style={{ verticalAlign: "top" }}
          locale={{ emptyText: <Empty description={false} /> }}
          bordered
          pagination={false}
          dataSource={billing}
          size="small"
          rowKey="reg"
          scroll={{ x: 1000 }}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onPelayanan(record.pelayananId);
                setJumlah(record.jumlah);
                setPemeriksa(record.pemeriksaId);
                // console.log(record);
              },
            };
          }}
          summary={(pageData) => {
            let total = 0;
            pageData.forEach(({ biayaPelayanan, jumlah }) => {
              total += biayaPelayanan * jumlah;
            });
            return (
              <>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Total</th>
                  <td className="column-money, tabeltabel">
                    <Text type="danger">
                      Rp.{" "}
                      {total.toLocaleString("id-id", {
                        minimumFractionDigits: 2,
                      })}
                    </Text>
                  </td>
                </tr>
              </>
            );
          }}
        >
          <Column
            style={{ verticalAlign: "top" }}
            title="No."
            key="reg"
            className="tabeltabel"
            width="10px"
            render={(text, record, index) => <span>{index + 1}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Kode"
            className="bgcolortunggu, tabeltabel2"
            width="40px"
            render={(billing) => <span>{billing.pelayananId}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Unit"
            className="tabeltabel"
            width="180px"
            render={(billing) => <span>{billing.ruangDesk}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Tanggal"
            className="tabeltabel"
            width="65px"
            render={(billing) => <span>{billing.tanggal}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Pelayanan"
            className="tabeltabel"
            width="400px"
            render={(billing) => <span>{billing.pelayananDesk}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Jumlah"
            width="40px"
            className="column-money, tabeltabel"
            render={(billing) => <span>{billing.jumlah}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Biaya Pelayanan"
            width="100px"
            className="column-money, tabeltabel"
            render={(billing) => (
              <span>
                Rp.{" "}
                {billing.biayaPelayanan.toLocaleString("id-id", {
                  minimumFractionDigits: 2,
                })}
              </span>
            )}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Total Biaya"
            width="100px"
            className="column-money, tabeltabel"
            render={(billing) => (
              <span>
                Rp.{" "}
                {(
                  parseInt(billing.biayaPelayanan) * parseInt(billing.jumlah)
                ).toLocaleString("id-id", { minimumFractionDigits: 2 })}
              </span>
            )}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Nama Penjamin"
            className="tabeltabel"
            width="150px"
            render={(billing) => <span>{billing.pembayaranDesk}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Pemeriksa"
            className="tabeltabel"
            width="250px"
            render={(billing) => <span>{billing.pemeriksaDesk}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="No Bayar"
            className="tabeltabel"
            render={(billing) => <span>{billing.noPembayaran}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="User"
            className="tabeltabel"
            render={(billing) => <span>{billing.userId}</span>}
          />
          <Column
            style={{ verticalAlign: "top" }}
            title="Action"
            className="tabeltabel"
            render={(billing) => (
              <span>
                <Popconfirm
                  title="Anda Yakin Dihapus ?"
                  onConfirm={(e) =>
                    onDelete(billing.registrasiId, billing.pelayananId)
                  }
                  onCancel={(e) => cancel(e)}
                  okText="Ya"
                  cancelText="Tidak"
                // disabled={namauser === "NUGRAHA" ? false : true}
                >
                  <Button
                    size="small"
                    danger
                    type="primary"
                  // disabled={namauser === "NUGRAHA" ? false : true}
                  >
                    Hapus
                  </Button>
                </Popconfirm>
              </span>
            )}
          />
        </Table>
      </Card>

      <Modal
        title="Pindah Ruang Rawat"
        visible={modalMutasi}
        width="1000px"
        // footer={null}
        //onOk={handleOk}
        footer={[
          <Button key="back" onClick={() => {
            setmodalMutasi(false)
          }}>
            Kembali
          </Button>,
          <Button key="submit" type="primary" onClick={() => {
            setmodalMutasi(false)
          }}>
            Simpan
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0" style={{ marginTop: 0 }}>
              Asal Ruang Perawatan
            </Divider>
            <Form
              form={form}
              name="penunjang"
              initialValues={{ remember: true }}
            // onFinish={simpan}
            >
              <Row gutter={[2, 2]} align="middle">
                <Col span={24} >
                  <Form.Item
                    {...formItemLayout1}
                    label="Ruang"
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                    disabled
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...formItemLayout}
                    label="No Lantai"
                    rules={[{ required: true }]}
                    disabled
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label="No Kamar"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label="No T T"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...formItemLayout}
                    label="Ruang Perawatan"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label="Golongan Usia"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label="Group Kelas"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Col>
          <Col span={24}>
            <Divider orientation="left" orientationMargin="0" style={{ marginTop: 0 }} >
              Ruang Perawatan Baru
            </Divider>
            <Form
              form={form}
              name="penunjang"
              initialValues={{ remember: true }}
            // onFinish={simpan}
            >
              <Row gutter={[2, 2]} align="middle">
                <Col span={24} >
                  <Form.Item
                    {...formItemLayout1}
                    label="Ruang"
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Select
                      // value={dokterId === null ? curpasRI.dokterId : dokterId}
                      // onChange={onDokterkonsul}
                      // disabled={formkonsul}
                      // dataSource={dokterall}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {/* {dokterall.map(d => ( */}
                      <Option key={1}>{'Ruang'}</Option>
                      {/* ))} */}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...formItemLayout}
                    label="Kelas Rawat"
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Select
                      // value={dokterId === null ? curpasRI.dokterId : dokterId}
                      // onChange={onDokterkonsul}
                      // disabled={formkonsul}
                      // dataSource={dokterall}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {/* {dokterall.map(d => ( */}
                      <Option key={1}>{'Kelas'}</Option>
                      {/* ))} */}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label="No Kamar"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Select
                      // value={}
                      // onChange={onDokterkonsul}
                      // disabled={formkonsul}
                      onFocus={() => {
                        getRuang('1');
                      }}
                      dataSource={ruangList}
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="..."
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {ruangList.map(d => (
                        <Option key={d.ruangId}>{d.deskripsi}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label="No T T"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    {...formItemLayout}
                    label="No Lantai"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label="Golongan Usia"
                    disabled
                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                  <Form.Item
                    {...formItemLayout}
                    label="Group Kelas"

                    rules={[{ required: true }]}
                    style={{ marginBottom: 2 }}
                  >
                    <Input
                      disabled
                    // value={diagnosa}
                    />
                  </Form.Item>
                </Col>
              </Row>

            </Form>
          </Col>
        </Row>
      </Modal>
    </Fragment >
  );
};

export default FormBillingRI;
