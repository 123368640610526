import { Card, Tabs } from 'antd'
import React from 'react'
import PantauanPelayananDarah from '../PantauanPelayananDarah';

const { TabPane } = Tabs;

const MenuTabBarPelayananBD = () => {
    return (
        <div>
            <PantauanPelayananDarah />
        </div>
    )
}

export default MenuTabBarPelayananBD