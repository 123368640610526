import { Button, Card, Table } from "antd";
import React, { useContext } from "react";
import { UserContext } from "../appsetting/UserContext";
const { Column } = Table;

const ListIP = () => {
  const { listIP, getListIP } = useContext(UserContext);

  return (
    <div>
      <Card
        size="small"
        title="List IP"
        headStyle={{ fontWeight: "bolder", backgroundColor: "aliceblue" }}
        style={{
          borderWidth: "2px",
          borderColor: "darkgray",
          borderRadius: "4px",
        }}
      >
        <Button block type="primary" onClick={() => getListIP()}>
          Cek List IP
        </Button>
        <Table
          bordered
          dataSource={listIP}
          size="small"
          rowKey="reg"
          scroll={{ x: 700 }}
        >
          <Column
            style={{ verticalAlign: "top" }}
            title="No."
            key="reg"
            className="tabeltabel"
            width={20}
            render={(text, record, index) => <span>{index + 1}</span>}
          />
          <Column
            title="IP Computer"
            key="reg"
            className="bgcolortunggu, tabeltabel2"
            width={40}
            sorter={(a, b) => a.IPComputer.localeCompare(b.IPComputer)}
            render={(listIP) => <span>{listIP.IPComputer}</span>}
          />
          <Column
            title="User ID"
            key="reg"
            className="tabeltabel"
            width={50}
            sorter={(a, b) => a.UserId.localeCompare(b.UserId)}
            render={(listIP) => <span>{listIP.UserId}</span>}
          />
        </Table>
      </Card>
    </div>
  );
};

export default ListIP;
