import React, { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import "./App.css";
// import "../assets/css/style.css";
import "./login/logins.css";
import Loginform from "./login/Loginform";
import LoginContextProvider from "./rawatjalan/context/LoginContext";
import MainAPP from "./layout/MainAPP";
import {
  StyleProvider,
  legacyLogicalPropertiesTransformer,
} from "@ant-design/cssinjs";
import { ConfigProvider, theme } from "antd";

const isTouchDevice = () => {
  return window.matchMedia("(pointer: coarse)").matches;
};
const AppMain = () => {
  const [isTouch, setIsTouch] = useState(false);

  useEffect(() => {
    setIsTouch(isTouchDevice());
  }, []);
  return (
    <div
    // onContextMenu={(e) => {
    //         e.preventDefault();
    //         return false;
    //       }}
    //       onPaste={(e) => {
    //         e.preventDefault();
    //         return false;
    //       }}
    //       onCopy={(e) => {
    //         e.preventDefault();
    //         return false;
    // }}
    // onKeyDownCapture={(e) => {
    //   if (e.key === "F12") {
    //     e.preventDefault();
    //     return ;
    //   }
    // }}
    >
      <StyleProvider
        hashPriority="high"
        transformers={[legacyLogicalPropertiesTransformer]}
      >
        <ConfigProvider
          theme={
            isTouch
              ? {
                  algorithm: theme.compactAlgorithm,
                  token: {
                    borderRadius: 2,
                  },
                }
              : {
                  algorithm: theme.compactAlgorithm,
                  token: {
                    borderRadius: 2,
                  },
                }
          }
        >
          <Switch>
            <Route
              render={(props) => (
                <LoginContextProvider {...props}>
                  <Route
                    exact
                    path={"/login"}
                    render={(props) => <Loginform {...props} />}
                  />
                  <Route
                    exact
                    path={"/"}
                    render={(props) => <MainAPP {...props} />}
                  />
                </LoginContextProvider>
              )}
            ></Route>
          </Switch>
        </ConfigProvider>
      </StyleProvider>
    </div>
  );
};

export default AppMain;
